import { useEffect, useState } from "react";
import { cn } from "../../../utils/misc";
import type { NavNestedMenuBlockContent, NavNestedMenuSectionBlockContent } from "../../../types";
import { NavLinkListItem } from "../nav-link-list-item";

type Props = {
  blok: NavNestedMenuBlockContent;
  isActive: boolean;
};

type ActiveTriggerStyle = {
  offsetY: number;
  height: number;
};

export function NavNestedMenu({ blok, isActive }: Props) {
  const [activeCategoryIndex, setActiveCategoryIndex] = useState<number>(0);
  const [activeTriggerStyle, setActiveTriggerStyle] = useState<ActiveTriggerStyle | null>(null);

  const sections = blok.sections ?? [];

  useEffect(() => {
    if (isActive) {
      return;
    }

    const resetTimeout = setTimeout(() => {
      setActiveCategoryIndex(0);
      setActiveTriggerStyle(null);
    }, 600);

    return () => {
      clearTimeout(resetTimeout);
    };
  }, [isActive]);

  return (
    <div className="inline-grid grid-cols-[264px_420px] gap-1 lg:grid-cols-[264px_696px]">
      <div className="relative bg-light-2">
        {!!activeTriggerStyle && (
          <ActiveSectionBackground activeTriggerStyle={activeTriggerStyle} />
        )}

        {sections.map((section, index) => (
          <SectionTrigger
            activeCategoryIndex={activeCategoryIndex}
            activeTriggerStyle={activeTriggerStyle}
            index={index}
            key={section._uid}
            section={section}
            setActiveCategoryIndex={setActiveCategoryIndex}
            setActiveTriggerStyle={setActiveTriggerStyle}
          />
        ))}
      </div>
      <div className="flex overflow-hidden bg-white">
        {sections.map((section, index) => (
          <SectionContent
            activeCategoryIndex={activeCategoryIndex}
            index={index}
            key={section._uid}
            section={section}
          />
        ))}
      </div>
    </div>
  );
}

function ActiveSectionBackground({
  activeTriggerStyle,
}: {
  activeTriggerStyle: ActiveTriggerStyle;
}) {
  return (
    <>
      <div
        className="absolute top-0 z-[2] h-[200px] w-full bg-white transition-all"
        style={{
          transform: `translateY(${activeTriggerStyle.offsetY}px)`,
          height: `${activeTriggerStyle.height}px`,
        }}
      />
      <div
        className="absolute right-0 top-1/2 z-[1] h-5 w-5 -translate-y-1/2 translate-x-1/2 rotate-45 rounded-md border-4 border-light-3 bg-white transition-all"
        style={{
          top: `${activeTriggerStyle.offsetY + activeTriggerStyle.height / 2}px`,
        }}
      />
    </>
  );
}

function SectionTrigger({
  section,
  index,
  activeCategoryIndex,
  setActiveCategoryIndex,
  activeTriggerStyle,
  setActiveTriggerStyle,
}: {
  section: NavNestedMenuSectionBlockContent;
  index: number;
  activeCategoryIndex: number;
  setActiveCategoryIndex: (index: number) => void;
  activeTriggerStyle: ActiveTriggerStyle | null;
  setActiveTriggerStyle: (style: ActiveTriggerStyle) => void;
}) {
  return (
    <button
      className={cn(
        "relative w-full p-4 text-left",
        index !== activeCategoryIndex && "transition-all duration-300 hover:bg-light-t1",
      )}
      key={section.title}
      onClick={(event) => {
        setActiveCategoryIndex(index);

        const element = event.currentTarget;
        setActiveTriggerStyle({
          offsetY: element.offsetTop,
          height: element.offsetHeight,
        });
      }}
      ref={(element) => {
        if (element && !activeTriggerStyle && index === activeCategoryIndex) {
          setActiveTriggerStyle({
            offsetY: element.offsetTop,
            height: element.offsetHeight,
          });
        }
      }}
      style={{
        zIndex: 3,
      }}
      type="button"
    >
      <div className="text-16 font-semibold">{section.title}</div>
      <div className="text-14 font-light text-light-8">{section.subtitle}</div>
    </button>
  );
}

function SectionContent({
  section,
  index,
  activeCategoryIndex,
}: {
  section: NavNestedMenuSectionBlockContent;
  index: number;
  activeCategoryIndex: number;
}) {
  const links = section.content?.[0]?.links ?? [];

  return (
    <div
      className={cn(
        "relative w-[420px] shrink-0 p-1 transition-all duration-300 lg:w-[696px]",
        activeCategoryIndex === index
          ? "z-10 translate-y-0 opacity-100"
          : "pointer-events-none opacity-0",
        activeCategoryIndex > index && "-translate-y-24",
        activeCategoryIndex < index && "translate-y-24",
      )}
      key={section.title}
      style={{ right: `${index * 100}%` }}
    >
      <div className="grid w-[420px] grid-cols-1 gap-x-2 gap-y-2 p-4 lg:w-[696px] lg:grid-cols-2">
        {links.map((link) => (
          <NavLinkListItem key={link._uid} link={link} />
        ))}
      </div>
    </div>
  );
}
