import * as yup from "yup";
import { messageMaxChars } from "./constants";

export const newCustomerSchema = yup
  .object({
    firstName: yup.string().required("Dieses Feld ist erforderlich."),
    lastName: yup.string().required("Dieses Feld ist erforderlich."),
    companyName: yup.string().required("Dieses Feld ist erforderlich."),
    email: yup
      .string()
      .email("Ungültige E-Mail-Adresse.")
      .required("Dieses Feld ist erforderlich."),
    phoneNumber: yup.string().required("Dieses Feld ist erforderlich."),
    street: yup.string().default(""),
    houseNumber: yup.string().default(""),
    zipCode: yup.string().default(""),
    city: yup.string().default(""),
    subject: yup.string().default(""),
    subjectType: yup.string().required("Dieses Feld ist erforderlich."),
    message: yup
      .string()
      .max(messageMaxChars, `Ihre Nachricht sollte höchstens ${messageMaxChars} Zeichen enthalten`)
      .required("Dieses Feld ist erforderlich."),
    isAgreedToTerms: yup
      .boolean()
      .oneOf(
        [true],
        "Bitte bestätigen Sie, dass Sie mit unseren Datenschutzbedingungen einverstanden sind.",
      )
      .required("Dieses Feld ist erforderlich."),
    isAgreedToDoubleOptIn: yup.boolean().required(),
  })
  .required();

export const existingCustomerSchema = yup
  .object({
    firstName: yup.string().default(""),
    lastName: yup.string().default(""),
    companyName: yup.string().default(""),
    email: yup
      .string()
      .email("Ungültige E-Mail-Adresse.")
      .required("Dieses Feld ist erforderlich."),
    phoneNumber: yup.string().default(""),
    street: yup.string().default(""),
    houseNumber: yup.string().default(""),
    zipCode: yup.string().default(""),
    city: yup.string().default(""),
    subject: yup.string().default(""),
    subjectType: yup.string().required("Dieses Feld ist erforderlich."),
    message: yup
      .string()
      .max(messageMaxChars, `Ihre Nachricht sollte höchstens ${messageMaxChars} Zeichen enthalten`)
      .required("Dieses Feld ist erforderlich."),
    isAgreedToTerms: yup
      .boolean()
      .oneOf(
        [true],
        "Bitte bestätigen Sie, dass Sie mit unseren Datenschutzbedingungen einverstanden sind.",
      )
      .required("Dieses Feld ist erforderlich."),
    isAgreedToDoubleOptIn: yup.boolean().required(),
  })
  .required();
