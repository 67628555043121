import "../index.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IBM_Plex_Sans as IBMPlexSans } from "next/font/google";
import NextNProgress from "nextjs-progressbar";
import { initializeStoryblokApi } from "../lib/storyblok";
import { PORTAL_ROOT_NODE_ID } from "../config/constants";
import { SbBlockSetContextProvider } from "../context/sb-block-set-context";
import { COLORS } from "../config/theme";
import { CookieConsentObserver } from "../components/user-tracking/cookie-consent-observer";
import { initializeAlgoliaAnalytics } from "../lib/algolia";
import { RedirectNoticeDialog } from "../components/redirect-notice-dialog";
import { GoogleTagManagerScript } from "../components/user-tracking/google-tag-manager-snippets";
import { RouterHistoryStoreSynchronizer } from "../store/router-history-store";

initializeStoryblokApi();
initializeAlgoliaAnalytics();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

/**
 * https://nextjs.org/docs/pages/building-your-application/optimizing/fonts#with-tailwind-css
 */
const ibmPlexSans = IBMPlexSans({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700"],
  style: ["normal", "italic"],
  variable: "--font-sans",
});

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        {/* Generated via https://realfavicongenerator.net/ */}
        <link href="/apple-touch-icon.png" rel="apple-touch-icon" sizes="180x180" />
        <link href="/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
        <link href="/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png" />
        <link href="/site.webmanifest" rel="manifest" />
        <link color="#002b4a" href="/safari-pinned-tab.svg" rel="mask-icon" />
        <meta content="#ffffff" name="msapplication-TileColor" />
        <meta content="#ffffff" name="theme-color" />
      </Head>
      <GoogleTagManagerScript />
      <QueryClientProvider client={queryClient}>
        <NextNProgress color={COLORS.light.t8} showOnShallow={false} />
        <SbBlockSetContextProvider>
          <div className={`${ibmPlexSans.variable} font-sans`}>
            <Component {...pageProps} />
            <CookieConsentObserver />
            <RedirectNoticeDialog />
            <RouterHistoryStoreSynchronizer />
            <div id={PORTAL_ROOT_NODE_ID} />
          </div>
        </SbBlockSetContextProvider>
      </QueryClientProvider>
    </>
  );
}
