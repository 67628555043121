import type { ReactNode } from "react";
import { forwardRef } from "react";
import { cn } from "../../utils/misc";
import type { ButtonBaseProps } from "./button-base";
import { ButtonBase } from "./button-base";

type Props = Pick<
  ButtonBaseProps,
  "size" | "variant" | "onClick" | "linkProps" | "isDisabled" | "className"
> & {
  icon: ReactNode;
  isCircle?: boolean;
  /**
   * Since icon buttons do not have any discernible text for screen readers etc.,
   * we need to at least provide an aria-label.
   */
  ariaLabel: string;
};

export const IconButton = forwardRef<HTMLButtonElement, Props>(function IconButton(
  { isCircle = false, icon, ariaLabel, ...props },
  ref,
) {
  return (
    <ButtonBase
      {...props}
      ariaLabel={ariaLabel}
      className={cn(isCircle ? "rounded-full" : "rounded-md", props.className)}
      paddingStyle="circle"
      ref={ref}
      startIcon={icon}
    />
  );
});
