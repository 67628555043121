import type { SbBlockContent, SbBlockSet, KeywordBlockContent, SbAsset } from "../types";
import { BlockSet } from "../blocks/block-set";
import { SCREENS } from "../config/theme";
import { Container } from "./container";
import { AsideMenu } from "./aside-menu";
import { NativeVideo } from "./native-video";
import { SbAspectRatioImage } from "./image";

type Props = {
  title: string | undefined;
  image: SbAsset | undefined;
  video?: SbAsset;
  videoPoster?: SbAsset;
  body: SbBlockSet | undefined;
  date?: string;
  keywords?: SbBlockContent<KeywordBlockContent>[];
  menuContent: string[] | undefined;
};

export function PostBase({
  title,
  image,
  video,
  videoPoster,
  date,
  body,
  keywords,
  menuContent,
}: Props) {
  return (
    <div className="mb-16">
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-8 lg:gap-x-16 xl:grid-cols-9 xl:gap-x-20 2xl:grid-cols-10 2xl:gap-x-24">
          <div className="lg:col-span-5 xl:col-span-6 2xl:col-span-7">
            <div className="mx-auto max-w-4xl">
              {!!title && <h1 className="text-22 font-semibold lg:text-40">{title}</h1>}
              {(!!date || !!keywords) && (
                <div className="mt-5 flex flex-col gap-y-6 lg:mt-8">
                  {!!date && (
                    <div className="text-12 font-medium uppercase text-brand-d lg:text-14">
                      {date}
                    </div>
                  )}
                  {!!keywords && (
                    <div className="flex flex-wrap items-center gap-1.5">
                      {keywords
                        .filter((keyword) => !!keyword.title)
                        .map((keyword) => (
                          <div
                            className="max-w-[150px] truncate rounded-2xl bg-light-2 px-4 py-1.5 text-12 lg:text-16"
                            key={keyword._uid}
                          >
                            {keyword.title}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              )}
              <div className="mt-6">
                {video?.filename ? (
                  <NativeVideo poster={videoPoster?.filename} src={video.filename} />
                ) : (
                  <SbAspectRatioImage
                    aspectRatio="16/9"
                    className="rounded-md border border-light-3"
                    cropWidth={SCREENS.md}
                    image={image}
                    priority={true}
                    size={{ base: "100vw", lg: SCREENS.md }}
                  />
                )}
              </div>
              <div className="mt-6 lg:mt-8">
                <BlockSet blocks={body} richTextScale={{ lg: "lg" }} />
              </div>
            </div>
          </div>
          <div className="mt-6 lg:col-span-3 lg:mt-0">
            {!!menuContent && <AsideMenu groupIds={menuContent} />}
          </div>
        </div>
      </Container>
    </div>
  );
}
