import { forwardRef } from "react";
import { cn } from "../../utils/misc";
import type { ButtonBaseProps } from "./button-base";
import { ButtonBase } from "./button-base";

type Props = Pick<
  ButtonBaseProps,
  | "size"
  | "variant"
  | "startIcon"
  | "endIcon"
  | "onClick"
  | "linkProps"
  | "children"
  | "type"
  | "isDisabled"
  | "isLoading"
  | "justifyContent"
  | "className"
> & {
  isFullWidth?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, Props>(function Button(
  { isFullWidth = false, className, ...props },
  ref,
) {
  return (
    <ButtonBase
      {...props}
      className={cn("rounded-md", isFullWidth && "w-full", className)}
      ref={ref}
    >
      {props.children}
    </ButtonBase>
  );
});
