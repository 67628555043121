export const STORY_INDEX_GROUP = {
  SystemSolutions: "systemSolutions",
  News: "news",
  TechnicalKnowledge: "technicalKnowledge",
  Products: "products",
  Services: "services",
  MarketsAndSectors: "marketsAndSectors",
  Partners: "partners",
  AboutUs: "aboutUs",
} as const;
export type StoryIndexGroup = (typeof STORY_INDEX_GROUP)[keyof typeof STORY_INDEX_GROUP];

export type AlgoliaStoryObject = {
  full_slug: string;
  component: string;
  title: string;
  image: string | undefined;
  content: string;
  group: StoryIndexGroup | null;
  objectID: string;
  keywords: string[] | null;
};
