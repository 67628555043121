type Props = {
  title: string | undefined;
};

export function BlockTitle(props: Props) {
  if (!props.title) {
    return null;
  }

  return <div className="mb-4 text-18 font-semibold lg:mb-6 lg:text-24">{props.title}</div>;
}
