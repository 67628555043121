import type { SbBlockComponentProps } from "../types";
import { cn } from "../utils/misc";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  text?: string;
  textAlignment?: "left" | "center" | "right";
}>;

export function DividerBlock({ blok }: Props) {
  return (
    <BlockWrapper blok={blok} className="my-8 lg:my-10">
      {!blok.text && <DividerLine />}

      {!!blok.text && (
        <div className="flex items-center gap-4 lg:gap-6">
          {blok.textAlignment !== "left" && <DividerLine />}
          <DividerText
            className={cn(
              blok.textAlignment === "left" && "text-left",
              blok.textAlignment === "center" && "text-center",
              blok.textAlignment === "right" && "text-right",
            )}
            text={blok.text}
          />
          {blok.textAlignment !== "right" && <DividerLine />}
        </div>
      )}
    </BlockWrapper>
  );
}

function DividerLine() {
  return <hr className="min-w-[10%] grow border-t border-t-light-3" />;
}

function DividerText({ text, className }: { text: string; className?: string }) {
  return (
    <span className={cn("text-12 font-medium uppercase text-foreground-t1 lg:text-14", className)}>
      {text}
    </span>
  );
}
