import { storyblokEditable } from "@storyblok/react";
import { QUERY_KEY } from "../../config/constants";
import { Container } from "../../components/container";
import { Button } from "../../components/button";
import type { SbBlockComponentProps } from "../../types";
import { Layout } from "../../components/layout";
import { humanFriendlyDateFormat } from "../../utils/datetime";
import { useInfiniteStoriesQuery } from "../../hooks/use-infinite-stories-query";
import { PostListCard, PostListCardSkeleton } from "../../components/post-list-card";
import { getNewsPostedDate } from "../../utils/misc";
import type { NewsPostPageBlockContent } from "./news-post-page-block";

type Props = SbBlockComponentProps<{
  title?: string;
}>;

export function NewsListPageBlock({ blok }: Props) {
  const { data, isError, isSuccess, isPending, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteStoriesQuery<NewsPostPageBlockContent>({
      queryKey: [QUERY_KEY.POSTS],
      contentType: "post",
      sortBy: "content.datePosted:desc",
    });

  return (
    <Layout>
      <div className="mb-12 lg:mb-16" {...storyblokEditable(blok)}>
        <Container>
          <h1 className="text-28 font-semibold lg:text-56">{blok.title}</h1>
          <div className="mt-6 grid grid-cols-1 gap-y-8 lg:mt-8 lg:grid-cols-2 lg:gap-x-12 lg:gap-y-12">
            {!!isError && <div>Entschuldigung! Ein Fehler ist aufgetreten.</div>}

            {!!isSuccess &&
              data.pages
                .flatMap((page) => page.stories)
                .map((post, index) => {
                  const postedDate = getNewsPostedDate(post);

                  return (
                    <PostListCard
                      href={post.full_slug}
                      image={post.content.image}
                      key={post.id}
                      shouldPreloadImage={index <= 6}
                      subtitle={
                        <>
                          {!!postedDate && humanFriendlyDateFormat(postedDate)}
                          {!!postedDate && !!post.content.keywords && " | "}
                          {!!post.content.keywords &&
                            post.content.keywords.map((k) => k.title).join(", ")}
                        </>
                      }
                      title={post.content.title}
                    />
                  );
                })}

            {!!isPending && [1, 2, 3, 4, 5, 6, 7, 8].map((i) => <PostListCardSkeleton key={i} />)}
          </div>
          {!!isSuccess && !!hasNextPage && (
            <div className="mt-8 flex justify-center lg:mt-12">
              <Button
                isLoading={isFetchingNextPage}
                onClick={() => {
                  void fetchNextPage();
                }}
                variant="secondary"
              >
                Mehr laden
              </Button>
            </div>
          )}
        </Container>
      </div>
    </Layout>
  );
}
