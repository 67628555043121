import { useState } from "react";
import Link from "next/link";
import { ROUTES } from "../../config/constants";
import { GlobalSearch } from "../global-search";
import { HeaderIconLinks } from "./header-icon-links";
import { DesktopNav } from "./desktop-nav/desktop-nav";
import { MobileNav } from "./mobile-nav/mobile-nav";
import { Logo } from "./logo";

export function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white">
      <div className="mb-4 mt-6 flex flex-wrap items-center justify-between gap-x-8 gap-y-5 md:gap-x-10 md:gap-y-6 lg:mb-6">
        <div className="-ml-1.5 flex flex-1 shrink-0">
          <Link href={ROUTES.HOME}>
            <Logo />
          </Link>
        </div>
        <nav className="flex flex-1 items-center justify-end gap-x-6 md:order-3 md:gap-x-10">
          <HeaderIconLinks />
          <MobileNav isOpen={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen} />
        </nav>
        <div className="flex w-full justify-center md:order-2 md:max-w-[320px] lg:max-w-[415px]">
          <GlobalSearch />
        </div>
        <div className="order-4 hidden w-full md:block">
          <DesktopNav
            onShowAllButtonClick={() => {
              setIsMobileMenuOpen(true);
            }}
          />
        </div>
      </div>
    </header>
  );
}
