import type { LinkProps } from "next/link";
import Link from "next/link";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import { cn } from "../utils/misc";

/**
 * https://xd.adobe.com/view/08149b25-93c8-462b-99b6-a0301cc5ac28-5d47/screen/7f68430c-bc50-42fd-9b52-18c431ec4ee5/specs/
 */
const variantClasses = {
  text: "text-foreground underline hover:text-brand-d",
  highlighted: "underline text-brand-d hover:text-foreground",
  interface: "text-foreground underline opacity-30 hover:opacity-100",
  solo: "font-bold text-brand-d hover:text-foreground",
} as const;

type Props = {
  children: ReactNode;
  variant?: keyof typeof variantClasses;
  href: LinkProps["href"];
  target?: HTMLAnchorElement["target"];
  onClick?: () => void;
};

export const TextLink = forwardRef<HTMLAnchorElement, Props>(function TextLink(
  { variant = "highlighted", href, target, onClick, children },
  ref,
) {
  return (
    <Link
      className={cn("transition-all", variantClasses[variant])}
      href={href}
      onClick={onClick}
      ref={ref}
      target={target}
    >
      {children}
    </Link>
  );
});
