import { useMemo } from "react";
import type { AccordionMultipleProps, AccordionSingleProps } from "@radix-ui/react-accordion/dist";
import type { SbBlockComponentProps, SbBlockContent, SbBlockSet } from "../types";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/accordion";
import { BlockTitle } from "./block-title";
import { BlockSet } from "./block-set";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type AccordionItemBlockContent = {
  title?: string;
  body?: SbBlockSet;
  isOpenByDefault?: boolean;
};

type Props = SbBlockComponentProps<{
  title?: string;
  items?: SbBlockContent<AccordionItemBlockContent>[];
  type?: "single" | "multiple";
}>;

export function AccordionBlock({ blok }: Props) {
  // Each item needs a unique value that Typescript can recognize as a defined string.
  const items = useMemo(() => {
    if (!blok.items) {
      return [];
    }

    return blok.items.map((item, index) => {
      const uniqueId = item._uid ?? `item-${index}`;
      return { ...item, value: uniqueId };
    });
  }, [blok.items]);

  if (items.length === 0) {
    return null;
  }

  const accordionProps = generateAccordionProps({ items, type: blok.type });

  return (
    <BlockWrapper blok={blok}>
      <BlockTitle title={blok.title} />
      <Accordion {...accordionProps}>
        {items.map((item) => (
          <AccordionItem key={item._uid} value={item.value}>
            <AccordionTrigger>{item.title}</AccordionTrigger>
            <AccordionContent>
              <div className="pb-4 pt-0">
                <BlockSet blocks={item.body} richTextScale="sm" />
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </BlockWrapper>
  );
}

function generateAccordionProps({
  items,
  type,
}: {
  items: (AccordionItemBlockContent & { value: string })[];
  type: "single" | "multiple" | undefined;
}): AccordionSingleProps | AccordionMultipleProps {
  const autoExpandedItems = items.filter((item) => item.isOpenByDefault);
  const accordionType = autoExpandedItems.length > 1 ? "multiple" : type ?? "single";

  if (accordionType === "single") {
    return {
      type: "single",
      collapsible: true,
      defaultValue: autoExpandedItems[0]?.value,
    };
  }

  return {
    type: "multiple",
    defaultValue: autoExpandedItems.map((item) => item.value),
  };
}
