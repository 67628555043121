import { FiArrowLeft } from "react-icons/fi";
import { BreadcrumbItem } from "./_breadcrumb-item";
import type { BreadcrumbItemProps } from "./_types";

export function MobileBreadcrumbItems(props: { items: BreadcrumbItemProps[] }) {
  /**
   * Takes the item which is above the current page in the hierarchy.
   *
   * For example:
   * - when visiting "/loesungen/produktionstechnik/arbeitsplatzsysteme" it will link to "/loesungen/produktionstechnik".
   * - when visiting "/kontakt" it will link to the homepage ("/").
   */
  const mobileViewportItem = props.items[props.items.length - 2];

  return (
    <div className="flex items-center gap-x-2">
      <FiArrowLeft className="shrink-0" size={20} />
      <BreadcrumbItem href={mobileViewportItem.href} label={mobileViewportItem.label} />
    </div>
  );
}
