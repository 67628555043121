import Link from "next/link";
import type { ReactNode } from "react";
import type { StaticImport } from "next/dist/shared/lib/get-img-props";
import type { SbAsset, SbStoryProps } from "../types";
import placeholder from "../assets/placeholder-640x360.svg";
import { SkeletonLoader } from "./skeleton-loader";
import { AspectRatioImage, SbAspectRatioImage } from "./image";

type Props = {
  stories: SbStoryProps<{ image?: SbAsset }>[] | undefined;
  /**
   * Assumes the data is fetched via React Query.
   */
  isPending: boolean;
  /**
   * Assumes the data is fetched via React Query.
   */
  isError: boolean;
  title?: ReactNode;
};

export function RelatedStoriesGrid({ stories, isPending, isError, title }: Props) {
  if (isError) {
    return null;
  }

  return (
    <div>
      {!!stories && stories.length > 0 && (
        <h3 className="text-18 font-semibold md:text-32">
          {title || "Das könnte Sie auch interessieren:"}
        </h3>
      )}

      <div className="mt-8 grid grid-cols-2 gap-x-3 gap-y-4 sm:grid-cols-3 sm:gap-x-4 sm:gap-y-5 xl:grid-cols-5 xl:gap-x-6 xl:gap-y-7">
        {!!isPending &&
          [1, 2, 3, 4, 5].map((i) => (
            <div key={i}>
              <SkeletonLoader className="h-36" />
              <SkeletonLoader className="mt-4 h-6" />
            </div>
          ))}
        {!isPending &&
          !!stories &&
          stories.map((item) => (
            <Link
              className="flex flex-col items-start gap-2 transition-all hover:opacity-80"
              href={`/${item.full_slug}`}
              key={item.uuid}
            >
              {item.content.image ? (
                <SbAspectRatioImage
                  aspectRatio="16/9"
                  className="rounded-md border border-light-3"
                  cropWidth={300}
                  image={item.content.image}
                  size={300}
                />
              ) : (
                <AspectRatioImage
                  alt={item.name}
                  aspectRatio="16/9"
                  className="rounded-md border border-light-3"
                  size={300}
                  src={placeholder as StaticImport}
                />
              )}
              <div className="line-clamp-3 hyphens-auto text-14 font-medium md:text-18" lang="de">
                {item.name}
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}
