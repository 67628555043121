import { useLocalStorage } from "usehooks-ts";
import { useCallback } from "react";

export function useQueryStorage() {
  const [queries, setQueries] = useLocalStorage<string[]>("savedSearchQueries", []);

  const add = useCallback(
    (query: string) => {
      if (queries.some((q) => q.toLowerCase() === query.toLowerCase())) {
        return;
      }

      setQueries([query, ...queries].slice(0, 6));
    },
    [queries, setQueries],
  );

  const removeOne = useCallback(
    (query: string) => {
      setQueries(queries.filter((item) => item !== query));
    },
    [queries, setQueries],
  );

  const removeAll = useCallback(() => {
    setQueries([]);
  }, [setQueries]);

  return {
    queries,
    add,
    removeOne,
    removeAll,
  };
}
