import Link from "next/link";
import { FiArrowRight } from "react-icons/fi";
import type { SbAsset } from "../types";
import { SCREENS } from "../config/theme";
import { cn } from "../utils/misc";
import { SkeletonLoader } from "./skeleton-loader";
import { SbAspectRatioImage } from "./image";

type Item = {
  id: string;
  href: string;
  image: SbAsset | undefined;
  title: string | undefined;
  colorClass: string;
  description?: string | undefined;
};

type Props = {
  items: Item[] | undefined;
  imageVariant: "small" | "cover";
  isPending?: boolean;
  isError?: boolean;
};

const itemListClasses = "flex flex-col gap-y-6";
const itemWrapperClasses = "mx-auto w-full max-w-5xl";

export function OverviewCardList({ items, isPending, isError, imageVariant }: Props) {
  if (isError || !items) {
    return null;
  }

  if (isPending) {
    return (
      <div className={itemListClasses}>
        {[1, 2, 3, 4].map((i) => (
          <SkeletonLoader className={cn(itemWrapperClasses, "h-80")} key={i} />
        ))}
      </div>
    );
  }

  return (
    <div className={itemListClasses}>
      {items.map((item) => (
        <div className={itemWrapperClasses} key={item.id}>
          <ListItem imageVariant={imageVariant} item={item} />
        </div>
      ))}
    </div>
  );
}

function ListItem({
  item: { href, image, title, colorClass, description },
  imageVariant,
}: { item: Item } & Pick<Props, "imageVariant">) {
  return (
    <Link
      className="relative grid cursor-pointer grid-cols-1 gap-4 overflow-hidden rounded-md border border-b-0 border-light-3 bg-light-1 p-4 pb-6 transition-all hover:opacity-80 md:grid-cols-2 md:gap-8 md:border-b md:border-r-0  md:py-0 md:pl-0 md:pr-6"
      href={href}
    >
      <ListItemImage backgroundColorClass={colorClass} image={image} imageVariant={imageVariant} />

      <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
        <div className="pr-4 md:py-8 md:pr-8">
          <h2 className="line-clamp-3 font-semibold md:text-18 lg:text-20">{title}</h2>
          {!!description && (
            <div className="mt-4 line-clamp-3 text-14 md:text-15 lg:text-16">{description}</div>
          )}
        </div>
        <FiArrowRight className="ml-auto shrink-0" size={24} />
      </div>

      <div
        className={cn(
          "absolute bottom-0 left-0 right-0 h-2 md:left-auto md:top-0 md:h-full md:w-2",
          colorClass,
        )}
      />
    </Link>
  );
}

function ListItemImage({
  image,
  backgroundColorClass,
  imageVariant,
}: { image: SbAsset | undefined; backgroundColorClass: string } & Pick<Props, "imageVariant">) {
  const borderClasses = "rounded-md border border-light-3 md:border-0 md:border-r";

  if (imageVariant === "cover") {
    return (
      <SbAspectRatioImage
        aspectRatio="16/9"
        className={borderClasses}
        cropWidth={SCREENS.md}
        image={image}
        priority={true}
        size={{
          base: "100vw",
          lg: SCREENS.md,
        }}
      />
    );
  }

  return (
    <div
      className={cn("flex justify-center px-16 py-4 md:py-6", borderClasses, backgroundColorClass)}
    >
      <div className="w-full max-w-[200px] sm:max-w-[240px] md:max-w-[200px] lg:max-w-[220px]">
        <SbAspectRatioImage
          aspectRatio="1/1"
          cropWidth={SCREENS.md}
          image={image}
          priority={true}
          size={{
            base: "100vw",
            lg: SCREENS.md,
          }}
        />
      </div>
    </div>
  );
}
