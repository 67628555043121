import type { ISbRichtext } from "@storyblok/react";
import { StoryblokComponent } from "@storyblok/react";
import type { LinkCustomAttributes } from "storyblok-rich-text-react-renderer";
import { MARK_BOLD, MARK_LINK, render } from "storyblok-rich-text-react-renderer";
import type { ReactNode } from "react";
import Link from "next/link";
import type { Breakpoint, SbLinkedStory, SbLinkType } from "../types";
import { cn } from "../utils/misc";

/**
 * https://tailwindcss.com/docs/typography-plugin#applying-a-type-scale
 */
type TwProseScale = "sm" | "base" | "lg" | "xl" | "2xl";

/**
 * Have to define all of these so that we ensure tailwind includes them in the bundle.
 */
const ResponsiveProseScaleMap: Record<Breakpoint | "base", Record<TwProseScale, string>> = {
  base: {
    sm: "prose-sm",
    base: "prose-base",
    lg: "prose-lg",
    xl: "prose-xl",
    "2xl": "prose-2xl",
  },
  sm: {
    sm: "sm:prose-sm",
    base: "sm:prose-base",
    lg: "sm:prose-lg",
    xl: "sm:prose-xl",
    "2xl": "sm:prose-2xl",
  },
  md: {
    sm: "md:prose-sm",
    base: "md:prose-base",
    lg: "md:prose-lg",
    xl: "md:prose-xl",
    "2xl": "md:prose-2xl",
  },
  lg: {
    sm: "lg:prose-sm",
    base: "lg:prose-base",
    lg: "lg:prose-lg",
    xl: "lg:prose-xl",
    "2xl": "lg:prose-2xl",
  },
  xl: {
    sm: "xl:prose-sm",
    base: "xl:prose-base",
    lg: "xl:prose-lg",
    xl: "xl:prose-xl",
    "2xl": "xl:prose-2xl",
  },
  "2xl": {
    sm: "2xl:prose-sm",
    base: "2xl:prose-base",
    lg: "2xl:prose-lg",
    xl: "2xl:prose-xl",
    "2xl": "2xl:prose-2xl",
  },
};

type ResponsiveScaleConfig = Partial<Record<Breakpoint, TwProseScale>>;

export type RichTextContentProps = {
  content: ISbRichtext | undefined;
  scale?: TwProseScale | ResponsiveScaleConfig;
  isLightTheme?: boolean;
};

export function RichTextContent({ content, scale, isLightTheme }: RichTextContentProps) {
  return (
    <div
      className={cn(
        "prose max-w-none prose-headings:font-semibold prose-a:font-normal",
        generateProseScaleClasses(scale),
        isLightTheme && "prose-invert",
      )}
    >
      {render(content, {
        markResolvers: {
          [MARK_BOLD]: markBoldResolver,
          [MARK_LINK]: linkResolver,
        },
        defaultBlokResolver,
      })}
    </div>
  );
}

function generateProseScaleClasses(scale: RichTextContentProps["scale"]) {
  if (!scale) {
    return;
  }

  if (typeof scale === "string") {
    return ResponsiveProseScaleMap.base[scale];
  }

  return (Object.keys(scale) as Breakpoint[])
    .reduce<string[]>((acc, curr) => {
      const breakpointScale = scale[curr];

      if (breakpointScale) {
        acc.push(ResponsiveProseScaleMap[curr][breakpointScale]);
      }

      return acc;
    }, [])
    .join(" ");
}

type LinkMarkProps = {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- TODO
  linktype?: SbLinkType | string;
  href?: string;
  target?: string;
  anchor?: string;
  uuid?: string;
  custom?: LinkCustomAttributes;
  /**
   * Set if linktype === "story" && resolve_links parameter is used when fetching stories.
   */
  story?: SbLinkedStory;
};

function defaultBlokResolver(name: string, props: Record<string, unknown> & { _uid: string }) {
  return (
    <div className="not-prose my-4 lg:mt-6">
      <StoryblokComponent blok={{ ...props, component: name }} key={props._uid} />
    </div>
  );
}

function markBoldResolver(children: ReactNode) {
  return <strong>{children}</strong>;
}

function linkResolver(children: ReactNode, { linktype, href, target, story }: LinkMarkProps) {
  if (linktype === "story") {
    const storyHref = story?.full_slug ?? href ?? "";

    return (
      <Link href={storyHref} target={target}>
        {children}
      </Link>
    );
  }

  if (linktype === "email") {
    return <a href={`mailto:${href}`}>{children}</a>;
  }

  if (linktype === "asset") {
    return (
      <a href={href} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    );
  }

  return (
    <a href={href} target={target}>
      {children}
    </a>
  );
}
