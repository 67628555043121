import { Radio, Field, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

type Props = {
  value: string;
  onSelect?: (value: string) => void;
  items: string[];
  className?: string;
};

export function RadioInput(props: Props) {
  const { value, items, onSelect } = props;

  return (
    <RadioGroup className="grid gap-6 sm:grid-cols-2" onChange={onSelect} value={value}>
      {items.map((item) => (
        <Field
          className="flex h-10 w-full cursor-pointer items-center rounded-md border-transparent hover:bg-light-2 hover:shadow-button"
          key={item}
        >
          <Radio className="group relative flex w-full" key={item} value={item}>
            <div className="flex w-full items-center justify-start pl-3.5">
              <CheckCircleIcon className="mr-4 size-6 fill-black opacity-10 group-data-[checked]:fill-brand-d group-data-[checked]:opacity-100" />
              <div className="text-sm">
                <p className="">{item}</p>
              </div>
            </div>
          </Radio>
        </Field>
      ))}
    </RadioGroup>
  );
}
