import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY } from "../config/constants";
import { getStoryByUuid } from "../api/storyblok";
import type { SbAsset } from "../types";

type ContactPersonBlockContent = {
  name?: string;
  email?: string;
  photo?: SbAsset;
  title?: string;
  phone_number?: string;
};

export const useContactPersonQuery = (uuid: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.CONTACT_PERSON, uuid],
    queryFn: async () => {
      const data = await getStoryByUuid<ContactPersonBlockContent>(uuid);

      return data.data.story.content;
    },
  });
};
