import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export function VideoWrapper(props: Props) {
  return (
    <div className="aspect-h-9 aspect-w-16 overflow-hidden rounded-md border border-light-3">
      {props.children}
    </div>
  );
}
