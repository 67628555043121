import Link from "next/link";
import type { NavLinkBlockContent, SbAsset } from "../../types";
import { getSbLinkUrl } from "../../utils/misc";
import { AspectRatioImage, SbAspectRatioImage } from "../image";

type Props = {
  link: NavLinkBlockContent;
};

export function NavLinkListItem({ link }: Props) {
  return (
    <Link
      className="flex gap-2 rounded-md p-2 text-left transition-colors hover:bg-light-t1"
      href={getSbLinkUrl(link.link) ?? ""}
      key={link._uid}
    >
      <div
        className="relative h-7 w-7 shrink-0 overflow-hidden rounded-md bg-light-5"
        style={{ backgroundColor: link.color?.value }}
      >
        {!!link.image && <NavLinkImage image={link.image} />}
      </div>
      <div>
        <div className="line-clamp-1 text-15 font-medium leading-tight">{link.title}</div>
        <div className="mt-0.5 line-clamp-2 hyphens-auto text-14 text-light-8" lang="de">
          {link.subtitle}
        </div>
      </div>
    </Link>
  );
}

function NavLinkImage({ image }: { image: SbAsset }) {
  // Render nothing if there is no image src.
  if (!image.filename) {
    return null;
  }

  // With SVGs (icons), we don't want to use the Storyblok image service for cropping/resizing etc.
  if (image.filename.endsWith(".svg")) {
    return <AspectRatioImage alt={image.alt} aspectRatio="1/1" size={100} src={image.filename} />;
  }

  return <SbAspectRatioImage aspectRatio="1/1" cropWidth={100} image={image} size={100} />;
}
