import { StoryblokComponent } from "@storyblok/react";
import type { SbBlockSet } from "../types";
import { SbBlockSetContextProvider } from "../context/sb-block-set-context";
import type { RichTextContentProps } from "../components/rich-text-content";
import { cn } from "../utils/misc";

type Props = {
  blocks: SbBlockSet | undefined;
  richTextScale?: RichTextContentProps["scale"];
};

export function BlockSet(props: Props) {
  if (!props.blocks) {
    return null;
  }

  return (
    <SbBlockSetContextProvider value={{ richTextScale: props.richTextScale }}>
      <div>
        {props.blocks.map((block) => (
          <div
            className={cn(
              !block.blockStyles?.margin?.mobile.value.isEnabled && "mt-6 first:mt-0",
              !block.blockStyles?.margin?.tablet.value.isEnabled && "md:mt-6 first:md:mt-0",
              !block.blockStyles?.margin?.desktop.value.isEnabled && "lg:mt-8 first:lg:mt-0",
            )}
            key={block._uid}
          >
            <StoryblokComponent blok={block} />
          </div>
        ))}
      </div>
    </SbBlockSetContextProvider>
  );
}
