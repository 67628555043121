import { useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { Modal } from "../modal";
import { Switch } from "../switch";
import { Button } from "../button";
import type { CookieConsentState } from "../../types";

type Props = {
  onAcceptAll: () => void;
  onRejectAll: () => void;
  onSave: (params: CookieConsentState) => void;
  onClose: () => void;
};

export function CookieSettingsDialog({ onAcceptAll, onRejectAll, onSave, onClose }: Props) {
  const [isAnalyticsConsentGranted, setIsAnalyticsConsentGranted] = useState(false);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <div className="p-6">
        <div className="truncate text-20 font-bold sm:text-22">Datenschutzeinstellungen</div>
        <div className="mt-6 border-t border-t-light-3 pt-3 text-15">
          <div>
            Hier finden Sie eine Übersicht zu über alle verwendeten Cookies. Sie können auswählen,
            welche Cookies Sie aktivieren oder deaktivieren möchten.
          </div>
        </div>
        <div className="mt-5 border-t border-t-light-3 pt-3">
          <div className="flex items-center">
            <div className="w-6">
              <FiChevronRight />
            </div>
            <div className="text-18 font-bold">Essenziell</div>
          </div>

          <div className="mt-2 pl-6 text-15">
            Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie
            Funktion der Website erforderlich.
          </div>
        </div>

        <div className="mt-5 border-t border-t-light-3 pt-3">
          <div className="flex items-center">
            <div className="w-6">
              <FiChevronRight />
            </div>
            <div className="text-18 font-bold">Statistiken</div>
            <div className="ml-auto">
              <Switch
                isChecked={isAnalyticsConsentGranted}
                onCheckedChange={setIsAnalyticsConsentGranted}
              />
            </div>
          </div>
          <div className="mt-2 pl-6 text-15">
            Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu
            verstehen, wie unsere Besucher unsere Website nutzen.
          </div>
        </div>
        <div className="mt-8 flex flex-col gap-2 sm:flex-row">
          <Button isFullWidth={true} onClick={onAcceptAll} size="medium" variant="normal">
            Alle akzeptieren
          </Button>
          <Button
            isFullWidth={true}
            onClick={() => {
              onSave({ analyticsConsentGranted: isAnalyticsConsentGranted });
            }}
            size="medium"
            variant="secondary"
          >
            Speichern
          </Button>
          <Button isFullWidth={true} onClick={onRejectAll} size="medium" variant="secondary">
            Ablehnen
          </Button>
        </div>
      </div>
    </Modal>
  );
}
