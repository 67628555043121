import { storyblokEditable } from "@storyblok/react";
import type { SbBlockComponentProps, SbStoryProps } from "../../types";
import { Container } from "../../components/container";
import { Layout } from "../../components/layout";
import { OverviewCardList } from "../../components/overview-card-list";
import { getProductGroupColorScheme } from "../../config/product-group-color-scheme";
import type { CategoryBlockContent } from "./category-page-block";

type Props = SbBlockComponentProps<{
  title?: string;
  categories?: SbStoryProps<CategoryBlockContent>[];
}>;

export function CategoriesListPageBlock({ blok }: Props) {
  const categories = blok.categories ?? [];

  const listItems = categories.map((category) => {
    const colorScheme = getProductGroupColorScheme(category.content.productGroup);

    return {
      id: category.uuid,
      href: category.full_slug,
      title: category.content.title,
      colorClass: colorScheme.bgClass,
      image: category.content.image,
    };
  });

  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-16">
        <Container>
          <div className="border-t border-light-3 py-6 lg:mb-6 lg:border-b">
            <h1 className="text-18 font-semibold lg:text-32">{blok.title}</h1>
          </div>

          <OverviewCardList imageVariant="small" items={listItems} />
        </Container>
      </div>
    </Layout>
  );
}
