import Link from "next/link";
import { storyblokEditable } from "@storyblok/react";
import type { SbBlockComponentProps, SbStoryProps } from "../types";
import { SbAspectRatioImage } from "../components/image";
import type { TechKnowledgePostPageBlockContent } from "./pages/tech-knowledge-post-page-block";

type Props = SbBlockComponentProps<{
  infos?: SbStoryProps<TechKnowledgePostPageBlockContent>[];
}>;

export function LandingPageTechKnowledgeBlock({ blok }: Props) {
  if (!blok.infos) {
    return null;
  }

  return (
    <div
      className="grid grid-cols-2 gap-x-2 gap-y-4 lg:grid-cols-4 lg:gap-x-6 lg:gap-y-6"
      {...storyblokEditable(blok)}
    >
      {blok.infos.map((info) => (
        <Link className="transition-all hover:opacity-80" href={info.full_slug} key={info.uuid}>
          <SbAspectRatioImage
            aspectRatio="16/9"
            className="rounded-md"
            cropWidth={600}
            image={info.content.image}
            size={{ base: "50vw", lg: "25vw", "2xl": 600 }}
          />
          <div
            className="mt-2 line-clamp-3 hyphens-auto text-12 font-semibold lg:mt-2 lg:text-16"
            lang="de"
          >
            {info.content.title}
          </div>
        </Link>
      ))}
    </div>
  );
}
