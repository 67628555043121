/**
 * Defined as the "System" datasource in Storyblok.
 */
export enum System {
  Produktionstechnik = "produktionstechnik",
  Fluidtechnik = "fluidtechnik",
  Antriebstechnik = "antriebstechnik",
  Industriehydraulik = "industriehydraulik",
}

/**
 * Defined as the "Product Group (Warengruppe)" datasource in Storyblok.
 */
export enum ProductGroup {
  Montageautomation = "montageautomation",
  Industriepneumatik = "industriepneumatik",
  Antriebstechnik = "antriebstechnik",
  Drucklufttechnik = "drucklufttechnik",
  Prozesstechnik = "prozesstechnik",
  Industrieteile = "industrieteile",
  Verbindungstechnik = "verbindungstechnik",
  Industriehydraulik = "industriehydraulik",
}
