import type { ReactNode } from "react";
import { CgSpinner } from "react-icons/cg";
import { MdOutlineError } from "react-icons/md";
import { IoRemoveCircleSharp } from "react-icons/io5";
import { useInstantSearch } from "react-instantsearch";
import type { Hit } from "instantsearch.js";
import type { AlgoliaStoryObject } from "@pts/algolia";
import { SearchDropdownWrapper } from "./_search-dropdown-wrapper";
import { SearchHitsList } from "./_search-hits-list";

type Props = {
  hits: Hit<AlgoliaStoryObject>[];
  totalHits: number;
  onHitClick: (hit: Hit<AlgoliaStoryObject>) => void;
  onShowAllResultsClick: () => void;
};

export function SearchHitsDropdown({ hits, totalHits, onHitClick, onShowAllResultsClick }: Props) {
  const { status } = useInstantSearch();

  return (
    <SearchDropdownWrapper>
      {status === "stalled" && (
        <SearchHitsNotification
          icon={<CgSpinner className="animate-spin text-brand-d" />}
          text="Ergebnisse laden."
        />
      )}
      {status === "error" && (
        <SearchHitsNotification
          icon={<MdOutlineError className="text-error" />}
          text="Entschuldigung! Ein Fehler ist aufgetreten."
        />
      )}

      {status === "idle" && hits.length === 0 && (
        <SearchHitsNotification
          icon={<IoRemoveCircleSharp className="text-warning" size={24} />}
          text="Keine Ergebnisse."
        />
      )}

      {status === "idle" && hits.length > 0 && (
        <SearchHitsList
          hits={hits}
          onHitClick={onHitClick}
          onShowAllResultsClick={onShowAllResultsClick}
          totalHits={totalHits}
        />
      )}
    </SearchDropdownWrapper>
  );
}

function SearchHitsNotification(props: { icon: ReactNode; text: string }) {
  return (
    <div className="flex items-center px-4 py-5">
      <div className="text-24">{props.icon}</div>
      <div className="ml-3.5">{props.text}</div>
    </div>
  );
}
