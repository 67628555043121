import type { ImageAspectRatio } from "./types";

type ImageAspectRatioConfig = {
  className: string;
  widthRatio: number;
  heightRatio: number;
};

/**
 * Used to assign correct TailwindCSS aspect ratio classes to the image wrapper,
 * and to calculate the cropped image dimensions when fetching from Storyblok.
 */
export const imageAspectRatio: Record<ImageAspectRatio, ImageAspectRatioConfig> = {
  "1/1": {
    className: "aspect-w-1 aspect-h-1",
    widthRatio: 1,
    heightRatio: 1,
  },
  "16/9": {
    className: "aspect-w-16 aspect-h-9",
    widthRatio: 16,
    heightRatio: 9,
  },
  "9/16": {
    className: "aspect-w-9 aspect-h-16",
    widthRatio: 9,
    heightRatio: 16,
  },
  "4/3": {
    className: "aspect-w-4 aspect-h-3",
    widthRatio: 4,
    heightRatio: 3,
  },
  "3/4": {
    className: "aspect-w-3 aspect-h-4",
    widthRatio: 3,
    heightRatio: 4,
  },
  "3/2": {
    className: "aspect-w-3 aspect-h-2",
    widthRatio: 3,
    heightRatio: 2,
  },
  "2/3": {
    className: "aspect-w-2 aspect-h-3",
    widthRatio: 2,
    heightRatio: 3,
  },
  "16/10": {
    className: "aspect-w-16 aspect-h-10",
    widthRatio: 16,
    heightRatio: 10,
  },
  "16/5": {
    className: "aspect-w-16 aspect-h-5",
    widthRatio: 16,
    heightRatio: 5,
  },
  "16/6": {
    className: "aspect-w-16 aspect-h-6",
    widthRatio: 16,
    heightRatio: 6,
  },
};
