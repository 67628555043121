import { useFormContext } from "react-hook-form";
import { cn } from "../../utils/misc";
import { textInputSharedClasses } from "./shared-classes";
import { RHFFormControl } from "./rhf-form-control";

type Props = {
  name: string;
  id: string;
  label: string;
  placeholder?: string;
  rows?: number;
};

export function RHFTextarea(props: Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const isError = !!errors[props.name];

  return (
    <RHFFormControl errors={errors} id={props.id} label={props.label} name={props.name}>
      <textarea
        className={cn(textInputSharedClasses({ isError }), "block resize-none")}
        id={props.id}
        placeholder={props.placeholder ?? " "}
        rows={props.rows}
        {...register(props.name)}
      />
    </RHFFormControl>
  );
}
