import { SkeletonLoader } from "../skeleton-loader";

type Props = {
  withLabel?: boolean;
};

export function PartnerCardSkeleton({ withLabel }: Props) {
  return (
    <div>
      <SkeletonLoader className="aspect-h-1 aspect-w-1" />
      {!!withLabel && <SkeletonLoader className="mt-2 h-6" />}
    </div>
  );
}
