import { storyblokEditable } from "@storyblok/react";
import { Container } from "../../components/container";
import { Layout } from "../../components/layout";
import type { SbAsset, SbBlockComponentProps, SbBlockSet, SbPaletteColor } from "../../types";
import { ExpandableText } from "../../components/expandable-text";
import { BlockSet } from "../block-set";
import { SbAspectRatioImage } from "../../components/image";
import { SCREENS } from "../../config/theme";
import { NativeVideo } from "../../components/native-video";

export type ServicePageBlockContent = {
  image?: SbAsset;
  video?: SbAsset;
  videoPoster?: SbAsset;
  title?: string;
  colorScheme?: SbPaletteColor;
  description?: string;
  body?: SbBlockSet;
};

type Props = SbBlockComponentProps<ServicePageBlockContent>;

export function ServicePageBlock({ blok }: Props) {
  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-12 lg:mb-16">
        <Container>
          <div className="flex flex-col gap-6 border-y border-light-3 py-6 lg:flex-row lg:justify-between lg:gap-16 xl:gap-24 2xl:gap-36">
            <div className="shrink-0 lg:order-2 lg:w-[400px] xl:w-[520px] 2xl:w-[600px]">
              {blok.video?.filename ? (
                <NativeVideo poster={blok.videoPoster?.filename} src={blok.video.filename} />
              ) : (
                <SbAspectRatioImage
                  aspectRatio="16/9"
                  className="rounded-md border border-light-3"
                  cropWidth={SCREENS.lg}
                  image={blok.image}
                  priority={true}
                  size={{
                    base: "100vw",
                    lg: SCREENS.lg,
                  }}
                />
              )}
            </div>

            <div className="lg:order-1 lg:flex lg:flex-col lg:justify-center">
              <div className="flex gap-3 md:gap-4">
                <h1 className="pr-4 text-18 font-semibold md:pr-6 md:text-32 lg:pr-0 lg:text-24 xl:text-40">
                  {blok.title}
                </h1>
              </div>

              <div className="prose mt-6 max-w-none md:mt-4">
                <ExpandableText>{blok.description}</ExpandableText>
              </div>
            </div>
          </div>

          <div className="mx-auto mt-6 max-w-4xl lg:mt-10 xl:pr-40">
            <BlockSet blocks={blok.body} richTextScale={{ lg: "lg" }} />
          </div>
        </Container>
      </div>
    </Layout>
  );
}
