import type {
  BlockStylesPluginContent,
  BlockStylesBreakpoint,
} from "@pts/storyblok-plugin-block-styles";

type Property = keyof Required<BlockStylesPluginContent>;

type BlockStyles = Record<Property | "lineHeight", Record<BlockStylesBreakpoint, string>>;

type PropertyHandlers = {
  [K in Property]: PropertyValueHandler<K>;
};

type PropertyValueHandler<
  TProperty extends Property,
  TBreakpoint extends BlockStylesBreakpoint = BlockStylesBreakpoint,
> = (value: Required<BlockStylesPluginContent>[TProperty][TBreakpoint]["value"]) => string;

const FALLBACK_VALUES: BlockStyles = {
  padding: responsiveFallback(),
  margin: responsiveFallback(),
  maxWidth: responsiveFallback(),
  background: responsiveFallback(),
  horizontalAlignment: responsiveFallback(),
  borderWidth: responsiveFallback("0px"),
  borderColor: responsiveFallback(),
  borderRadius: responsiveFallback(),
  fontSize: responsiveFallback(),
  lineHeight: responsiveFallback(),
  textColor: responsiveFallback(),
  fontWeight: responsiveFallback(),
  textAlign: responsiveFallback(),
};

const HANDLERS: PropertyHandlers = {
  padding: (value) => {
    const orderedSides = ["top", "right", "bottom", "left"] as const;
    return orderedSides.map((side) => `${value[side].value}${value[side].unit}`).join(" ");
  },
  margin: (value) => {
    if (!value.isEnabled) {
      return "initial";
    }

    const orderedSides = ["top", "bottom"] as const;
    return orderedSides.map((side) => `${value[side].value}${value[side].unit}`).join(" ");
  },
  maxWidth: (value) => {
    if (value.size === null) {
      return "initial";
    }

    return `${value.size}${value.unit}`;
  },
  background: (value) => {
    if (!value) {
      return "initial";
    }

    return value.color;
  },
  horizontalAlignment: (value) => {
    if (!value) {
      return "initial";
    }

    if (value === "left") {
      return "0 auto";
    }

    if (value === "right") {
      return "auto 0";
    }

    return "auto";
  },
  borderWidth: (value) => {
    const orderedSides = ["top", "right", "bottom", "left"] as const;
    return orderedSides.map((side) => `${value[side].value}px`).join(" ");
  },
  borderColor: (value) => {
    if (!value) {
      return "initial";
    }

    return value;
  },
  borderRadius: (value) => {
    const orderedSides = ["topLeft", "topRight", "bottomRight", "bottomLeft"] as const;
    return orderedSides.map((side) => `${value[side].size}${value[side].unit}`).join(" ");
  },
  fontSize: ({ fontSize: { size, unit }, isEnabled }) => {
    if (!isEnabled || size === null) {
      return "initial";
    }

    return `${size}${unit}`;
  },
  textColor: (value) => {
    if (!value) {
      return "initial";
    }

    return value.color;
  },
  fontWeight: (value) => {
    if (!value) {
      return "initial";
    }

    return String(value);
  },
  textAlign: (value) => {
    if (!value) {
      return "initial";
    }

    return value;
  },
};

export function generateBlockStyles(styles: BlockStylesPluginContent | undefined): BlockStyles {
  if (!styles) {
    return FALLBACK_VALUES;
  }

  const generatePropertyStyles = <TProperty extends Property>(property: TProperty) => {
    const propertyValue = styles[property];
    if (!isValidPropertyValue<TProperty>(propertyValue)) {
      return FALLBACK_VALUES[property];
    }

    const handler = HANDLERS[property];

    return {
      mobile: handler(propertyValue.mobile.value),
      tablet: handler(propertyValue.tablet.value),
      desktop: handler(propertyValue.desktop.value),
    };
  };

  return {
    padding: generatePropertyStyles("padding"),
    margin: generatePropertyStyles("margin"),
    maxWidth: generatePropertyStyles("maxWidth"),
    background: generatePropertyStyles("background"),
    horizontalAlignment: generatePropertyStyles("horizontalAlignment"),
    borderWidth: generatePropertyStyles("borderWidth"),
    borderColor: generatePropertyStyles("borderColor"),
    borderRadius: generatePropertyStyles("borderRadius"),
    fontSize: generatePropertyStyles("fontSize"),
    lineHeight: generateLineHeightStyles(styles.fontSize),
    textColor: generatePropertyStyles("textColor"),
    fontWeight: generatePropertyStyles("fontWeight"),
    textAlign: generatePropertyStyles("textAlign"),
  };
}

function generateLineHeightStyles(config: BlockStylesPluginContent["fontSize"]) {
  if (!config) {
    return FALLBACK_VALUES.lineHeight;
  }

  const generateBreakpointStyles = (breakpoint: BlockStylesBreakpoint) => {
    const {
      lineHeight: { unit, size },
      isEnabled,
    } = config[breakpoint].value;
    if (!isEnabled || size === null) {
      return "initial";
    }

    return `${size}${unit}`;
  };

  return {
    mobile: generateBreakpointStyles("mobile"),
    tablet: generateBreakpointStyles("tablet"),
    desktop: generateBreakpointStyles("desktop"),
  };
}

function isValidPropertyValue<TProperty extends Property>(
  property: BlockStylesPluginContent[Property],
): property is Required<BlockStylesPluginContent>[TProperty] {
  return !!property && typeof property === "object";
}

function responsiveFallback(value = "initial") {
  return {
    mobile: value,
    tablet: value,
    desktop: value,
  };
}
