import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import { getSystemColorScheme } from "../config/solution-sector-color-scheme";
import type { SbBlockComponentProps, SbStoryProps } from "../types";
import { SbAspectRatioImage } from "../components/image";
import { cn } from "../utils/misc";
import type { SolutionSectorPageBlockContent } from "./pages/solution-sector-page-block";

type Props = SbBlockComponentProps<{
  systems?: SbStoryProps<SolutionSectorPageBlockContent>[];
}>;

export function LandingPageSolutionsBlock({ blok }: Props) {
  const systems = blok.systems ?? [];

  return (
    <div
      {...storyblokEditable(blok)}
      className="grid grid-cols-2 gap-2 sm:gap-4 lg:grid-cols-4 xl:gap-6"
    >
      {systems.map((system) => {
        const colorScheme = getSystemColorScheme(system.content.system);

        return (
          <div
            className="aspect-h-1 aspect-w-1 overflow-hidden rounded-md border border-light-3 bg-light-t1 transition-all hover:opacity-80"
            key={system.uuid}
          >
            <Link className="flex flex-col" href={system.full_slug}>
              <div
                className={cn(
                  "h-1.5 w-full rounded-t-inherit sm:h-2 lg:h-2.5",
                  colorScheme.bgClass,
                )}
              />

              <div className="flex flex-grow flex-col">
                <div className="flex flex-grow items-center justify-center px-2">
                  <div className="truncate text-15 font-semibold sm:text-24 lg:text-20 xl:text-22">
                    {system.name}
                  </div>
                </div>

                <div className="flex-shrink overflow-hidden rounded-b-inherit border-t border-t-light-3">
                  <SbAspectRatioImage
                    aspectRatio="3/2"
                    cropWidth={600}
                    image={system.content.image}
                    size={{
                      base: "50vw",
                      lg: "25vw",
                      "2xl": 600,
                    }}
                  />
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
}
