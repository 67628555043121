import { useQuery } from "@tanstack/react-query";
import { getConfig } from "../api/storyblok";
import type { ConfigBlockContent, GetStoryResponse } from "../types";
import { QUERY_KEY } from "../config/constants";

const select = (data: GetStoryResponse<ConfigBlockContent>) => {
  return data.data.story.content;
};

export const useConfig = () => {
  return useQuery({
    queryKey: [QUERY_KEY.CONFIG],
    queryFn: getConfig,
    select,
  });
};
