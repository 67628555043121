import { type ISbRichtext, storyblokEditable } from "@storyblok/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import type { SbAsset, SbBlockComponentProps, SbLink } from "../../types";
import { Layout } from "../../components/layout";
import { Container } from "../../components/container";
import { RichTextContent } from "../../components/rich-text-content";
import { QUERY_KEY } from "../../config/constants";
import { getStories } from "../../api/storyblok";
import { SbAspectRatioImage } from "../../components/image";
import { Button } from "../../components/button";
import { humanFriendlyDateFormat } from "../../utils/datetime";
import { SkeletonLoader } from "../../components/skeleton-loader";
import { getSbLinkUrl, isEmptyRichText } from "../../utils/misc";
import { ExpandableText } from "../../components/expandable-text";

type EventPageBlockContent = {
  title?: string;
  image?: SbAsset;
  teaserDescription?: string;
  eventStartDate?: string;
  eventEndDate?: string;
  openingHours?: string;
  registrationLink?: SbLink;
  buttonText?: string;
};

type Props = SbBlockComponentProps<{
  title?: string;
  description?: ISbRichtext;
}>;

export function EventsListPageBlock({ blok }: Props) {
  const {
    data: events,
    isPending,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: [QUERY_KEY.UPCOMING_EVENTS],
    queryFn: async () => {
      const endOfYesterday = dayjs().subtract(1, "day").endOf("day").toISOString();

      const data = await getStories<EventPageBlockContent>({
        content_type: "eventPage",
        sort_by: "content.eventStartDate:asc",
        filter_query: {
          __or: [
            // The event starts in the future.
            { eventStartDate: { gt_date: endOfYesterday } },
            // OR the event spans multiple days and hasn't ended yet.
            { eventEndDate: { gt_date: endOfYesterday } },
          ],
        },
      });

      return data.data.stories;
    },
  });

  if (isError) {
    return null;
  }

  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-12 lg:mb-16">
        <Container>
          <div className="border-y border-light-3 py-6 xl:pl-28 2xl:pl-32">
            <h1 className="text-28 font-semibold lg:text-56">{blok.title}</h1>
            {!isEmptyRichText(blok.description) && (
              <div className="mt-3 max-w-4xl lg:mt-6">
                <RichTextContent content={blok.description} scale={{ lg: "lg" }} />
              </div>
            )}
          </div>
          <div className="mx-auto flex w-full max-w-5xl flex-col gap-6 pt-6">
            {!!isPending &&
              [1, 2, 3].map((i) => <SkeletonLoader className="h-96 md:h-80" key={i} />)}

            {!!isSuccess && events.length === 0 && (
              <div>Es gibt keine bevorstehenden Veranstaltungen.</div>
            )}

            {!!isSuccess &&
              events.length > 0 &&
              events.map((event) => <EventCard event={event.content} key={event.uuid} />)}
          </div>
        </Container>
      </div>
    </Layout>
  );
}

function EventCard({
  event: {
    image,
    title,
    eventStartDate,
    eventEndDate,
    openingHours,
    teaserDescription,
    registrationLink,
    buttonText,
  },
}: {
  event: EventPageBlockContent;
}) {
  return (
    <div className="grid grid-cols-1 overflow-hidden rounded-md border border-light-3 bg-light-1 md:grid-cols-2">
      <div className="md:p-8 md:pr-0">
        <SbAspectRatioImage
          aspectRatio="16/9"
          className="rounded-md border-b border-b-light-3 md:shadow-floating"
          cropWidth={800}
          image={image}
          size={800}
        />
      </div>
      <div className="p-4 md:p-8">
        <h2 className="line-clamp-2 font-semibold md:text-18 lg:text-20">{title}</h2>
        <div className="mt-1 flex items-center gap-2 text-14 md:text-16">
          {!!eventStartDate && humanFriendlyDateFormat(eventStartDate)}
          {!!eventEndDate && ` — ${humanFriendlyDateFormat(eventEndDate)}`}
        </div>
        {!!openingHours && <div className="text-12 text-light-8 md:text-14">{openingHours}</div>}

        <ExpandableText className="mt-4 text-14 md:text-16">{teaserDescription}</ExpandableText>
        <div className="mt-4 md:mt-5">
          <Button
            linkProps={{
              href: getSbLinkUrl(registrationLink) || "mailto:events@pts-group.de",
              target: "_blank",
            }}
          >
            {buttonText || "Kontakt"}
          </Button>
        </div>
      </div>
    </div>
  );
}
