import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import { getProductGroupColorScheme } from "../config/product-group-color-scheme";
import type { SbBlockComponentProps, SbStoryProps } from "../types";
import { SbAspectRatioImage } from "../components/image";
import { cn } from "../utils/misc";
import type { CategoryBlockContent } from "./pages/category-page-block";

type Props = SbBlockComponentProps<{
  categories?: SbStoryProps<CategoryBlockContent>[];
}>;

export function LandingPageCategoriesBlock({ blok }: Props) {
  const categories = blok.categories ?? [];

  return (
    <div {...storyblokEditable(blok)}>
      <div className="grid grid-cols-2 gap-2 sm:gap-4 lg:grid-cols-4 xl:gap-6">
        {categories.map((category) => {
          const colorScheme = getProductGroupColorScheme(category.content.productGroup);

          return (
            <div
              className={cn(
                "aspect-h-1 aspect-w-1 rounded-md transition-all hover:opacity-80",
                colorScheme.bgClass,
              )}
              key={category.full_slug}
            >
              <Link className="flex flex-col" href={category.full_slug}>
                <div
                  className={cn(
                    "truncate p-2 pb-5 text-14 font-semibold md:p-4 md:pb-6 lg:p-6 lg:pb-8 lg:text-16",
                    colorScheme.textOnBgClass,
                  )}
                >
                  {category.content.title}
                </div>
                <div className="flex-1 px-8 sm:px-10 md:px-12 lg:px-14">
                  <SbAspectRatioImage
                    aspectRatio="1/1"
                    cropWidth={500}
                    image={category.content.image}
                    size={{ base: "50vw", lg: "25vw", "2xl": 500 }}
                  />
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
