import { cn } from "../../../utils/misc";
import { NavLinkListItem } from "../nav-link-list-item";
import type { NavLinkBlockContent } from "../../../types";

export type MobileSubmenuProps = {
  title: string | undefined;
  links: NavLinkBlockContent[];
  _uid: string;
};

type Props = {
  submenu: MobileSubmenuProps;
  activeSubmenuId: string | null;
  refCallback: (element: HTMLDivElement | null) => void;
};

export function MobileNavSubmenu({ submenu, activeSubmenuId, refCallback }: Props) {
  return (
    <div
      className={cn(
        "absolute top-0 w-full px-3 py-5",
        activeSubmenuId === submenu._uid ? "visible" : "invisible",
      )}
      ref={refCallback}
    >
      <h2 className="mb-3 px-2 text-13 font-medium uppercase text-light-8">{submenu.title}</h2>
      <div className="grid gap-x-2 gap-y-2">
        {submenu.links.map((link) => (
          <NavLinkListItem key={link._uid} link={link} />
        ))}
      </div>
    </div>
  );
}
