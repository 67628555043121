import type { ReactNode } from "react";
import { useCallback, useState } from "react";
import dynamic from "next/dynamic";

type RenderLightBoxProps = {
  slides: { src: string; alt: string; description?: ReactNode }[]; // The Lightbox component handles sizing.
};

const Lightbox = dynamic(() => import("../components/lightbox/lightbox"));

const LIGHTBOX_INDEX_NULL = -1;

/**
 * Use this hook to open a lightbox so that related CSS and JS can be lazy-loaded.
 * You can use `renderLightbox` immediately - nothing will be loaded until `openLightbox` is called.
 */
export function useLightbox() {
  const [index, setIndex] = useState<number>(LIGHTBOX_INDEX_NULL);
  const [isInteractive, setIsInteractive] = useState(false);

  const openLightbox = useCallback(({ initialIndex }: { initialIndex?: number } = {}) => {
    setIndex(initialIndex ?? 0);
    setIsInteractive(true);
  }, []);

  const renderLightbox = useCallback(
    ({ slides }: RenderLightBoxProps) =>
      isInteractive ? (
        <Lightbox
          initialSlideIndex={index}
          isOpen={index >= 0}
          onClose={() => {
            setIndex(LIGHTBOX_INDEX_NULL);
          }}
          slides={slides}
        />
      ) : null,
    [index, isInteractive],
  );

  return { openLightbox, renderLightbox };
}
