import Link from "next/link";
import type { CSSProperties, ReactNode } from "react";
import { FiExternalLink } from "react-icons/fi";
import type { ISbRichtext } from "@storyblok/react";
import type { SbAsset, SbBlockComponentProps, SbLink } from "../types";
import { imageAspectRatio } from "../components/image/config";
import type { ImageAspectRatio, ImageSize } from "../components/image";
import { SbAspectRatioImage } from "../components/image";
import { cn, getSbLinkUrl, isEmptyRichText, parseSbNumberFieldValue } from "../utils/misc";
import { RichTextContent } from "../components/rich-text-content";
import { useLightbox } from "../hooks/use-lightbox";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type ImageBlockContent = {
  image?: SbAsset;
  aspectRatio?: string;
  objectFit?: "cover" | "contain";
  /**
   * Numeric string.
   */
  width?: string;
  /**
   * Numeric string. Only applied if no aspect ratio is set (aspectRatio: "original").
   */
  height?: string;
  caption?: ISbRichtext;
  isZoomEnabled?: boolean;
  link?: SbLink;
  hasBorder?: boolean;
  /**
   * Numeric string.
   */
  padding?: string;
  borderRadius?: "md" | "xl" | "3xl" | "full";
  shadow?: "small" | "medium" | "large";
  shouldEagerLoad?: boolean;
  alignment?: "left" | "center" | "right";
  captionAlignment?: "left" | "center" | "right";
  captionFontScale?: "base" | "sm" | "lg" | "xl" | "2xl";
};

type Props = SbBlockComponentProps<ImageBlockContent>;

const styles = {
  imageAlignment: (alignment: ImageBlockContent["alignment"]) => {
    return cn(alignment === "center" && "mx-auto", alignment === "right" && "ml-auto");
  },
  border: (hasBorder: ImageBlockContent["hasBorder"]) => {
    return cn(hasBorder && "border border-light-3");
  },
  borderRadius: (borderRadius: ImageBlockContent["borderRadius"]) => {
    return cn(
      borderRadius && "overflow-hidden",
      borderRadius === "md" && "rounded-md",
      borderRadius === "xl" && "rounded-xl",
      borderRadius === "3xl" && "rounded-3xl",
      borderRadius === "full" && "rounded-full",
    );
  },
  shadow: (shadow: ImageBlockContent["shadow"]) => {
    return cn(
      shadow === "small" && "shadow-button",
      shadow === "medium" && "shadow-elevated",
      shadow === "large" && "shadow-floating",
    );
  },
  zoom: cn(
    "cursor-zoom-in overflow-hidden transition-all",
    "[&_img]:transition-all [&_img]:duration-300 [&_img]:hover:scale-105 [&_img]:hover:opacity-90",
  ),
} as const;

export function ImageBlock({ blok }: Props) {
  const { openLightbox, renderLightbox } = useLightbox();

  if (!blok.image) {
    return null;
  }

  const width = parseSbNumberFieldValue(blok.width);
  const height = parseSbNumberFieldValue(blok.height);
  const padding = parseSbNumberFieldValue(blok.padding);
  const link = getSbLinkUrl(blok.link);
  const isZoomEnabled = blok.isZoomEnabled && !link;

  return (
    <BlockWrapper blok={blok}>
      <div
        className={cn(
          "table",
          width && "w-full table-fixed",
          !width && isValidAspectRatio(blok.aspectRatio) && "w-full",
          styles.imageAlignment(blok.alignment),
        )}
        style={{
          maxWidth: width ? `${width}px` : undefined,
        }}
      >
        <ImageWrapper
          className={cn(
            "group relative max-w-full",
            isZoomEnabled && styles.zoom,
            styles.border(blok.hasBorder),
            styles.borderRadius(blok.borderRadius),
            styles.shadow(blok.shadow),
          )}
          link={blok.link}
          onClick={() => {
            if (!isZoomEnabled) {
              return;
            }

            openLightbox();
          }}
          style={{
            width: width ? `${width}px` : undefined,
            height: height && !isValidAspectRatio(blok.aspectRatio) ? `${height}px` : undefined,
            padding: padding ? `${padding}px` : undefined,
          }}
        >
          {!isValidAspectRatio(blok.aspectRatio) && (
            <NonAspectRatioImage
              alt={blok.image.alt}
              height={height}
              objectFit={blok.objectFit}
              shouldEagerLoad={blok.shouldEagerLoad}
              src={blok.image.filename}
              width={width}
            />
          )}

          {isValidAspectRatio(blok.aspectRatio) && (
            <AspectRatioImage
              aspectRatio={blok.aspectRatio}
              cropWidth={width}
              image={blok.image}
              objectFit={blok.objectFit}
              shouldEagerLoad={blok.shouldEagerLoad}
              size={width}
            />
          )}

          {!!link && <LinkIndicator />}
        </ImageWrapper>

        {!isEmptyRichText(blok.caption) && (
          <Caption
            alignment={blok.captionAlignment}
            content={blok.caption}
            scale={blok.captionFontScale}
          />
        )}
      </div>

      {!!isZoomEnabled &&
        renderLightbox({
          slides: [
            {
              src: blok.image.filename,
              alt: blok.image.alt,
              description: <RichTextContent content={blok.caption} isLightTheme={true} />,
            },
          ],
        })}
    </BlockWrapper>
  );
}

function ImageWrapper({
  link,
  className,
  style,
  onClick,
  children,
}: {
  link: SbLink | undefined;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  children: ReactNode;
}) {
  const linkHref = getSbLinkUrl(link);

  const sharedProps = {
    className,
    style,
    onClick,
  };

  if (linkHref) {
    return (
      <Link
        {...sharedProps}
        className={cn(
          className,
          "block outline-brand-d transition-all hover:outline hover:outline-2",
        )}
        href={linkHref}
        target={link?.target}
      >
        {children}
      </Link>
    );
  }

  return <div {...sharedProps}>{children}</div>;
}

function NonAspectRatioImage({
  src,
  alt,
  width,
  height,
  objectFit,
  shouldEagerLoad,
}: {
  src: string | undefined;
  alt: string | undefined;
  width: number | undefined;
  height: number | undefined;
  objectFit: ImageBlockContent["objectFit"];
  shouldEagerLoad: boolean | undefined;
}) {
  return (
    // If we don't know the image dimensions, and aspect ratio is not set, it doesn't make sense to use Next.js Image.
    // eslint-disable-next-line @next/next/no-img-element -- TODO
    <img
      alt={alt}
      className={cn(
        "object-cover",
        width && "w-full",
        height && "h-full w-full",
        height && objectFit === "contain" && "object-contain",
      )}
      loading={shouldEagerLoad ? "eager" : "lazy"}
      src={src}
    />
  );
}

function AspectRatioImage({
  aspectRatio,
  objectFit,
  image,
  size,
  cropWidth,
  shouldEagerLoad,
}: {
  aspectRatio: ImageAspectRatio;
  objectFit: ImageBlockContent["objectFit"];
  image: SbAsset;
  size: ImageSize | undefined;
  cropWidth: number | undefined;
  shouldEagerLoad: boolean | undefined;
}) {
  return (
    <SbAspectRatioImage
      aspectRatio={aspectRatio}
      cropWidth={cropWidth}
      image={image}
      objectFit={objectFit}
      priority={shouldEagerLoad}
      size={size ?? "100vw"}
    />
  );
}

function Caption({
  content,
  alignment,
  scale,
}: {
  content: ImageBlockContent["caption"];
  alignment: ImageBlockContent["captionAlignment"];
  scale: ImageBlockContent["captionFontScale"];
}) {
  return (
    <div
      className={cn(
        "mt-3 table-caption w-full caption-bottom",
        alignment === "center" && "text-center",
        alignment === "right" && "text-right",
      )}
    >
      <RichTextContent content={content} scale={scale} />
    </div>
  );
}

function LinkIndicator() {
  return (
    <div
      className={cn(
        "absolute bottom-4 left-1/2 -translate-x-1/2",
        "inline-flex items-center justify-center rounded-full bg-brand-d p-2.5 text-white shadow-button md:p-3",
      )}
    >
      <FiExternalLink className="h-4 w-4 md:h-5 md:w-5" />
    </div>
  );
}

function isValidAspectRatio(value: string | undefined): value is ImageAspectRatio {
  return !!value && value in imageAspectRatio;
}
