import { TextLink } from "../text-link";
import { getSbLinkUrl } from "../../utils/misc";
import { Button } from "../button";
import { useConfig } from "../../hooks/use-config";

type Props = {
  onAcceptAll: () => void;
  onRejectAll: () => void;
  onSettingsClick: () => void;
};

export function CookieConsentBanner({ onAcceptAll, onRejectAll, onSettingsClick }: Props) {
  const { data: config, isSuccess: isConfigLoaded } = useConfig();

  if (!isConfigLoaded) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 z-30 bg-white px-6 py-4 shadow-floating">
      <div className="truncate text-18 font-bold sm:text-20">Datenschutzeinstellungen</div>
      <div className="mt-2 flex flex-col gap-5 md:flex-row md:items-center md:justify-between">
        <div className="text-14 sm:text-15">
          Wir nutzen Cookies auf unserer Webseite. Einige Cookies auf dieser Seite sind essentiell
          für den Betrieb der Seite und andere helfen Ihre Nutzungserfahrung zu verbessern.{" "}
          {!!config.cookiePageLink && (
            <TextLink href={getSbLinkUrl(config.cookiePageLink)}>Datenschutzerklärung</TextLink>
          )}
        </div>
        <div className="flex flex-col gap-2 sm:flex-row sm:items-center md:justify-end">
          <Button isFullWidth={true} onClick={onAcceptAll} size="medium" variant="normal">
            Alle akzeptieren
          </Button>

          <Button isFullWidth={true} onClick={onSettingsClick} size="medium" variant="secondary">
            Einstellungen
          </Button>

          <Button isFullWidth={true} onClick={onRejectAll} size="medium" variant="secondary">
            Ablehnen
          </Button>
        </div>
      </div>
    </div>
  );
}
