import algoliasearch from "algoliasearch";
import aa from "search-insights";
import Cookies from "js-cookie";

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
);

/**
 * Initializes with an anonymous cookie.
 */
export const initializeAlgoliaAnalytics = () => {
  aa("init", {
    appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
    useCookie: false,
  });
};

/**
 * Sets an identifiable cookie.
 */
export const initializeAlgoliaCookie = () => {
  aa("init", {
    partial: true,
    useCookie: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO
  generateAlgoliaUserToken().then((token) => {
    if (token) {
      aa("setUserToken", token);
    }
  });
};

/**
 * Generates a user token based on the Google Analytics cookie.
 *
 * https://www.algolia.com/doc/guides/sending-events/concepts/usertoken/#google-analytics
 *
 * Added a "ga-" prefix and replaced the "." separator with "-" because the Insights API
 * uses a regex that doesn't include dots.
 * https://github.com/algolia/instantsearch/blob/master/packages/algoliasearch-helper/src/utils/isValidUserToken.js
 *
 * Small hack: If the GA cookie is not available immediately (e.g. right after a user consents to tracking),
 * we make three attempts to get it with a 500ms interval.
 */
function generateAlgoliaUserToken() {
  let intervalId: NodeJS.Timeout | undefined;
  let numAttempts = 0;

  function maybeGenerateToken() {
    const cookieValue = Cookies.get("_ga");
    if (!cookieValue) {
      return;
    }

    const userIdParts = cookieValue.split(".").slice(-2);
    if (userIdParts.length !== 2) {
      return;
    }

    return `ga-${userIdParts.join("-")}`;
  }

  return new Promise<string | undefined>((resolve) => {
    const syncToken = maybeGenerateToken();
    if (syncToken) {
      resolve(syncToken);
      return;
    }

    intervalId = setInterval(() => {
      numAttempts++;

      const asyncToken = maybeGenerateToken();
      if (asyncToken) {
        window.clearInterval(intervalId);
        resolve(asyncToken);
        return;
      }

      if (numAttempts >= 3) {
        window.clearInterval(intervalId);
        resolve(undefined);
      }
    }, 500);
  });
}
