import { useEffect, useState } from "react";
import type { NextRouter } from "next/router";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { SB_ROOT_SLUGS } from "@pts/storyblok";
import { TextLink } from "./text-link";
import { Button } from "./button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./dialog";

const REDIRECT_QUERY_PARAM_KEY = "redirect";
const COOKIE_KEY = "redirect-notice-shown";
const COOKIE_TRUE_VALUE = "yes";
const COOKIE_EXPIRATION_DAYS = 365;

export function RedirectNoticeDialog() {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Only show the notice if we detect a redirect from an old site, and ideally only once per person.
   */
  useEffect(() => {
    async function maybeSetOpen() {
      if (!router.isReady) {
        return;
      }

      const hasBeenRedirected = router.query[REDIRECT_QUERY_PARAM_KEY] === "true";
      if (!hasBeenRedirected) {
        return;
      }

      const hasAlreadySeenNotice = Cookies.get(COOKIE_KEY) === COOKIE_TRUE_VALUE;
      if (hasAlreadySeenNotice) {
        return;
      }

      setIsOpen(true);
      Cookies.set(COOKIE_KEY, COOKIE_TRUE_VALUE, { expires: COOKIE_EXPIRATION_DAYS });
      await removeQueryParamFromUrl(router, REDIRECT_QUERY_PARAM_KEY);
    }

    void maybeSetOpen();
  }, [router]);

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Willkommen!</DialogTitle>
        </DialogHeader>
        <DialogDescription className="text-center sm:text-left">
          <div>Wir sind jetzt die PTS Group GmbH.</div>
          <div>
            Erfahren Sie mehr in{" "}
            <TextLink
              href={`/${SB_ROOT_SLUGS.NEWS}/die-neue-pts-group`}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              folgendem Artikel
            </TextLink>
            .
          </div>
        </DialogDescription>
        <DialogFooter>
          <DialogClose>
            <Button>OK</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

async function removeQueryParamFromUrl(router: NextRouter, paramKey: string) {
  const { [paramKey]: _redirectQueryParam, ...otherQueryParams } = router.query;

  await router.replace({ pathname: router.pathname, query: otherQueryParams }, undefined, {
    shallow: true,
  });
}
