import { useCallback, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useOnClickOutside } from "usehooks-ts";
import { useHits, useSearchBox } from "react-instantsearch";
import type { AlgoliaStoryObject } from "@pts/algolia";
import {
  DEBOUNCE_DURATION,
  MIN_SEARCH_QUERY_LENGTH,
  ROUTES,
  SEARCH_QUERY_PARAM_KEY,
} from "../../config/constants";
import { SearchContextProvider } from "../../context/search-context";
import { useQueryStorage } from "./_use-query-storage";
import { SearchHitsDropdown } from "./_search-hits-dropdown";
import { SavedQueriesDropdown } from "./_saved-queries-dropdown";
import { GlobalSearchInput } from "./_global-search-input";

export function GlobalSearch() {
  return (
    <SearchContextProvider>
      <GlobalSearchContent />
    </SearchContextProvider>
  );
}

function GlobalSearchContent() {
  const router = useRouter();
  const { hits, results, sendEvent } = useHits<AlgoliaStoryObject>();
  const { query, refine } = useSearchBox({ queryHook });
  const [inputValue, setInputValue] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const queryStorage = useQueryStorage();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClickOutside = useCallback(() => {
    setIsInputFocused(false);
  }, []);

  useOnClickOutside(wrapperRef, handleClickOutside);

  async function redirectToSearchPageWithQuery(searchQuery: string) {
    queryStorage.add(searchQuery);
    setIsInputFocused(false);
    inputRef.current?.blur();

    await router.push({
      pathname: ROUTES.SEARCH,
      query: {
        [SEARCH_QUERY_PARAM_KEY]: searchQuery,
      },
    });
  }

  async function handleSubmit() {
    if (inputValue.length < MIN_SEARCH_QUERY_LENGTH) {
      inputRef.current?.focus();
      return;
    }

    await redirectToSearchPageWithQuery(inputValue);
  }

  const hasSavedSearches = queryStorage.queries.length > 0;
  const shouldShowSavedSearches =
    isInputFocused && inputValue.length < MIN_SEARCH_QUERY_LENGTH && hasSavedSearches;
  const shouldShowHits =
    isInputFocused &&
    query.length > MIN_SEARCH_QUERY_LENGTH &&
    inputValue.length >= MIN_SEARCH_QUERY_LENGTH;

  return (
    <div className="relative w-full" ref={wrapperRef}>
      <GlobalSearchInput
        inputRef={inputRef}
        onChange={(value) => {
          refine(value);
          setInputValue(value);
        }}
        onFocus={() => {
          setIsInputFocused(true);
        }}
        onSubmit={handleSubmit}
        value={inputValue}
      />

      {!!(shouldShowHits || shouldShowSavedSearches) && (
        <div className="absolute left-0 right-0 top-full z-20 pt-2 lg:pt-2.5">
          {!!shouldShowHits && (
            <SearchHitsDropdown
              hits={hits}
              onHitClick={(hit) => {
                sendEvent("click", hit, "Hit Clicked [global search]");
                setIsInputFocused(false);
                queryStorage.add(query);
              }}
              onShowAllResultsClick={() => {
                void handleSubmit();
              }}
              totalHits={results?.nbHits ?? 0}
            />
          )}

          {!!shouldShowSavedSearches && (
            <SavedQueriesDropdown
              inputValue={inputValue}
              onClearAll={queryStorage.removeAll}
              onClearQuery={(q) => {
                queryStorage.removeOne(q);
              }}
              onSelectQuery={(searchQuery) => {
                void redirectToSearchPageWithQuery(searchQuery);
              }}
              queries={queryStorage.queries}
            />
          )}
        </div>
      )}
    </div>
  );
}

let timerId: NodeJS.Timeout | undefined;

function queryHook(query: string, search: (value: string) => void) {
  if (timerId) {
    clearTimeout(timerId);
  }

  timerId = setTimeout(() => {
    search(query);
  }, DEBOUNCE_DURATION);
}
