import { useQuery } from "@tanstack/react-query";
import { storyblokEditable } from "@storyblok/react";
import { QUERY_KEY } from "../../config/constants";
import { Layout } from "../../components/layout";
import { Container } from "../../components/container";
import { getPartners } from "../../api/storyblok";
import type { SbBlockComponentProps } from "../../types";
import { PartnerCard } from "../../components/partner-card/partner-card";
import { PartnerCardSkeleton } from "../../components/partner-card/partner-card-skeleton";

type Props = SbBlockComponentProps<{
  title?: string;
}>;

export function PartnersListPageBlock({ blok }: Props) {
  const { data, isPending, isSuccess } = useQuery({
    queryKey: [QUERY_KEY.PARTNERS],
    queryFn: getPartners,
  });

  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-12 lg:mb-16">
        <Container>
          <div className="border-t border-light-3 py-6 lg:pb-10">
            <h1 className="text-18 font-semibold lg:text-32">{blok.title}</h1>
          </div>
          <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6">
            {!!isPending &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                <PartnerCardSkeleton key={i} withLabel={true} />
              ))}

            {!!isSuccess &&
              data.map((partner) => (
                <PartnerCard
                  cropWidth={500}
                  key={partner.id}
                  logoVariant="thumbnail"
                  showLabel={true}
                  size={500}
                  story={partner}
                />
              ))}
          </div>
        </Container>
      </div>
    </Layout>
  );
}
