import Link from "next/link";
import type { ReactNode } from "react";
import type { SbAsset } from "../types";
import { SCREENS } from "../config/theme";
import { SkeletonLoader } from "./skeleton-loader";
import { SbAspectRatioImage } from "./image";

type Props = {
  image: SbAsset | undefined;
  href: string;
  title: string | undefined;
  subtitle?: ReactNode;
  shouldPreloadImage: boolean;
};

export function PostListCard({ image, href, title, subtitle, shouldPreloadImage }: Props) {
  return (
    <Link className="block transition-all hover:opacity-80" href={href}>
      <SbAspectRatioImage
        aspectRatio="16/9"
        className="rounded-md border border-light-3"
        cropWidth={SCREENS.lg}
        image={image}
        priority={shouldPreloadImage}
        size={{ base: "100vw", lg: SCREENS.lg }}
      />
      {!!subtitle && (
        <div className="mt-2 text-12 font-medium uppercase text-light-8 lg:mt-4 lg:text-14">
          {subtitle}
        </div>
      )}

      <div className="mt-2 line-clamp-3 text-22 font-semibold lg:text-40">{title}</div>
    </Link>
  );
}

export function PostListCardSkeleton({ hideSubtitle }: { hideSubtitle?: boolean }) {
  return (
    <div>
      <SkeletonLoader className="h-72 lg:h-96" />
      {!hideSubtitle && <SkeletonLoader className="mt-2 h-4 lg:mt-4 lg:h-5" />}
      <SkeletonLoader className="mt-2 h-14 lg:mt-4 lg:h-24" />
    </div>
  );
}
