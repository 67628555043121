import type { Hit } from "instantsearch.js";
import { useCallback, useRef, useState } from "react";
import type { AlgoliaStoryObject } from "@pts/algolia";
import { useElementResizeObserver } from "../../hooks/use-element-resize-observer";
import { SearchHit } from "./_search-hit";

type Props = {
  hits: Hit<AlgoliaStoryObject>[];
  totalHits: number;
  onHitClick: (hit: Hit<AlgoliaStoryObject>) => void;
  onShowAllResultsClick: () => void;
};

export function SearchHitsList({ hits, totalHits, onHitClick, onShowAllResultsClick }: Props) {
  const hitBoxRef = useRef<HTMLDivElement | null>(null);
  const [shouldShowAllResultsLink, setShouldShowAllResultsLink] = useState(false);

  const onResize = useCallback((entry: ResizeObserverEntry) => {
    const hasOverflow = entry.target.clientHeight < entry.target.scrollHeight;
    setShouldShowAllResultsLink(hasOverflow);
  }, []);

  useElementResizeObserver(hitBoxRef, onResize);

  return (
    <div>
      <div className="border-b border-foreground-t5 p-4 text-14 font-medium uppercase text-foreground-t2">
        {hits.length}/{totalHits} Ergebnissen
      </div>
      <div className="max-h-[62vh] overflow-y-auto" ref={hitBoxRef}>
        {hits.map((hit) => (
          <SearchHit
            hit={hit}
            key={hit.objectID}
            onClick={() => {
              onHitClick(hit);
            }}
          />
        ))}
      </div>
      {!!shouldShowAllResultsLink && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events -- TODO
        <div
          className="flex justify-center border-t border-foreground-t5 p-4 text-brand-d underline transition-all hover:bg-light-1 hover:text-foreground"
          onClick={onShowAllResultsClick}
          role="button"
          tabIndex={0}
        >
          Alle Suchergebnisse anzeigen
        </div>
      )}
    </div>
  );
}
