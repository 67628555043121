import type { SbBlockComponentProps, SbLink } from "../types";
import { Button } from "../components/button";
import { cn, getSbLinkUrl } from "../utils/misc";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  text?: string;
  link?: SbLink;
  variant?: "normal" | "material" | "subtle" | "link" | "secondary";
  isFullWidth?: boolean;
  alignment?: "left" | "center" | "right";
}>;

export function ButtonBlock({ blok }: Props) {
  const link = getSbLinkUrl(blok.link);

  return (
    <BlockWrapper blok={blok}>
      <div
        className={cn(
          "flex",
          blok.alignment === "left" && "justify-start",
          blok.alignment === "center" && "justify-center",
          blok.alignment === "right" && "justify-end",
        )}
      >
        <Button
          isFullWidth={blok.isFullWidth}
          linkProps={
            link
              ? {
                  href: link,
                  target: blok.link?.target,
                }
              : undefined
          }
          variant={blok.variant ?? "normal"}
        >
          {blok.text}
        </Button>
      </div>
    </BlockWrapper>
  );
}
