import { useQuery } from "@tanstack/react-query";
import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import type { SbBlockComponentProps } from "../../types";
import { Container } from "../../components/container";
import { QUERY_KEY } from "../../config/constants";
import { Layout } from "../../components/layout";
import { getMarkets } from "../../api/storyblok";
import { SbAspectRatioImage } from "../../components/image";
import { SkeletonLoader } from "../../components/skeleton-loader";

type Props = SbBlockComponentProps<{
  title?: string;
}>;

export function MarketsListPageBlock({ blok }: Props) {
  const { data, isSuccess, isPending } = useQuery({
    queryKey: [QUERY_KEY.MARKETS],
    queryFn: getMarkets,
  });

  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-12 lg:mb-16">
        <Container>
          <h1 className="text-28 font-semibold lg:text-56">{blok.title}</h1>
          <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-2 xl:grid-cols-3">
            {!!isPending &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                <div key={i}>
                  <SkeletonLoader className="h-72" />
                  <SkeletonLoader className="mt-2 h-6" />
                </div>
              ))}

            {!!isSuccess &&
              data.map((story) => (
                <Link
                  className="block transition-all hover:opacity-80"
                  href={story.full_slug}
                  key={story.uuid}
                >
                  <SbAspectRatioImage
                    aspectRatio="16/9"
                    className="rounded-md border border-light-3"
                    cropWidth={500}
                    image={story.content.image}
                    size={{ base: "100vw", md: "50vw", lg: "33vw", "2xl": 500 }}
                  />

                  <div className="mt-2 line-clamp-3 font-semibold sm:text-20 md:text-18 lg:text-20">
                    {story.name}
                  </div>
                </Link>
              ))}
          </div>
        </Container>
      </div>
    </Layout>
  );
}
