import { createContext, useContext } from "react";
import type { UseEmblaCarouselType } from "embla-carousel-react";

type CarouselThumbnailsContextState = {
  emblaThumbsApi: UseEmblaCarouselType[1];
};

export const CarouselThumbnailsContext = createContext<CarouselThumbnailsContextState | null>(null);

export const useCarouselThumbnailsContext = () => {
  const ctx = useContext(CarouselThumbnailsContext);

  if (!ctx) {
    throw new Error("Missing CarouselThumbnailsContext.");
  }

  return ctx;
};
