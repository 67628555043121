import type { QueryObserverSuccessResult } from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import type { GetStoryResponse } from "../../types";
import { getStoryBySlugOrId } from "../../api/storyblok";

type UseBreadcrumbSegmentsReturn =
  | { isPending: true; isError: false; segments: undefined }
  | { isPending: false; isError: true; segments: undefined }
  | { isPending: false; isError: false; segments: { href: string; label: string; id: string }[] };

/**
 * Fetches parent stories & constructs breadcrumb props for them.
 */
export function useParentBreadcrumbSegments(paths: string[]): UseBreadcrumbSegmentsReturn {
  const segmentQueries = useQueries({
    queries: paths.map((path) => {
      return {
        queryKey: ["slug", path],
        queryFn: () => getStoryBySlugOrId(path),
      };
    }),
  });

  if (paths.length === 0) {
    return {
      isError: false,
      isPending: false,
      segments: [],
    };
  }

  if (segmentQueries.every((query) => query.isError)) {
    return {
      isError: true,
      isPending: false,
      segments: undefined,
    };
  }

  if (segmentQueries.some((query) => query.isPending)) {
    return {
      isError: false,
      isPending: true,
      segments: undefined,
    };
  }

  const successfulQuerySegments = segmentQueries
    .filter(
      (query): query is QueryObserverSuccessResult<GetStoryResponse<unknown>> => query.isSuccess,
    )
    .map((query) => ({
      href: query.data.data.story.full_slug,
      id: query.data.data.story.full_slug,
      label: query.data.data.story.name,
    }));

  return {
    isError: false,
    isPending: false,
    segments: successfulQuerySegments,
  };
}
