export const COLORS = {
  transparent: "transparent",
  white: {
    t2: "rgba(255, 255, 255, 0.2)",
    DEFAULT: "#FFF",
  },
  black: {
    t5: "rgba(0, 0, 0, 0.12)",
    t4: "rgba(0, 0, 0, 0.2)",
    t3: "rgba(0, 0, 0, 0.33)",
    t2: "rgba(0, 0, 0, 0.5)",
    DEFAULT: "#000",
  },
  foreground: {
    t5: "rgba(0, 43, 74, 0.12)",
    t4: "rgba(0, 43, 74, 0.2)",
    t3: "rgba(0, 43, 74, 0.4)",
    t2: "rgba(0, 43, 74, 0.5)",
    "t1.5": "rgba(0, 43, 74, 0.65)",
    t1: "rgba(0, 43, 74, 0.8)",
    DEFAULT: "#002B4A",
  },
  error: "#EB0026",
  warning: "#D69D2B",
  confirmation: "#6EB925",
  brand: {
    a: {
      l2: "#E84D66",
      l1: "#E43450",
      DEFAULT: "#DE0024",
      d1: "#C80020",
      d2: "#B2001C",
    },
    b: {
      l2: "#4D9BBA",
      l1: "#348DB0",
      DEFAULT: "#00709C",
      d1: "#00658C",
      d2: "#005A7D",
    },
    c: {
      l2: "#4DC8A7",
      l1: "#34C09A",
      DEFAULT: "#00B081",
      d1: "#009E74",
      d2: "#008D67",
    },
    d: {
      l2: "#4DC9ED",
      l1: "#34C1EA",
      DEFAULT: "#00B2E5",
      d1: "#00A0CE",
      d2: "#008FB8",
    },
    e: {
      l2: "#A1E0D2",
      l1: "#94DCCC",
      DEFAULT: "#79D3BF",
      d1: "#6DBEAC",
      d2: "#61A999",
    },
    f: {
      l2: "#A886C5",
      l1: "#9C75BD",
      DEFAULT: "#8352AC",
      d1: "#76499B",
      d2: "#69418A",
    },
    g: {
      l2: "#FFE682",
      l1: "#FFE271",
      DEFAULT: "#FFDB4D",
      d1: "#E6C545",
      d2: "#CDB03D",
    },
    h: {
      l2: "#BDE497",
      l1: "#B4E089",
      DEFAULT: "#A1D86B",
      d1: "#91C260",
      d2: "#81AD56",
    },
  },
  light: {
    1: "#F8F9FA",
    t1: "#002B4A0D",
    2: "#EDEEF0",
    t2: "#002B4A17",
    3: "#E2E4E6",
    t3: "#002B4A1F",
    4: "#DDDEE1",
    t4: "#002B4A29",
    5: "#D6D8DC",
    t5: "#002B4A38",
    6: "#CCCED2",
    t6: "#002B4A42",
    7: "#C0C2C8",
    t7: "#002B4A4D",
    8: "#919296",
    t8: "#002B4A66",
  },
} as const;

/**
 * Used in tailwind config + code (with e.g. responsive breakpoint hooks).
 */
export const SCREENS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
} as const;

export const FONT_SIZES = {
  10: ["0.625rem", { lineHeight: "0.875rem" }], // 10px, 14px line height
  12: ["0.75rem", { lineHeight: "1rem" }], // 12px, 16px line height
  13: ["0.8125rem", { lineHeight: "1.1875rem" }], // 13px, 19px line height
  14: ["0.875rem", { lineHeight: "1.25rem" }], // 14px, 20px line height
  15: ["0.9375rem", { lineHeight: "1.375rem" }], // 15px, 22px line height
  16: ["1rem", { lineHeight: "1.5rem" }], // 16px, 24px line height
  17: ["1.0625rem", { lineHeight: "1.6rem" }], // 17px, 25.6px line height
  18: ["1.125rem", { lineHeight: "1.75rem" }], // 18px, 28px line height
  20: ["1.25rem", { lineHeight: "1.75rem" }], // 20px, 28px line height
  22: ["1.375rem", { lineHeight: "1.875rem" }], // 22px, 30px line height
  24: ["1.5rem", { lineHeight: "2rem" }], // 24px, 32px line height
  28: ["1.75rem", { lineHeight: "2.625rem" }], // 28px, 42px line height
  32: ["2rem", { lineHeight: "2.25rem" }], // 32px, 36px line height
  40: ["2.5rem", { lineHeight: "3.25rem" }], // 40px, 52px line height
  56: ["3.5rem", { lineHeight: "4.375rem" }], // 56px, 70px line height
} as const;
