import { useQuery } from "@tanstack/react-query";
import Link from "next/link";
import type { SbBlockContent, SbLink, MenuGroupBlockContent, MenuLinkBlockContent } from "../types";
import { QUERY_KEY } from "../config/constants";
import { getStoriesByUuids } from "../api/storyblok";
import { getSbLinkUrl } from "../utils/misc";
import { SkeletonLoader } from "./skeleton-loader";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./accordion";

export function AsideMenu({ groupIds }: { groupIds: string[] }) {
  const { data, isError, isPending } = useQuery({
    queryKey: [QUERY_KEY.STORIES_BY_UUIDS, groupIds],
    queryFn: () => getStoriesByUuids<MenuGroupBlockContent>(groupIds),
  });

  if (isError) {
    return null;
  }

  if (isPending) {
    return <SkeletonLoader className="h-80" />;
  }

  if (data.stories.length === 0) {
    return null;
  }

  return (
    <Accordion
      className="rounded-md border border-light-3 bg-light-3 lg:sticky-full-height"
      collapsible={true}
      type="single"
    >
      {data.stories.map((group) => {
        const itemsWithValidLinks =
          group.content.items?.filter(
            (item): item is SbBlockContent<MenuLinkBlockContent & { link: SbLink }> => !!item.link,
          ) ?? [];

        return (
          <AccordionItem
            className="mt-px overflow-hidden border-b-0 bg-light-1 first:mt-0 first:rounded-t-md last:rounded-b-md focus-within:relative focus-within:z-10"
            key={group.uuid}
            value={group.uuid}
          >
            <AccordionTrigger
              className="p-4 text-18 font-semibold shadow-[0_1px_0] shadow-light-3 lg:px-8 lg:py-6 lg:text-24"
              iconClassName="h-8 w-8"
            >
              {group.content.title}
            </AccordionTrigger>
            <AccordionContent className="bg-white">
              <ul className="flex flex-col gap-4 px-4 py-4 lg:gap-6 lg:px-8 lg:py-6">
                {itemsWithValidLinks.map((item) => (
                  <li className="truncate" key={item._uid}>
                    <Link
                      className="text-15 font-medium hover:underline"
                      href={getSbLinkUrl(item.link)}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}
