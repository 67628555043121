import { storyblokEditable } from "@storyblok/react";
import { FiArrowRight } from "react-icons/fi";
import { Button } from "../components/button";
import { getBrandColorScheme } from "../config/brand-color-scheme";
import { Carousel } from "../components/carousel/carousel";
import type { SbAsset, SbBlockComponentProps, SbStoryProps } from "../types";
import { SbAspectRatioImage } from "../components/image";
import { SCREENS } from "../config/theme";
import { cn } from "../utils/misc";
import { CarouselThumbnails } from "../components/carousel/carousel-thumbnails";
import { CarouselSlides } from "../components/carousel/carousel-slides";
import { CarouselThumbnail } from "../components/carousel/carousel-thumbnail";
import { CarouselSlide } from "../components/carousel/carousel-slide";
import type { ServicePageBlockContent } from "./pages/service-page-block";

type Props = SbBlockComponentProps<{
  servicePages?: SbStoryProps<ServicePageBlockContent>[];
}>;

export function LandingPageServicesBlock({ blok }: Props) {
  if (!blok.servicePages || blok.servicePages.length < 1) {
    return null;
  }

  return (
    <div {...storyblokEditable(blok)}>
      <ServicesCarousel pages={blok.servicePages} />
    </div>
  );
}

function ServicesCarousel({ pages }: { pages: SbStoryProps<ServicePageBlockContent>[] }) {
  return (
    <Carousel>
      <CarouselSlides>
        {pages.map((page) => (
          <CarouselSlide key={page.uuid}>
            <ServicesCarouselItem page={page} />
          </CarouselSlide>
        ))}
      </CarouselSlides>
      <CarouselThumbnails gridClasses="lg:-ml-6" wrapperClasses="lg:mt-6">
        {pages.map((page, index) => (
          <CarouselThumbnail className="lg:pl-6" index={index} key={page.uuid}>
            <ServicesCarouselThumbnail page={page} />
          </CarouselThumbnail>
        ))}
      </CarouselThumbnails>
    </Carousel>
  );
}

function ServicesCarouselItem({ page }: { page: SbStoryProps<ServicePageBlockContent> }) {
  const colorScheme = getBrandColorScheme(page.content.colorScheme?.value);

  return (
    <div className="relative">
      <div className="w-full overflow-hidden rounded-t-md border border-light-3 lg:w-3/4 lg:rounded-md xl:w-2/3">
        <ServicesCarouselImage image={page.content.image} />
      </div>
      <div
        className={cn(
          "rounded-b-md border border-t-0 border-light-3 p-4 py-6 lg:absolute lg:left-1/2 lg:right-0 lg:top-1/2 lg:-translate-y-1/2 lg:rounded-md lg:border-0 lg:p-6 2xl:right-40",
          colorScheme.bgClass,
          colorScheme.textClass,
        )}
      >
        <div className="line-clamp-2 text-18 font-semibold lg:text-40">{page.content.title}</div>
        <div className="mt-4 line-clamp-3 lg:mt-5">{page.content.description}</div>
        <div className="mt-4 lg:mt-6">
          <Button
            endIcon={<FiArrowRight size={24} />}
            linkProps={{ href: page.full_slug }}
            size="small"
            variant={colorScheme.buttonVariant}
          >
            Mehr
          </Button>
        </div>
      </div>
    </div>
  );
}

function ServicesCarouselThumbnail({ page }: { page: SbStoryProps<ServicePageBlockContent> }) {
  return (
    <div>
      <ServicesCarouselImage image={page.content.image} />
      <div className="mt-1 text-14 font-semibold lg:text-18">{page.content.title}</div>
    </div>
  );
}

function ServicesCarouselImage({ image }: { image: SbAsset | undefined }) {
  return (
    <SbAspectRatioImage
      aspectRatio="16/9"
      cropWidth={SCREENS.xl}
      image={image}
      size={{ base: "100vw", sm: SCREENS.lg, xl: SCREENS.xl }}
    />
  );
}
