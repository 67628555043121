import type { ReactNode } from "react";
import type { SbAsset } from "../types";
import { SCREENS } from "../config/theme";
import { ExpandableText } from "./expandable-text";
import { SbAspectRatioImage } from "./image";
import { NativeVideo } from "./native-video";

type Props = {
  image: SbAsset | undefined;
  video?: SbAsset;
  videoPoster?: SbAsset;
  title: string | undefined;
  description: ReactNode;
  beforeTitle?: ReactNode;
  aboveTitle?: ReactNode;
};

export function SystemSolutionParentPageHeader({
  image,
  video,
  videoPoster,
  title,
  description,
  beforeTitle,
  aboveTitle,
}: Props) {
  return (
    <div className="flex flex-col gap-6 border-t border-light-3 py-6 lg:mb-6 lg:flex-row lg:justify-between lg:gap-16 lg:border-b xl:gap-24 2xl:gap-36">
      <div className="shrink-0 lg:order-2 lg:w-[400px] xl:w-[520px] 2xl:w-[600px]">
        {video?.filename ? (
          <NativeVideo poster={videoPoster?.filename} src={video.filename} />
        ) : (
          <SbAspectRatioImage
            aspectRatio="16/9"
            className="rounded-md border border-light-3"
            cropWidth={SCREENS.md}
            image={image}
            priority={true}
            size={{ base: "100vw", lg: SCREENS.md }}
          />
        )}
      </div>

      <div className="lg:order-1 lg:flex lg:flex-col lg:justify-center">
        {!!aboveTitle && aboveTitle}

        <div className="flex gap-3 md:gap-4">
          {!!beforeTitle && beforeTitle}
          <h1 className="pr-4 text-18 font-semibold md:pr-6 md:text-32 lg:pr-0 lg:text-24 xl:text-32">
            {title}
          </h1>
        </div>

        <div className="mt-6 md:mt-4">
          <ExpandableText>{description}</ExpandableText>
        </div>
      </div>
    </div>
  );
}
