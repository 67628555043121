import type { ReactNode } from "react";
import { useCallback } from "react";
import { cn } from "../../utils/misc";
import { useCarouselContext } from "./carousel-context";
import { useCarouselThumbnailsContext } from "./carousel-thumbnails-context";

type Props = {
  index: number;
  className?: string;
  children: ReactNode;
};

export function CarouselThumbnail({ index, className, children }: Props) {
  const { emblaMainApi, selectedIndex } = useCarouselContext();
  const { emblaThumbsApi } = useCarouselThumbnailsContext();

  const onThumbClick = useCallback(
    (thumbIndex: number) => {
      if (!emblaMainApi || !emblaThumbsApi) {
        return;
      }
      emblaMainApi.scrollTo(thumbIndex);
    },
    [emblaMainApi, emblaThumbsApi],
  );

  return (
    <div
      className={cn(
        "relative min-w-0 shrink-0 grow-0 basis-[40vw] pl-2 sm:basis-[40%] sm:pl-4 lg:basis-[28%]",
        className,
      )}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events -- TODO */}
      <div
        className={cn(
          "w-full touch-manipulation transition-all",
          selectedIndex === index && "opacity-100",
          selectedIndex !== index && "opacity-40 hover:opacity-60",
        )}
        onClick={() => {
          onThumbClick(index);
        }}
        role="button"
        style={{
          textDecoration: "none",
        }}
        tabIndex={0}
      >
        {children}
      </div>
    </div>
  );
}
