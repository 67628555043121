import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export function SearchDropdownWrapper({ children }: Props) {
  return (
    <div className="min-h-[66px] rounded-md border border-black-t5 bg-white shadow-floating">
      {children}
    </div>
  );
}
