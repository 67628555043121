import type { ISbRichtext } from "@storyblok/react";
import { RichTextContent } from "../components/rich-text-content";
import type { SbBlockComponentProps } from "../types";
import { useSbBlockSetContext } from "../context/sb-block-set-context";
import { BlockTitle } from "./block-title";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  title?: string;
  body?: ISbRichtext;
}>;

export function RichTextBlock({ blok }: Props) {
  const { richTextScale } = useSbBlockSetContext();

  return (
    <BlockWrapper blok={blok}>
      <BlockTitle title={blok.title} />
      <RichTextContent content={blok.body} scale={richTextScale} />
    </BlockWrapper>
  );
}
