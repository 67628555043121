import type { PartnerCardBlockContent, SbBlockComponentProps, SbBlockContent } from "../types";
import { LazyPartnerCard } from "../components/partner-card/partner-card";
import { BlockTitle } from "./block-title";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  title?: string;
  partners?: SbBlockContent<PartnerCardBlockContent>[];
}>;

export function PartnerLogosBlock({ blok }: Props) {
  const partners = blok.partners ?? [];

  if (partners.length < 1) {
    return null;
  }

  return (
    <BlockWrapper blok={blok} className="my-6">
      <BlockTitle title={blok.title} />
      <div className="mt-6 grid grid-cols-3 gap-2 sm:grid-cols-4 sm:gap-4">
        {partners.map((partner) => (
          <LazyPartnerCard
            cropWidth={500}
            key={partner.partner}
            logoVariant={partner.logoVariant}
            size={500}
            uuid={partner.partner}
          />
        ))}
      </div>
    </BlockWrapper>
  );
}
