import type { QueryKey } from "@tanstack/react-query";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getStories } from "../api/storyblok";

type Params = {
  queryKey: QueryKey;
  contentType: string;
  sortBy?: string;
};

export const useInfiniteStoriesQuery = <TBlockContent>({
  queryKey,
  contentType,
  sortBy,
}: Params) => {
  return useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: async ({ pageParam }) => {
      const perPage = 8;

      const data = await getStories<TBlockContent>({
        content_type: contentType,
        per_page: perPage,
        page: pageParam,
        ...(sortBy && { sort_by: sortBy }),
      });

      return {
        ...data.data,
        page: pageParam,
        perPage,
        total: data.total,
      };
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.perPage >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    },
    initialPageParam: 1,
  });
};
