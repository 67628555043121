import type { ReactNode } from "react";
import { cn } from "../utils/misc";

const maxWidths = {
  "4xl": "lg:max-w-4xl",
  "5xl": "lg:max-w-5xl",
} as const;

type Props = {
  children: ReactNode;
  maxWidth?: keyof typeof maxWidths;
};

export function Container({ children, maxWidth }: Props) {
  return (
    <div className={cn("container mx-auto px-5 lg:px-8", maxWidth && maxWidths[maxWidth])}>
      {children}
    </div>
  );
}
