import { FiX } from "react-icons/fi";
import { SearchDropdownWrapper } from "./_search-dropdown-wrapper";

type Props = {
  queries: string[];
  onSelectQuery: (query: string) => void;
  onClearQuery: (query: string) => void;
  onClearAll: () => void;
  inputValue: string;
};

export function SavedQueriesDropdown({
  queries,
  onSelectQuery,
  onClearQuery,
  onClearAll,
  inputValue,
}: Props) {
  const shouldFilterByInputValue = inputValue.length > 0;

  const filteredQueries = shouldFilterByInputValue
    ? queries.filter((query) => query.includes(inputValue))
    : queries;

  if (filteredQueries.length === 0) {
    return null;
  }

  return (
    <SearchDropdownWrapper>
      <div className="pb-2.5 pt-3">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus -- TODO */}
        <div
          className="px-4 text-14 text-foreground-t2 underline transition-all hover:text-foreground"
          onClick={onClearAll}
          role="button"
        >
          Suchverlauf löschen
        </div>
        <div className="mt-2.5">
          {filteredQueries.map((query) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events -- TODO
            <div
              className="flex items-center justify-between py-1 pl-4 pr-2 hover:bg-light-t1"
              key={query}
              onClick={() => {
                onSelectQuery(query);
              }}
              role="button"
              tabIndex={0}
            >
              <span className="py-0.5">
                {shouldFilterByInputValue ? getHighlightedText(query, inputValue) : query}
              </span>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events -- TODO */}
              <div
                className="rounded-md px-2 py-1.5 transition-all hover:bg-light-t2"
                onClick={(event) => {
                  event.stopPropagation();
                  onClearQuery(query);
                }}
                role="button"
                tabIndex={0}
              >
                <FiX size={18} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </SearchDropdownWrapper>
  );
}

function getHighlightedText(text: string, highlight: string) {
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          // eslint-disable-next-line react/no-array-index-key -- TODO
          <span className="font-semibold" key={index}>
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </>
  );
}
