import dynamic from "next/dynamic";
import type { ReactNode } from "react";
import { SCREENS } from "../config/theme";
import type { Breakpoint } from "../types";

type Props = {
  from?: Breakpoint;
  to?: Breakpoint;
  children: ReactNode;
};

const MediaQueryBase = dynamic(() => import("react-responsive"), {
  ssr: false,
});

export function MediaQuery({ from, to, children }: Props) {
  return (
    <MediaQueryBase
      maxWidth={to ? SCREENS[to] - 1 : undefined}
      minWidth={from ? SCREENS[from] : undefined}
    >
      {children}
    </MediaQueryBase>
  );
}
