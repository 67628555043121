import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { cn } from "../utils/misc";
import { IconButton } from "./button";

type Props = {
  isOpen: boolean;
  align?: "center" | "start";
  onClose: () => void;
  children: ReactNode;
};

export function FullscreenOverlay({ align = "start", ...props }: Props) {
  const [transitioned, setTransitioned] = useState(false);

  useEffect(() => {
    if (props.isOpen) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-y-hidden");
    };
  }, [props.isOpen]);

  /**
   * Takes care of background opacity animation.
   */
  useEffect(() => {
    setTransitioned(true);
  }, []);

  return (
    <div
      className={cn(
        "fixed inset-0 z-50 flex flex-col bg-black-t2 duration-500",
        (!transitioned || !props.isOpen) && "invisible opacity-0",
        props.isOpen && transitioned && "visible opacity-100 transition-all",
      )}
    >
      <div className="fixed right-4 top-4">
        <IconButton
          ariaLabel="Schließen"
          icon={<FiX size={24} />}
          onClick={props.onClose}
          size="medium"
          variant="material"
        />
      </div>

      <div
        className={cn(
          "flex h-full origin-bottom flex-col overflow-hidden",
          !props.isOpen && "scale-90",
          props.isOpen && "transform-none transition-all",
          align === "center" && "justify-center",
        )}
      >
        {!!props.isOpen && props.children}
      </div>
    </div>
  );
}
