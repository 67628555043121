import type { MutableRefObject } from "react";
import { useEffect } from "react";
import { debounce } from "lodash-es";

export const useElementResizeObserver = (
  nodeRef: MutableRefObject<HTMLElement | null>,
  /**
   * You should wrap this in a useCallback to avoid unecessary observer reinitialization.
   */
  callback: (entry: ResizeObserverEntry) => void,
  debounceTime = 0,
) => {
  useEffect(() => {
    if (!nodeRef.current) {
      return;
    }

    const observer = new ResizeObserver(
      debounce((entries) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access -- TODO
        callback(entries[0]);
      }, debounceTime),
    );
    observer.observe(nodeRef.current);

    return () => {
      observer.disconnect();
    };
  }, [callback, debounceTime, nodeRef]);
};
