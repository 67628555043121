import type { NavMenuBlockContent } from "../../../types";
import { NavLinkListItem } from "../nav-link-list-item";

type Props = {
  blok: NavMenuBlockContent;
};

export function NavMenu({ blok }: Props) {
  const links = blok.content?.[0]?.links ?? [];

  return (
    <div className="grid w-[696px] grid-cols-2 gap-x-2 gap-y-2 bg-white p-4">
      {links.map((link) => (
        <NavLinkListItem key={link._uid} link={link} />
      ))}
    </div>
  );
}
