import type { ISbRichtext } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react";
import { Container } from "../../../components/container";
import type { SbAsset, SbBlockComponentProps, SbLink } from "../../../types";
import { Layout } from "../../../components/layout";
import { SbAspectRatioImage } from "../../../components/image";
import { MediaQuery } from "../../../components/media-query";
import { SCREENS } from "../../../config/theme";
import { ContactForm } from "./form";

type Props = SbBlockComponentProps<{
  image?: SbAsset;
  title?: string;
  privacyLink?: SbLink;
  isAgreedToTermsLabel?: string;
  doubleOptInLabelPrimary?: string;
  doubleOptInLabelSecondary?: string;
  doubleOptInTooltipContent?: ISbRichtext;
  newCustomerSubjects?: SubjectOptionProps;
  existingCustomerSubjects?: SubjectOptionProps;
}>;

type SubjectOptionProps = { label: string; value: string }[];

export function ContactFormPageBlock({ blok }: Props) {
  return (
    <Layout>
      <div className="mb-12" {...storyblokEditable(blok)}>
        <Container>
          <MediaQuery to="lg">
            <SbAspectRatioImage
              aspectRatio="16/10"
              className="rounded-md"
              cropWidth={SCREENS.lg}
              image={blok.image}
              priority={true}
              size="100vw"
            />
          </MediaQuery>

          <MediaQuery from="lg">
            <SbAspectRatioImage
              aspectRatio="16/5"
              className="rounded-md"
              cropWidth={SCREENS["2xl"]}
              image={blok.image}
              priority={true}
              size={{
                base: "100vw",
                "2xl": SCREENS["2xl"],
              }}
            />
          </MediaQuery>
        </Container>
        <Container maxWidth="4xl">
          <div className="mt-4 sm:mt-6 lg:mt-0 lg:-translate-y-12 lg:rounded-md lg:bg-white lg:px-12 lg:pt-12">
            <h1 className="text-28 font-semibold lg:text-56">{blok.title}</h1>
            <div className="mt-4 sm:mt-6 lg:mt-8">
              <ContactForm
                doubleOptInLabelPrimary={blok.doubleOptInLabelPrimary}
                doubleOptInLabelSecondary={blok.doubleOptInLabelSecondary}
                doubleOptInTooltipContent={blok.doubleOptInTooltipContent}
                existingCustomerSubjects={blok.existingCustomerSubjects}
                isAgreedToTermsLabel={blok.isAgreedToTermsLabel}
                newCustomerSubjects={blok.newCustomerSubjects}
                privacyLink={blok.privacyLink}
              />
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
}
