import { TbSearch } from "react-icons/tb";
import type { MutableRefObject } from "react";
import { TextInput } from "../form/text-input";
import { TextInputAdornment } from "../form/text-input-adornment";

type Props = {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => Promise<void>;
  onFocus: () => void;
  inputRef: MutableRefObject<HTMLInputElement | null>;
};

export function GlobalSearchInput({ value, onChange, onSubmit, onFocus, inputRef }: Props) {
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        void onSubmit();
      }}
    >
      <TextInput
        adornmentRight={
          <TextInputAdornment
            onClick={() => {
              void onSubmit();
            }}
          >
            <TbSearch size={20} />
          </TextInputAdornment>
        }
        onChange={(event) => {
          onChange(event.target.value);
        }}
        onFocus={onFocus}
        placeholder="Suche"
        ref={inputRef}
        value={value}
      />
    </form>
  );
}
