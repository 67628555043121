import type { SearchClient } from "algoliasearch";
import { InstantSearch } from "react-instantsearch";
import type { ReactNode } from "react";
import { searchClient } from "../lib/algolia";

type Props = {
  children: ReactNode;
};

const emptyResult = {
  hits: [],
  nbHits: 0,
  nbPages: 0,
  page: 0,
  processingTimeMS: 0,
  hitsPerPage: 0,
  exhaustiveNbHits: false,
  query: "",
  params: "",
};

const client: SearchClient = {
  ...searchClient,
  search(requests) {
    /**
     * By default, Algolia also fires a search request on mount when there is no query yet.
     *
     * https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react-hooks/.
     */
    const isEmptyQuery = requests.every(({ params }) => !params?.query);
    if (isEmptyQuery) {
      return Promise.resolve({ results: requests.map(() => emptyResult) });
    }

    return searchClient.search(requests);
  },
};

export function SearchContextProvider({ children }: Props) {
  return (
    <InstantSearch
      future={{
        preserveSharedStateOnUnmount: true,
      }}
      indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX}
      insights={true}
      searchClient={client}
    >
      {children}
    </InstantSearch>
  );
}
