import { ROUTES } from "../../config/constants";
import { useCurrentStory } from "../../context/sb-story-context";
import { SkeletonLoader } from "../skeleton-loader";
import { useParentBreadcrumbSegments } from "./_use-parent-breadcrumb-segments";
import { generateParentBreadcrumbSlugs } from "./_utils";
import { MobileBreadcrumbItems } from "./_mobile-breadcrumb-items";
import { DesktopBreadcrumbItems } from "./_desktop-breadcrumb-items";

const startPageSegment = {
  href: ROUTES.HOME,
  label: "Start",
  id: ROUTES.HOME,
};

export function PageBreadcrumbs() {
  const { full_slug: fullSlug, name } = useCurrentStory();

  const currentPageSegment = {
    href: fullSlug,
    label: name,
    id: fullSlug,
  };

  const parentPaths = generateParentBreadcrumbSlugs(fullSlug);
  const { segments, isError, isPending } = useParentBreadcrumbSegments(parentPaths);

  if (isError) {
    return null;
  }

  if (isPending) {
    return (
      <div className="overflow-x-hidden">
        <div className="h-5 w-40 lg:hidden">
          <SkeletonLoader className="h-full" />
        </div>
        <div className="hidden gap-4 lg:flex">
          {[startPageSegment.href, ...parentPaths, currentPageSegment.href].map((i) => (
            <div className="h-5 w-40" key={i}>
              <SkeletonLoader className="h-full" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  const items = [startPageSegment, ...segments, currentPageSegment];

  return (
    <nav className="text-foreground-t overflow-x-hidden text-foreground-t3">
      <div className="lg:hidden">
        <MobileBreadcrumbItems items={items} />
      </div>
      <div className="hidden lg:block xl:hidden">
        <DesktopBreadcrumbItems items={items} maxItems={3} />
      </div>
      <div className="hidden xl:block 2xl:hidden">
        <DesktopBreadcrumbItems items={items} maxItems={4} />
      </div>
      <div className="hidden 2xl:block">
        <DesktopBreadcrumbItems items={items} maxItems={5} />
      </div>
    </nav>
  );
}
