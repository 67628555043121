import { FiChevronDown } from "react-icons/fi";
import type { FocusEventHandler, MouseEventHandler } from "react";
import { useMemo } from "react";
import Link from "next/link";
import { cn, getSbLinkUrl } from "../../../utils/misc";
import type { EnhancedNavItemType } from "../use-enhanced-nav-items";

export function NavTrigger({
  onClick,
  onFocus,
  onMouseEnter,
  navItem,
  activeMenuIndex,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  onFocus: FocusEventHandler<HTMLButtonElement>;
  onMouseEnter: MouseEventHandler<HTMLButtonElement>;
  navItem: EnhancedNavItemType;
  activeMenuIndex: number | null;
}) {
  const classes = useMemo(
    () =>
      cn(
        "relative z-[2] mt-0.5 flex w-max select-none items-center rounded-md border-b-3 border-t-transparent px-3.5 py-3 text-15 font-medium transition-all",
        navItem.index !== activeMenuIndex && "hover:bg-light-t1",
        navItem.isActive ? "border-b-brand-d  shadow-button" : "border-b-transparent",
        navItem.isActive && navItem.index !== activeMenuIndex && "bg-light-t1",
      ),
    [activeMenuIndex, navItem.index, navItem.isActive],
  );

  if (navItem.component === "navigationLink") {
    return (
      <Link className={classes} href={getSbLinkUrl(navItem.link) ?? ""}>
        {navItem.title}
      </Link>
    );
  }

  return (
    <button
      className={cn(classes)}
      onClick={onClick}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      type="button"
    >
      {navItem.title}
      <FiChevronDown
        className={cn(
          "ml-0.5 mt-0.5 transition-transform duration-300",
          navItem.index === activeMenuIndex && "rotate-180",
        )}
        size={20}
      />
    </button>
  );
}
