import { FiChevronLeft, FiX } from "react-icons/fi";
import { Logo } from "../logo";
import { cn } from "../../../utils/misc";
import { Button, IconButton } from "../../button";

type Props = {
  onClose: () => void;
  activeSubmenuId: string | null;
  setActiveSubmenuId: (id: string | null) => void;
};

export function MobileNavHeader({ onClose, activeSubmenuId, setActiveSubmenuId }: Props) {
  return (
    <div className="relative min-h-[62px] overflow-hidden">
      <Logo
        className={cn(
          "absolute left-5 top-[18px] w-[70px] transition-all duration-300 ease-out md:w-[70px]",
          activeSubmenuId
            ? "pointer-events-none invisible -translate-x-full opacity-0"
            : "-translate-x-2 opacity-100",
        )}
      />
      <div
        className={cn(
          "absolute left-1.5 top-3 transition-all ease-out",
          activeSubmenuId
            ? "pointer-events-auto visible translate-x-0 opacity-100 duration-500"
            : "pointer-events-none invisible translate-x-full opacity-0 duration-300",
        )}
      >
        <Button
          className="gap-1 text-15"
          onClick={() => {
            setActiveSubmenuId(null);
          }}
          size="medium"
          startIcon={<FiChevronLeft className="h-4 w-4" />}
          variant="link"
        >
          Zurück
        </Button>
      </div>
      <IconButton
        ariaLabel="Close"
        className="absolute right-3.5 top-3 p-2"
        icon={<FiX className="h-6 w-6" />}
        onClick={onClose}
        size="medium"
        variant="link"
      />
    </div>
  );
}
