import { storyblokEditable } from "@storyblok/react";
import type { CSSProperties, ReactNode } from "react";
import type { SbBlockContent, WithBlockStyles } from "../../types";
import { SCREENS } from "../../config/theme";
import { cn } from "../../utils/misc";
import { generateBlockStyles } from "./block-wrapper.utils";

type Props = {
  blok: SbBlockContent<WithBlockStyles>;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
};

export function BlockWrapper({ blok, className, style, children }: Props) {
  const styles = generateBlockStyles(blok.blockStyles);

  return (
    <div {...storyblokEditable(blok)} className={cn("block-wrapper", className)} style={style}>
      <style jsx={true}>{`
        .block-wrapper {
          padding: ${styles.padding.mobile};
          margin-block: ${styles.margin.mobile};
          margin-inline: ${styles.horizontalAlignment.mobile};
          max-width: ${styles.maxWidth.mobile};
          background-color: ${styles.background.mobile};
          border-width: ${styles.borderWidth.mobile};
          border-color: ${styles.borderColor.mobile};
          border-radius: ${styles.borderRadius.mobile};
          color: ${styles.textColor.mobile};
          font-size: ${styles.fontSize.mobile};
          line-height: ${styles.lineHeight.mobile};
          font-weight: ${styles.fontWeight.mobile};
          text-align: ${styles.textAlign.mobile};
        }

        @media (min-width: ${SCREENS.md}px) {
          .block-wrapper {
            padding: ${styles.padding.tablet};
            margin-block: ${styles.margin.tablet};
            margin-inline: ${styles.horizontalAlignment.tablet};
            max-width: ${styles.maxWidth.tablet};
            background-color: ${styles.background.tablet};
            border-width: ${styles.borderWidth.tablet};
            border-color: ${styles.borderColor.tablet};
            border-radius: ${styles.borderRadius.tablet};
            color: ${styles.textColor.tablet};
            font-size: ${styles.fontSize.tablet};
            line-height: ${styles.lineHeight.tablet};
            font-weight: ${styles.fontWeight.tablet};
            text-align: ${styles.textAlign.tablet};
          }
        }

        @media (min-width: ${SCREENS.lg}px) {
          .block-wrapper {
            padding: ${styles.padding.desktop};
            margin-block: ${styles.margin.desktop};
            margin-inline: ${styles.horizontalAlignment.desktop};
            max-width: ${styles.maxWidth.desktop};
            background-color: ${styles.background.desktop};
            border-width: ${styles.borderWidth.desktop};
            border-color: ${styles.borderColor.desktop};
            border-radius: ${styles.borderRadius.desktop};
            color: ${styles.textColor.desktop};
            font-size: ${styles.fontSize.desktop};
            line-height: ${styles.lineHeight.desktop};
            font-weight: ${styles.fontWeight.desktop};
            text-align: ${styles.textAlign.desktop};
          }
        }
      `}</style>
      {children}
    </div>
  );
}
