import type { ReactNode } from "react";
import { useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { FullscreenOverlay } from "./fullscreen-overlay";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};

export function Modal(props: Props) {
  const contentRef = useRef(null);

  useOnClickOutside(contentRef, props.onClose);

  return (
    <FullscreenOverlay align="start" isOpen={props.isOpen} onClose={props.onClose}>
      <div className="mx-auto mt-[72px] h-full w-[720px] max-w-full px-4 pb-4 md:mt-24 md:px-8 md:pb-8">
        <div
          className="max-h-[calc(100%-88px)] justify-start overflow-y-auto rounded-xl bg-white shadow-elevated"
          ref={contentRef}
        >
          {props.children}
        </div>
      </div>
    </FullscreenOverlay>
  );
}
