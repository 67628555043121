import type { SbBlockComponentProps, SbAsset } from "../types";
import { NativeVideo } from "../components/native-video";
import { BlockTitle } from "./block-title";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  title?: string;
  video?: SbAsset;
  poster?: SbAsset;
}>;

export function NativeVideoBlock({ blok }: Props) {
  if (!blok.video) {
    return null;
  }

  return (
    <BlockWrapper blok={blok}>
      <BlockTitle title={blok.title} />
      <NativeVideo poster={blok.poster?.filename} src={blok.video.filename} />
    </BlockWrapper>
  );
}
