import Image from "next/image";
import logo from "../../assets/pts-logo-dark.png";
import { cn } from "../../utils/misc";

type Props = {
  className?: string;
};

export function Logo({ className }: Props) {
  return (
    <Image
      alt="PTS Group"
      className={cn("w-[80px] md:w-[115px]", className)}
      priority={true}
      src={logo}
    />
  );
}
