import { cn } from "../utils/misc";

type Props = {
  /**
   * Should include a height class.
   */
  className: string;
};

export function SkeletonLoader({ className }: Props) {
  return <div className={cn("w-full animate-pulse rounded-md bg-light-3", className)} />;
}
