import { FiArrowRight } from "react-icons/fi";
import { useCallback, useMemo, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { SbAspectRatioImage, SbImage } from "../../components/image";
import { SCREENS } from "../../config/theme";
import { cn, getSbLinkUrl } from "../../utils/misc";
import type { SbAsset, SbBlockContent, SbLink } from "../../types";
import { Button } from "../../components/button";
import { useCarouselContext } from "../../components/carousel/carousel-context";
import { SlideVideoLightbox } from "./slide-video-lightbox";

export type SlideBlockContent = {
  smallHeader?: string;
  header?: string;
  description?: string;
  image?: SbAsset;
  video?: SbAsset;
  buttonLink?: SbLink;
  buttonText?: string;
  colorScheme?: string;
};

type Props = {
  index: number;
  slide: SbBlockContent<SlideBlockContent>;
  prioritizeImageLoad: boolean;
};

const editorialColorScheme = {
  default: {
    backgroundColorClasses:
      "bg-foreground lg:bg-transparent lg:bg-gradient-to-r lg:from-foreground lg:to-transparent lg:from-35% lg:to-65%",
    desktopTextColorClasses: "text-white",
    loadingSpinnerClasses: "text-white",
    desktopButtonVariant: "material",
  },
  light: {
    backgroundColorClasses:
      "bg-light-1 lg:bg-transparent lg:bg-gradient-to-r lg:from-light-1 lg:to-transparent lg:from-35% lg:to-65%",
    desktopTextColorClasses: "text-foreground",
    loadingSpinnerClasses: "text-foreground",
    desktopButtonVariant: "normal",
  },
} as const;

const productPlacementColorScheme = {
  standard: {
    backgroundColorClasses: "bg-gradient-to-r from-brand-b-d2 to-brand-d",
    desktopTextColorClasses: "text-white",
    loadingSpinnerClasses: "text-white",
    desktopButtonVariant: "normal",
  },
  neutral: {
    backgroundColorClasses: "bg-light-2",
    desktopTextColorClasses: "text-foreground",
    loadingSpinnerClasses: "text-foreground",
    desktopButtonVariant: "normal",
  },
  light_blue: {
    backgroundColorClasses: "bg-gradient-to-r from-brand-d-d2 to-brand-d",
    desktopTextColorClasses: "text-white",
    loadingSpinnerClasses: "text-white",
    desktopButtonVariant: "normal",
  },
  dark_blue: {
    backgroundColorClasses: "bg-gradient-to-r from-brand-b-d2 to-brand-b",
    desktopTextColorClasses: "text-white",
    loadingSpinnerClasses: "text-white",
    desktopButtonVariant: "normal",
  },
  green: {
    backgroundColorClasses: "bg-gradient-to-r from-brand-c-d2 to-brand-c",
    desktopTextColorClasses: "text-white",
    loadingSpinnerClasses: "text-white",
    desktopButtonVariant: "normal",
  },
  light_green: {
    backgroundColorClasses: "bg-gradient-to-r from-brand-h-d2 to-brand-h",
    desktopTextColorClasses: "text-white",
    loadingSpinnerClasses: "text-white",
    desktopButtonVariant: "normal",
  },
  turquoise: {
    backgroundColorClasses: "bg-gradient-to-r from-brand-e-d2 to-brand-e",
    desktopTextColorClasses: "text-white",
    loadingSpinnerClasses: "text-white",
    desktopButtonVariant: "normal",
  },
  purple: {
    backgroundColorClasses: "bg-gradient-to-r from-brand-f-d2 to-brand-f",
    desktopTextColorClasses: "text-white",
    loadingSpinnerClasses: "text-white",
    desktopButtonVariant: "normal",
  },
  red: {
    backgroundColorClasses: "bg-gradient-to-r from-brand-a-d2 to-brand-a",
    desktopTextColorClasses: "text-white",
    loadingSpinnerClasses: "text-white",
    desktopButtonVariant: "normal",
  },
  yellow: {
    backgroundColorClasses: "bg-gradient-to-r from-brand-g-d2 to-brand-g",
    desktopTextColorClasses: "text-white",
    loadingSpinnerClasses: "text-white",
    desktopButtonVariant: "normal",
  },
} as const;

const isValidEditorialSlideColorScheme = (
  color: string | undefined,
): color is keyof typeof editorialColorScheme => {
  return !!color && color in editorialColorScheme;
};

const isValidProductPlacementSlideColorScheme = (
  color: string | undefined,
): color is keyof typeof productPlacementColorScheme => {
  return !!color && color in productPlacementColorScheme;
};

const getColorSchemeVariables = (
  component: string | undefined,
  colorScheme: string | undefined,
): {
  backgroundColorClasses: string;
  desktopTextColorClasses: string;
  desktopButtonVariant: "normal" | "material";
  loadingSpinnerClasses: string;
} => {
  if (component === "editorialSlide") {
    return isValidEditorialSlideColorScheme(colorScheme)
      ? editorialColorScheme[colorScheme]
      : editorialColorScheme.default;
  }

  if (component === "productPlacementSlide") {
    return isValidProductPlacementSlideColorScheme(colorScheme)
      ? productPlacementColorScheme[colorScheme]
      : productPlacementColorScheme.standard;
  }

  return productPlacementColorScheme.standard;
};

export function Slide({ slide, index, prioritizeImageLoad }: Props) {
  const { slidesInView } = useCarouselContext();
  const isInView = slidesInView.includes(index);

  const [hasLoaded, setHasLoaded] = useState(prioritizeImageLoad);

  const setLoaded = useCallback(() => {
    if (isInView) {
      setHasLoaded(true);
    }
  }, [isInView]);

  const {
    backgroundColorClasses,
    desktopTextColorClasses,
    desktopButtonVariant,
    loadingSpinnerClasses,
  } = useMemo(
    () => getColorSchemeVariables(slide.component, slide.colorScheme),
    [slide.component, slide.colorScheme],
  );

  return (
    <div>
      <div className={cn("relative flex items-center rounded-md", backgroundColorClasses)}>
        {slide.component === "editorialSlide" && !!slide.image && (
          <div className="absolute inset-0 rounded-md lg:left-1/3 lg:-z-10">
            {!!isInView && (
              <SbImage
                className={cn("object-cover", lazyLoadImageClasses(hasLoaded))}
                cropWidth={SCREENS.lg}
                fill={true}
                image={slide.image}
                onLoad={setLoaded}
                priority={prioritizeImageLoad}
                size={{
                  base: "100vw",
                  sm: SCREENS.md,
                  md: SCREENS.lg,
                  xl: SCREENS["2xl"],
                }}
              />
            )}
            {!hasLoaded && <ImageLoadingSpinner className={loadingSpinnerClasses} />}
          </div>
        )}

        <div className="hidden flex-grow lg:flex lg:flex-col lg:pl-28 xl:pl-36 2xl:pl-44">
          <div
            className={cn("line-clamp-1 text-14 font-medium uppercase", desktopTextColorClasses)}
          >
            {slide.smallHeader}
          </div>
          <h3 className={cn("mt-2 line-clamp-2 text-40 font-semibold", desktopTextColorClasses)}>
            {slide.header}
          </h3>
          <div className={cn("mt-6 line-clamp-3", desktopTextColorClasses)}>
            {slide.description}
          </div>

          {!!slide.buttonLink && (
            <div className="mt-6">
              <Button
                endIcon={<FiArrowRight size={24} />}
                linkProps={{ href: getSbLinkUrl(slide.buttonLink) }}
                size="medium"
                variant={desktopButtonVariant}
              >
                {slide.buttonText}
              </Button>
            </div>
          )}
        </div>
        <div className="flex w-full px-6 py-4 lg:w-auto lg:py-12 lg:pl-12 lg:pr-28 xl:py-10 xl:pl-16 xl:pr-40 2xl:pl-24 2xl:pr-60">
          <div className="relative mx-auto h-[215px] w-[215px] sm:h-[270px] sm:w-[270px] md:h-[360px] md:w-[360px] lg:h-[320px] lg:w-[320px] xl:h-[400px] xl:w-[400px]">
            {slide.component === "productPlacementSlide" && (
              <>
                {!!isInView && (
                  <SbAspectRatioImage
                    aspectRatio="1/1"
                    className={lazyLoadImageClasses(hasLoaded)}
                    cropWidth={600}
                    image={slide.image}
                    onLoad={setLoaded}
                    priority={prioritizeImageLoad}
                    size={{
                      base: "100vw",
                      lg: 600,
                    }}
                  />
                )}
                {!hasLoaded && <ImageLoadingSpinner className={loadingSpinnerClasses} />}
              </>
            )}

            {!!slide.video?.filename && !!hasLoaded && (
              <div className="absolute inset-0 flex items-center justify-center">
                <SlideVideoLightbox videoSrc={slide.video.filename} />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col pt-10 lg:hidden">
        <div className="line-clamp-1 text-12 font-medium uppercase text-foreground-t1">
          {slide.smallHeader}
        </div>
        <h3 className="mt-2 line-clamp-2 text-22 font-semibold">{slide.header}</h3>
        <div className="mt-4 line-clamp-3 text-14">{slide.description}</div>

        {!!slide.buttonLink && (
          <div className="mt-4">
            <Button
              endIcon={<FiArrowRight size={24} />}
              linkProps={{ href: getSbLinkUrl(slide.buttonLink) }}
              size="medium"
            >
              {slide.buttonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function ImageLoadingSpinner({ className }: { className: string }) {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <CgSpinner className={cn("animate-spin text-40", className)} />
    </div>
  );
}

const lazyLoadImageClasses = (hasLoaded: boolean) =>
  cn("transition-opacity", !hasLoaded && "opacity-0", hasLoaded && "opacity-100");
