import type { ISbRichtext } from "@storyblok/react";
import type { SbBlockComponentProps, SbAsset } from "../types";
import { RichTextContent } from "../components/rich-text-content";
import { SbAspectRatioImage } from "../components/image";
import { SCREENS } from "../config/theme";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

export type CardBlockContent = {
  text?: ISbRichtext;
  image?: SbAsset;
  title?: string;
  imageAspectRatio?: "1/1" | "16/9";
};

type Props = SbBlockComponentProps<CardBlockContent>;

export function CardBlock({ blok }: Props) {
  return (
    <BlockWrapper blok={blok}>
      <SbAspectRatioImage
        aspectRatio={blok.imageAspectRatio || "16/9"}
        className="rounded-md border border-light-3"
        cropWidth={SCREENS.lg}
        image={blok.image}
        size={{
          base: "100vw",
          lg: SCREENS.lg,
        }}
      />

      <div className="mt-2 lg:mt-3">
        <div className="font-semibold">{blok.title}</div>
        <div className="mt-2 lg:mt-2">
          <RichTextContent content={blok.text} />
        </div>
      </div>
    </BlockWrapper>
  );
}
