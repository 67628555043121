import type { ButtonBaseProps } from "../components/button/button-base";
import { COLORS } from "./theme";

type BrandColorScheme = {
  /**
   * Background color class.
   */
  bgClass: string;
  /**
   * Text color class.
   */
  textClass: string;
  /**
   * Button variant when placed on the scheme's background, i.e. `bgClass`
   */
  buttonVariant: ButtonBaseProps["variant"];
};

const BRAND_COLOR_SCHEME = {
  [COLORS.white.DEFAULT]: {
    bgClass: "bg-white",
    textClass: "text-foreground",
    buttonVariant: "normal",
  },
  [COLORS.black.DEFAULT]: {
    bgClass: "bg-black",
    textClass: "text-white",
    buttonVariant: "material",
  },
  [COLORS.foreground.DEFAULT]: {
    bgClass: "bg-foreground",
    textClass: "text-white",
    buttonVariant: "material",
  },
  [COLORS.brand.a.DEFAULT]: {
    bgClass: "bg-brand-a",
    textClass: "text-white",
    buttonVariant: "material",
  },
  [COLORS.brand.b.DEFAULT]: {
    bgClass: "bg-brand-b",
    textClass: "text-white",
    buttonVariant: "material",
  },
  [COLORS.brand.c.DEFAULT]: {
    bgClass: "bg-brand-c",
    textClass: "text-foreground",
    buttonVariant: "normal",
  },
  [COLORS.brand.d.DEFAULT]: {
    bgClass: "bg-brand-d",
    textClass: "text-foreground",
    buttonVariant: "normal",
  },
  [COLORS.brand.e.DEFAULT]: {
    bgClass: "bg-brand-e",
    textClass: "text-foreground",
    buttonVariant: "normal",
  },
  [COLORS.brand.f.DEFAULT]: {
    bgClass: "bg-brand-f",
    textClass: "text-white",
    buttonVariant: "material",
  },
  [COLORS.brand.g.DEFAULT]: {
    bgClass: "bg-brand-g",
    textClass: "text-foreground",
    buttonVariant: "normal",
  },
  [COLORS.brand.h.DEFAULT]: {
    bgClass: "bg-brand-h",
    textClass: "text-foreground",
    buttonVariant: "normal",
  },
} as const;

const isBrandColor = (color: string | undefined): color is keyof typeof BRAND_COLOR_SCHEME => {
  return !!color && color in BRAND_COLOR_SCHEME;
};

export const getBrandColorScheme = (color: string | undefined): BrandColorScheme => {
  if (!isBrandColor(color)) {
    return BRAND_COLOR_SCHEME[COLORS.foreground.DEFAULT];
  }

  return BRAND_COLOR_SCHEME[color];
};
