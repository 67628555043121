export const messageMaxChars = 4000;

export const customers: CustomerTypes[] = ["Neukunde", "Ich bin schon Kunde"];

export type CustomerTypes = "Neukunde" | "Ich bin schon Kunde";

export const orderQuestionsOptions = [
  {
    label: "Lieferstatus",
    value: "Lieferstatus",
  },
  {
    label: "Fragen zur Rechnung",
    value: "Fragen zur Rechnung",
  },
  {
    label: "Bestellung ändern oder stornieren",
    value: "Bestellung ändern oder stornieren",
  },
  {
    label: "Reklamation",
    value: "Reklamation",
  },
];
