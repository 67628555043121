import router from "next/router";
import { isSSR } from "./misc";

let isPreview = false;

/**
 * `setPreviewModeFlagDuringSsr` should be called during SSR of Storyblok content
 * so that both the server and the client are in sync when it comes to fetching draft vs. published content.
 */
export const isPreviewMode = () => {
  if (isSSR()) {
    return isPreview;
  }

  return router.isPreview;
};

export const setPreviewModeFlagDuringSsr = (value: boolean | undefined) => {
  isPreview = value ?? false;
};
