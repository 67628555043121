/**
 * Returns all slug segments except for the homepage & the current page, since we already have the data for them.
 *
 * For example, "abc/def/hijkl/mno" will return:
 * - "abc"
 * - "abc/def"
 * - "abc/def/hijkl"
 */
export function generateParentBreadcrumbSlugs(currentPageFullSlug: string) {
  return currentPageFullSlug
    .split("/")
    .filter(Boolean) // Removes empty parts, which can be caused by trailing slashes etc.
    .slice(0, -1) // We don't need the last part since the story name is already available in page props.
    .map((_, i, segments) => segments.slice(0, i + 1).join("/")); // Join each segment with whatever preceeds it.
}
