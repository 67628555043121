import { render } from "storyblok-rich-text-react-renderer";
import type { ISbRichtext } from "@storyblok/react";
import type { ClassValue } from "clsx";
import { clsx } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";
import { SB_ROOT_SLUGS } from "@pts/storyblok";
import type {
  ConfigBlockContent,
  SbBlockContent,
  SbLink,
  SbStoryLink,
  SbStoryProps,
  SocialLinkBlockContent,
  SocialNetwork,
} from "../types";
import { SOCIAL_LINKS_META } from "../config/constants";
import { FONT_SIZES } from "../config/theme";
import { getAllLinks } from "../api/storyblok";
import type { NewsPostPageBlockContent } from "../blocks/pages/news-post-page-block";

const twMergeCustom = extendTailwindMerge({
  override: {
    classGroups: {
      // https://github.com/dcastil/tailwind-merge/issues/217#issuecomment-1500652940
      "font-size": Object.keys(FONT_SIZES).map((size) => `text-${size}`),
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMergeCustom(clsx(inputs));
}

/**
 * e.g. https://www.example.com/file.pdf returns "pdf"
 */
export const getFileExtensionFromUrl = (url: string) => {
  return url.split(".").pop();
};

export const isEmptyRichText = (text: ISbRichtext | undefined) => {
  return render(text) === null;
};

type ValidSocialLink = SbBlockContent<
  SocialLinkBlockContent & { network: SocialNetwork; url: string }
>;

/**
 * Removes empty links and appends URLs.
 */
export const getSocialLinks = (config: ConfigBlockContent) => {
  if (!config.socialLinks) {
    return [];
  }

  return config.socialLinks
    .filter((link): link is ValidSocialLink => !!link.url && !!link.network)
    .map((link) => ({
      ...link,
      ...SOCIAL_LINKS_META[link.network],
    }));
};

export function getSbLinkUrl(link: SbLink): string;
export function getSbLinkUrl(link: undefined): undefined;
export function getSbLinkUrl(link: SbLink | undefined): string | undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO
export function getSbLinkUrl(link: SbLink | undefined): any {
  if (!link) {
    return undefined;
  }

  if (link.linktype === "url") {
    return link.url;
  }

  if (link.linktype === "story") {
    return link.story ? link.story.full_slug : link.cached_url;
  }

  return link.url ? `mailto:${link.url}` : undefined;
}

export const getNewsPostedDate = (news: SbStoryProps<NewsPostPageBlockContent>) => {
  return news.content.datePosted ?? news.first_published_at ?? null;
};

/**
 * Fetches all stories via /cdn/links and filters out folders, global config stories, etc.
 */
export const getAllStoryLinks = async (): Promise<SbStoryLink[]> => {
  const { data } = await getAllLinks();

  const result: SbStoryLink[] = [];

  Object.keys(data.links).forEach((uuid) => {
    const link = data.links[uuid];

    if (link.is_folder || link.slug.startsWith(`${SB_ROOT_SLUGS.GLOBAL}/`)) {
      return;
    }

    result.push(link);
  });

  return result;
};

/**
 * Checks if the current page is rendered within an iframe.
 */
export function isInsideStoryblokVisualEditor() {
  if (isSSR()) {
    return false;
  }

  return window.location !== window.parent.location;
}

export function isSSR() {
  return typeof window === "undefined" || typeof document === "undefined";
}

/**
 * Parses a "Number"-type field value from Storyblok.
 *
 * The value coming from Storyblok can be undefined, an empty string, or a string that can be parsed into a number.
 */
export function parseSbNumberFieldValue(value: string | undefined) {
  if (!value) {
    return undefined;
  }

  const asNumber = parseInt(value, 10);

  if (isNaN(asNumber)) {
    return undefined;
  }

  return asNumber;
}
