import type { ReactNode } from "react";
import { useCallback, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { cn } from "../../../utils/misc";
import { useConfig } from "../../../hooks/use-config";
import { SkeletonLoader } from "../../skeleton-loader";
import type { ConfigBlockContent } from "../../../types";
import type { EnhancedNavItemType } from "../use-enhanced-nav-items";
import { useEnhancedNavItems } from "../use-enhanced-nav-items";
import { NavNestedMenu } from "./_nav-nested-menu";
import { NavTrigger } from "./_nav-trigger";
import { NavMenu } from "./_nav-menu";
import { NavOverflowButton } from "./_nav-overflow-button";

const navWrapperBaseClasses = "flex h-[72px] rounded-md bg-light-t2 p-2.5";

export function DesktopNav({ onShowAllButtonClick }: { onShowAllButtonClick: () => void }) {
  const { data, isPending, isError } = useConfig();

  if (isError) {
    return null;
  }

  if (isPending) {
    return (
      <nav className={cn(navWrapperBaseClasses, "w-full gap-4 px-4 py-5")}>
        {[1, 2, 3, 4, 5].map((i) => (
          <div className="h-6 w-28 last:ml-auto" key={i}>
            <SkeletonLoader className="h-full" />
          </div>
        ))}
      </nav>
    );
  }

  return <DesktopNavContent config={data} onShowAllButtonClick={onShowAllButtonClick} />;
}

type PopoverStyle = {
  left: number;
  height: number;
  width: number;
};

type ActiveTriggerStyle = {
  left: number;
  width: number;
  height: number;
};

function DesktopNavContent({
  onShowAllButtonClick,
  config,
}: {
  onShowAllButtonClick: () => void;
  config: ConfigBlockContent;
}) {
  const navRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const contentRefs = useRef<(HTMLElement | null)[]>([]);
  const [activeMenuIndex, setActiveMenuIndex] = useState<number | null>(null);
  const { primaryItems, secondaryItems, contentItems } = useEnhancedNavItems({ config });
  const [popoverStyle, setPopoverStyle] = useState<PopoverStyle>({ left: 0, height: 0, width: 0 });
  const [activeTriggerStyle, setActiveTriggerStyle] = useState<ActiveTriggerStyle>({
    left: 0,
    width: 0,
    height: 0,
  });

  const closeMenu = useCallback(() => {
    setActiveMenuIndex(null);
  }, []);

  useOnClickOutside(navRef, closeMenu);

  const openMenu = useCallback(
    (navItem: EnhancedNavItemType, triggerElement: HTMLButtonElement) => {
      if (navItem.component === "navigationLink") {
        closeMenu();
        return;
      }

      if (navItem.index === activeMenuIndex) {
        closeMenu();
        return;
      }

      recalculatePopoverPosition(navItem, triggerElement);
      setActiveMenuIndex(navItem.index);
    },
    [activeMenuIndex, closeMenu],
  );

  function recalculatePopoverPosition(
    navItem: EnhancedNavItemType,
    triggerElement: HTMLButtonElement,
  ) {
    const contentRef = contentRefs.current[navItem.index];
    if (!contentRef || !navRef.current) {
      return;
    }

    setActiveTriggerStyle({
      left: triggerElement.offsetLeft,
      width: triggerElement.offsetWidth,
      height: triggerElement.offsetHeight,
    });

    const contentWidth = contentRef.offsetWidth;
    const overflowsNavbar = triggerElement.offsetLeft + contentWidth > navRef.current.offsetWidth;
    setPopoverStyle({
      left: overflowsNavbar ? navRef.current.offsetWidth - contentWidth : triggerElement.offsetLeft,
      height: contentRef.offsetHeight,
      width: contentRef.offsetWidth,
    });
  }

  const makeTriggerComponent = useCallback(
    (navItem: EnhancedNavItemType) => (
      <li key={navItem._uid}>
        <NavTrigger
          activeMenuIndex={activeMenuIndex}
          navItem={navItem}
          onClick={(event) => {
            openMenu(navItem, event.currentTarget);
          }}
          onFocus={(event) => {
            if (activeMenuIndex === null) {
              recalculatePopoverPosition(navItem, event.currentTarget);
            }
          }}
          onMouseEnter={(event) => {
            if (activeMenuIndex === null) {
              recalculatePopoverPosition(navItem, event.currentTarget);
            }
          }}
        />
      </li>
    ),
    [activeMenuIndex, openMenu],
  );

  return (
    <nav className="relative" ref={navRef}>
      <div
        className={cn(navWrapperBaseClasses, "flex-wrap justify-between overflow-hidden")}
        ref={menuRef}
      >
        <ul className="mr-60 flex flex-wrap gap-2">
          {primaryItems.map((navItem) => makeTriggerComponent(navItem))}
        </ul>
        <ul className="flex flex-wrap gap-2">
          {secondaryItems.map((navItem) => makeTriggerComponent(navItem))}
        </ul>
      </div>

      <NavOverflowButton
        closeMenu={closeMenu}
        menuRef={menuRef}
        onShowAllButtonClick={onShowAllButtonClick}
      />

      <ActiveTriggerBackground
        activeMenuIndex={activeMenuIndex}
        activeTriggerStyle={activeTriggerStyle}
      />

      <ActiveMenuPopover
        activeMenuIndex={activeMenuIndex}
        activeTriggerStyle={activeTriggerStyle}
        popoverStyle={popoverStyle}
      >
        {contentItems.map((navItem) => (
          <MenuContent
            activeMenuIndex={activeMenuIndex}
            key={navItem._uid}
            navItem={navItem}
            refCallback={(element) => {
              contentRefs.current[navItem.index] = element;
            }}
          />
        ))}
      </ActiveMenuPopover>
    </nav>
  );
}

function ActiveTriggerBackground({
  activeMenuIndex,
  activeTriggerStyle,
}: {
  activeTriggerStyle: ActiveTriggerStyle;
  activeMenuIndex: number | null;
}) {
  return (
    <div
      className={cn(
        "absolute bottom-2.5 top-2.5 z-[1] rounded-md bg-light-1 transition-all duration-300",
        activeMenuIndex === null ? "opacity-0" : "opacity-100",
      )}
      style={{
        width: `${activeTriggerStyle.width}px`,
        left: `${activeTriggerStyle.left}px`,
      }}
    />
  );
}

function ActiveMenuPopover({
  activeMenuIndex,
  popoverStyle,
  activeTriggerStyle,
  children,
}: {
  activeTriggerStyle: ActiveTriggerStyle;
  popoverStyle: PopoverStyle;
  activeMenuIndex: number | null;
  children: ReactNode;
}) {
  const arrowLeft = activeTriggerStyle.left + activeTriggerStyle.width / 2;

  return (
    <>
      <div
        className={cn(
          "absolute top-full h-4 w-4 translate-y-2/3 rotate-45 bg-light-3 transition-all duration-300",
          activeMenuIndex !== null
            ? "scale-100 opacity-100"
            : "pointer-events-none scale-0 opacity-0",
        )}
        style={{ left: `${arrowLeft}px` }}
      />
      <div
        className={cn(
          "absolute top-full z-10 pt-4 transition-all duration-300",
          activeMenuIndex === null ? "pointer-events-none opacity-0" : "opacity-100",
        )}
        style={{ left: `${popoverStyle.left}px` }}
      >
        <div
          className={cn(
            "relative box-content flex overflow-hidden rounded-md bg-light-3 p-1 shadow-floating transition-all duration-300",
          )}
          style={{
            height: `${popoverStyle.height}px`,
            width: `${popoverStyle.width}px`,
            minWidth: `${activeTriggerStyle.width}px`,
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
}

function MenuContent({
  navItem,
  activeMenuIndex,
  refCallback,
}: {
  navItem: EnhancedNavItemType;
  activeMenuIndex: number | null;
  refCallback: (element: HTMLElement | null) => void;
}) {
  return (
    <div
      className={cn(
        "transition-all duration-300",
        navItem.index === activeMenuIndex
          ? "opacity-100"
          : "pointer-events-none absolute opacity-0",
        (navItem.index === activeMenuIndex || activeMenuIndex === null) && "transform-none",
        activeMenuIndex && activeMenuIndex > navItem.index && "-translate-x-24",
        activeMenuIndex && activeMenuIndex > navItem.index && "translate-x-24",
      )}
      key={navItem._uid}
      ref={refCallback}
    >
      {navItem.component === "navigationMenu" && <NavMenu blok={navItem} />}
      {navItem.component === "navigationNestedMenu" && (
        <NavNestedMenu blok={navItem} isActive={activeMenuIndex === navItem.index} />
      )}
    </div>
  );
}
