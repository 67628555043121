import type { ReactNode } from "react";
import { useCallback, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { cn } from "../../utils/misc";
import { useCarouselContext } from "./carousel-context";
import { CarouselThumbnailsContext } from "./carousel-thumbnails-context";

type Props = {
  wrapperClasses?: string;
  gridClasses?: string;
  children: ReactNode;
};

export function CarouselThumbnails({ wrapperClasses, gridClasses, children }: Props) {
  const { emblaMainApi } = useCarouselContext();
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
  });

  const scrollToSelectedThumb = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) {
      return;
    }

    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaThumbsApi]);

  useEffect(() => {
    if (!emblaMainApi) {
      return;
    }

    scrollToSelectedThumb();
    emblaMainApi.on("reInit", scrollToSelectedThumb);
    emblaMainApi.on("select", scrollToSelectedThumb);
  }, [emblaMainApi, scrollToSelectedThumb]);

  return (
    <CarouselThumbnailsContext.Provider value={{ emblaThumbsApi }}>
      <div className={cn("mt-2 sm:mt-4", wrapperClasses)}>
        <div className="overflow-hidden" ref={emblaThumbsRef}>
          <div className={cn("-ml-2 flex sm:-ml-3 lg:-ml-4", gridClasses)}>{children}</div>
        </div>
      </div>
    </CarouselThumbnailsContext.Provider>
  );
}
