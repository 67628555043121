import { type ISbRichtext, storyblokEditable } from "@storyblok/react";
import type { ReactNode } from "react";
import { stringify } from "qs";
import type {
  SbBlockComponentProps,
  SbStoryProps,
  ContactFormReferrerAction,
  SbAsset,
  ProductGroup,
  SbBlockContent,
  PartnerCardBlockContent,
} from "../../types";
import { Container } from "../../components/container";
import { RichTextContent } from "../../components/rich-text-content";
import { Layout } from "../../components/layout";
import type { ProductGroupColorScheme } from "../../config/product-group-color-scheme";
import { getProductGroupColorScheme } from "../../config/product-group-color-scheme";
import { SbAspectRatioImage } from "../../components/image";
import { Button } from "../../components/button";
import { useContactPersonQuery } from "../../hooks/use-contact-person-query";
import { SkeletonLoader } from "../../components/skeleton-loader";
import { TextLink } from "../../components/text-link";
import { LazyPartnerCard } from "../../components/partner-card/partner-card";
import { cn } from "../../utils/misc";
import { REFERRER_ACTION_QUERY_PARAM_KEY, ROUTES } from "../../config/constants";

type ProductFamilyBlockContent = {
  title?: string;
  image?: SbAsset;
  description?: string;
  productGroup?: ProductGroup;
};

export type CategoryBlockContent = {
  title?: string;
  image?: SbAsset;
  seoText?: ISbRichtext;
  categoryProducts?: SbStoryProps<ProductFamilyBlockContent>[];
  productGroup?: ProductGroup;
  contactPerson?: string;
  partners?: SbBlockContent<PartnerCardBlockContent>[];
};

type Props = SbBlockComponentProps<CategoryBlockContent>;

export function CategoryPageBlock({ blok }: Props) {
  const colorScheme = getProductGroupColorScheme(blok.productGroup);

  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-16 lg:mb-24">
        <Container>
          <div
            className={cn(
              "flex items-center gap-3 rounded-md p-3 lg:gap-6 lg:px-5 lg:py-4",
              colorScheme.bgClass,
            )}
          >
            <div className="w-[56px] lg:w-[96px]">
              <SbAspectRatioImage
                aspectRatio="1/1"
                cropWidth={100}
                image={blok.image}
                priority={true}
                size={100}
              />
            </div>

            <h1
              className={cn("truncate text-18 font-semibold lg:text-40", colorScheme.textOnBgClass)}
            >
              {blok.title}
            </h1>
          </div>
          <div className="mt-6 flex flex-col gap-10 md:gap-12 lg:mt-8 lg:gap-14 xl:flex-row xl:gap-14 2xl:gap-20">
            <div className="min-w-0 flex-grow">
              <div className="max-w-4xl">
                <RichTextContent content={blok.seoText} scale={{ lg: "lg" }} />
              </div>
              {!!blok.categoryProducts && blok.categoryProducts.length > 0 && (
                <GridWrapper title="Kategorien">
                  {blok.categoryProducts.map((category) => (
                    <ProductFamilyCard
                      colorScheme={colorScheme}
                      family={category}
                      key={category.id}
                    />
                  ))}
                </GridWrapper>
              )}
              {!!blok.partners && blok.partners.length > 0 && (
                <GridWrapper hasUniformSpacing={true} title="Partner">
                  {blok.partners.map((partner) => (
                    <LazyPartnerCard
                      cropWidth={600}
                      key={partner.partner}
                      logoVariant={partner.logoVariant}
                      size={600}
                      uuid={partner.partner}
                    />
                  ))}
                </GridWrapper>
              )}
            </div>
            <div className="flex flex-col xl:flex-shrink-0 xl:flex-grow-0 xl:basis-[375px] 2xl:basis-[450px]">
              <div className="xl:sticky-full-height">
                {!!blok.contactPerson && <ContactPersonBox uuid={blok.contactPerson} />}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

function GridWrapper({
  title,
  hasUniformSpacing,
  children,
}: {
  title: string;
  hasUniformSpacing?: boolean;
  children: ReactNode;
}) {
  return (
    <div className="mt-8 lg:mt-12">
      <h2 className="text-22 font-semibold lg:text-40">{title}</h2>
      <div
        className={cn(
          "mt-4 grid grid-cols-[repeat(auto-fit,minmax(153px,1fr))] sm:grid-cols-3 lg:mt-6 lg:grid-cols-4 xl:grid-cols-3",
          hasUniformSpacing && "gap-3 lg:gap-4",
          !hasUniformSpacing && "gap-x-3 gap-y-6 lg:gap-x-4 lg:gap-y-8",
        )}
      >
        {children}
      </div>
    </div>
  );
}

function ProductFamilyCard({
  family,
  colorScheme,
}: {
  family: SbStoryProps<ProductFamilyBlockContent>;
  colorScheme: ProductGroupColorScheme;
}) {
  return (
    <div className="flex flex-col">
      <div className="overflow-hidden rounded-md border border-light-3">
        <SbAspectRatioImage
          aspectRatio="1/1"
          cropWidth={600}
          image={family.content.image}
          size={{
            base: "50vw",
            lg: "25vw",
            "2xl": 600,
          }}
        />
      </div>

      <h3 className="mt-4 hyphens-auto font-semibold md:text-18 2xl:text-22" lang="de">
        {family.content.title}
      </h3>

      <div className="mt-2 w-24 border-b-2" style={{ borderColor: colorScheme.hexColor }} />

      {!!family.content.description && (
        <div className="mt-4 line-clamp-4 hyphens-auto text-14" lang="de">
          {family.content.description}
        </div>
      )}

      <div className="mt-auto pt-4">
        <Button
          isFullWidth={true}
          linkProps={{ href: getProductFamilyButtonLink(family) }}
          size="small"
        >
          Anfrage & Beratung
        </Button>
      </div>
    </div>
  );
}

/**
 * Generates a link to the contact form page with a refferer action query parameter (used in email).
 */
function getProductFamilyButtonLink(family: SbStoryProps<ProductFamilyBlockContent>) {
  const referrerAction: ContactFormReferrerAction = {
    type: "category-inquiry-button-click",
    categoryName: family.name,
    categoryUrl: family.full_slug,
    categoryUuid: family.uuid,
  };

  const queryString = stringify({
    [REFERRER_ACTION_QUERY_PARAM_KEY]: JSON.stringify(referrerAction),
  });

  return `${ROUTES.CONTACT_FORM}?${queryString}`;
}

function ContactPersonBox(props: { uuid: string }) {
  const { data, isPending, isError, isSuccess } = useContactPersonQuery(props.uuid);

  if (isError) {
    return null;
  }

  return (
    <div className="rounded-md border border-light-3 bg-light-1 p-4 sm:p-6">
      <h5 className="text-20 font-semibold sm:text-24 xl:text-22">Kontakt</h5>
      <div className="mt-4 sm:mt-6 xl:mt-4">
        {!!isPending && <SkeletonLoader className="h-24" />}
        {!!isSuccess && (
          <div className="flex gap-3 sm:gap-5 xl:gap-4">
            <div className="w-[100px] sm:w-[180px] xl:w-[130px]">
              <SbAspectRatioImage
                aspectRatio="1/1"
                className="rounded-md border border-light-3"
                cropWidth={300}
                image={data.photo}
                size={300}
              />
            </div>

            <div>
              <div className="text-15 font-semibold sm:text-20 xl:text-18">{data.name}</div>
              <div className="text-14 sm:text-16 xl:text-15">{data.title}</div>
              <div className="mt-5 flex flex-col text-14 sm:text-16 xl:text-15">
                <TextLink href={`tel:${data.phone_number}`} variant="highlighted">
                  {data.phone_number}
                </TextLink>
                <TextLink href={`mailto:${data.email}`} target="_blank" variant="highlighted">
                  {data.email}
                </TextLink>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
