import NextImage from "next/image";
import { cn } from "../../utils/misc";
import { imageAspectRatio } from "./config";
import type { AspectRatioImageProps } from "./types";
import { generateImageSizes } from "./utils";

export function AspectRatioImage({
  aspectRatio,
  className,
  src,
  alt,
  size,
  priority,
  style,
  onLoad,
  onClick,
  draggable,
  objectFit = "cover",
}: AspectRatioImageProps) {
  return (
    <div
      className={cn(
        "relative w-full overflow-hidden",
        imageAspectRatio[aspectRatio].className,
        className,
      )}
    >
      <NextImage
        alt={alt}
        className="rounded-inherit"
        draggable={draggable}
        fill={true}
        onClick={onClick}
        onLoad={onLoad}
        priority={priority}
        quality={80}
        sizes={generateImageSizes(size)}
        src={src}
        style={{
          objectFit,
          objectPosition: "center",
          ...style,
        }}
      />
    </div>
  );
}
