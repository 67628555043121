import { FaPlay } from "react-icons/fa";
import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useOnClickOutside } from "usehooks-ts";
import { IconButton } from "../../components/button";
import { FullscreenOverlay } from "../../components/fullscreen-overlay";
import { NativeVideo } from "../../components/native-video";
import { Container } from "../../components/container";
import { PORTAL_ROOT_NODE_ID } from "../../config/constants";

type Props = {
  videoSrc: string;
};

export function SlideVideoLightbox(props: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton
        ariaLabel="Video abspielen"
        icon={<FaPlay size={22} />}
        isCircle={true}
        onClick={() => {
          setIsOpen(true);
        }}
        size="large"
        variant="material"
      />
      {!!isOpen &&
        createPortal(
          <VideoLightBox
            onClose={() => {
              setIsOpen(false);
            }}
            videoSrc={props.videoSrc}
          />,
          document.getElementById(PORTAL_ROOT_NODE_ID) as HTMLDivElement,
        )}
    </>
  );
}

function VideoLightBox(props: { videoSrc: string; onClose: () => void }) {
  const contentRef = useRef(null);

  useOnClickOutside(contentRef, props.onClose);

  return (
    <FullscreenOverlay align="center" isOpen={true} onClose={props.onClose}>
      <Container maxWidth="4xl">
        <div className="mb-16" ref={contentRef}>
          <NativeVideo autoPlay={true} src={props.videoSrc} />
        </div>
      </Container>
    </FullscreenOverlay>
  );
}
