import { useEffect } from "react";
import Cookies from "js-cookie";
import { isInsideStoryblokVisualEditor } from "../../utils/misc";
import { initializeAlgoliaCookie } from "../../lib/algolia";
import type { CookieConsentState } from "../../types";
import { CONSENT_STATE_COOKIE_KEY } from "./cookie-constants";

export function useTrackingInit({ isEnabled }: { isEnabled: boolean }) {
  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    maybeInitializeTrackers();
  }, [isEnabled]);
}

function maybeInitializeTrackers() {
  if (isInsideStoryblokVisualEditor()) {
    return;
  }

  const consentState = getConsentState();

  if (consentState?.analyticsConsentGranted) {
    initializeAlgoliaCookie();
  }
}

function getConsentState() {
  const cookieValue = Cookies.get(CONSENT_STATE_COOKIE_KEY);
  if (!cookieValue) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO
  const state = JSON.parse(cookieValue);
  if (!isValidConsentState(state)) {
    return;
  }

  return state;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO
function isValidConsentState(state: any): state is Partial<CookieConsentState> {
  return typeof state === "object";
}
