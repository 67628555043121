import { storyblokEditable } from "@storyblok/react";
import { Container } from "../../components/container";
import { BlockSet } from "../block-set";
import type { SbBlockComponentProps, SbBlockSet } from "../../types";
import { Layout } from "../../components/layout";

type Props = SbBlockComponentProps<{
  title?: string;
  body?: SbBlockSet;
}>;

export function GenericPageBlock({ blok }: Props) {
  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-12 mt-6 lg:mb-16">
        <Container>
          <div className="xl:ml-28 2xl:ml-32">
            <h1 className="text-24 font-semibold lg:text-40">{blok.title}</h1>
            <div className="mt-2 h-1 w-[40px] rounded-md bg-brand-b md:h-[6px] md:w-[60px] xl:h-2 xl:w-[80px]" />
            <div className="mt-6 max-w-4xl">
              <BlockSet blocks={blok.body} richTextScale={{ lg: "lg" }} />
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
}
