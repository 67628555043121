/* eslint-disable react/no-array-index-key -- TODO */
import { useCallback, useEffect, useState } from "react";
import { cn } from "../../utils/misc";
import { useCarouselContext } from "./carousel-context";

export function CarouselDots() {
  const { emblaMainApi, selectedIndex } = useCarouselContext();
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const updateScrollSnaps = useCallback(() => {
    if (!emblaMainApi) {
      return;
    }
    setScrollSnaps(emblaMainApi.scrollSnapList());
  }, [emblaMainApi]);

  const scrollTo = useCallback(
    (index: number) => {
      if (!emblaMainApi) {
        return;
      }
      emblaMainApi.scrollTo(index);
    },
    [emblaMainApi],
  );

  useEffect(() => {
    if (!emblaMainApi) {
      return;
    }

    updateScrollSnaps();
    emblaMainApi.on("reInit", updateScrollSnaps);
  }, [emblaMainApi, updateScrollSnaps]);

  return (
    <div className="absolute bottom-3 left-0 right-0">
      <div className="hidden w-full items-center justify-center gap-2 lg:flex">
        {scrollSnaps.map((_, index) => {
          const isSelected = index === selectedIndex;

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus -- TODO
            <div
              aria-disabled={isSelected}
              aria-label={
                isSelected ? `Slide ${index + 1} (currently selected)` : `Go to slide ${index + 1}`
              }
              className={cn(
                "h-2 w-10 rounded-full border border-light-2 shadow-button transition-colors",
                isSelected ? "bg-brand-d hover:cursor-auto" : "bg-white hover:bg-brand-b",
              )}
              key={index}
              onClick={() => {
                scrollTo(index);
              }}
              role="button"
            />
          );
        })}
      </div>
    </div>
  );
}
