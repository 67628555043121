import type { ReactNode } from "react";
import { PageBreadcrumbs } from "../page-breadcrumbs";
import { Container } from "../container";
import { Header } from "./header";
import { Footer } from "./footer";

type Props = {
  children: ReactNode;
  beforeBreadcrumbs?: ReactNode;
  hideBreadcrumbs?: boolean;
};

export function Layout({ hideBreadcrumbs = false, ...props }: Props) {
  return (
    <>
      <Container>
        <Header />
        {!!props.beforeBreadcrumbs && <div className="my-4 lg:my-6">{props.beforeBreadcrumbs}</div>}
        {!hideBreadcrumbs && (
          <div className="my-4 lg:my-6">
            <PageBreadcrumbs />
          </div>
        )}
      </Container>
      <main>{props.children}</main>
      <Footer />
    </>
  );
}
