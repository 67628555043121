export const SB_ROOT_SLUGS = {
  HOME: "home",
  GLOBAL: "global",
  PRODUCT_GROUPS: "warengruppen",
  SOLUTIONS: "loesungen",
  SERVICES: "dienstleistungen",
  PARTNERS: "partner",
  MARKETS: "maerkte-und-branchen",
  EVENTS: "events",
  NEWS: "aktuelles",
} as const;
