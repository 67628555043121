import dynamic from "next/dynamic";
import { VideoWrapper } from "../components/video-wrapper";
import type { SbBlockComponentProps } from "../types";
import { BlockTitle } from "./block-title";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  title?: string;
  url?: string;
}>;

export function YoutubeVideoBlock({ blok }: Props) {
  const ReactPlayer = dynamic(() => import("react-player/lazy"), {
    ssr: false,
  });

  return (
    <BlockWrapper blok={blok}>
      <BlockTitle title={blok.title} />
      <VideoWrapper>
        <ReactPlayer controls={true} height="100%" url={blok.url} volume={0.5} width="100%" />
      </VideoWrapper>
    </BlockWrapper>
  );
}
