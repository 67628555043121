import { storyblokEditable } from "@storyblok/react";
import { BlockSet } from "../block-set";
import type {
  PartnerCardBlockContent,
  SbAsset,
  SbBlockComponentProps,
  SbBlockContent,
  SbBlockSet,
} from "../../types";
import { Layout } from "../../components/layout";
import { Container } from "../../components/container";
import { SbAspectRatioImage } from "../../components/image";
import { MediaQuery } from "../../components/media-query";
import { SCREENS } from "../../config/theme";
import { LazyPartnerCard } from "../../components/partner-card/partner-card";
import { NativeVideo } from "../../components/native-video";

type Props = SbBlockComponentProps<{
  title?: string;
  image?: SbAsset;
  video?: SbAsset;
  videoPoster?: SbAsset;
  body?: SbBlockSet;
  footer?: SbBlockSet;
  partners?: SbBlockContent<PartnerCardBlockContent>[];
}>;

export function GenericCenteredPageBlock({ blok }: Props) {
  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-12 lg:mb-16">
        <Container>
          <div className="relative flex flex-col gap-4 lg:flex-row">
            <MediaQuery to="lg">
              <SbAspectRatioImage
                aspectRatio="16/10"
                className="rounded-md"
                cropWidth={SCREENS.lg}
                image={blok.image}
                priority={true}
                size="100vw"
              />
            </MediaQuery>
            <MediaQuery from="lg" to="xl">
              <SbAspectRatioImage
                aspectRatio="16/6"
                className="rounded-md"
                cropWidth={SCREENS["2xl"]}
                image={blok.image}
                priority={true}
                size={{ base: "100vw", "2xl": SCREENS["2xl"] }}
              />
            </MediaQuery>
            <MediaQuery from="xl">
              <SbAspectRatioImage
                aspectRatio="16/5"
                className="rounded-md"
                cropWidth={SCREENS["2xl"]}
                image={blok.image}
                priority={true}
                size={{ base: "100vw", "2xl": SCREENS["2xl"] }}
              />
            </MediaQuery>
            {!!blok.video && !!blok.video.filename && (
              <div className="lg:absolute lg:right-6 lg:top-6 lg:w-[480px] lg:shadow-floating xl:w-[500px] 2xl:right-10 2xl:top-10 2xl:w-[595px]">
                <NativeVideo poster={blok.videoPoster?.filename} src={blok.video.filename} />
              </div>
            )}
          </div>
        </Container>
        <Container maxWidth="4xl">
          <div className="mt-4 sm:mt-6 lg:mt-0 lg:-translate-y-10 lg:rounded-md lg:bg-white lg:px-8 lg:pt-8 xl:-translate-y-12 xl:px-12 xl:pt-12">
            <h1 className="text-22 font-semibold lg:text-32 xl:text-40">{blok.title}</h1>
            <div className="mt-4 lg:mt-6">
              <BlockSet blocks={blok.body} richTextScale={{ lg: "lg" }} />
            </div>
          </div>
        </Container>
        {!!blok.footer && blok.footer.length > 0 && (
          <Container>
            <div className="mt-8 lg:mt-0">
              <BlockSet blocks={blok.footer} />
            </div>
          </Container>
        )}

        {!!blok.partners && blok.partners.length > 0 && (
          <Container>
            <div className="mt-8 lg:mt-12">
              <h2 className="text-22 font-semibold lg:text-40">Partner</h2>
              <div className="mt-4 grid grid-cols-3 gap-2 sm:gap-4 lg:mt-6 lg:grid-cols-6 xl:gap-6">
                {blok.partners.map((partner) => (
                  <LazyPartnerCard
                    cropWidth={600}
                    key={partner.partner}
                    logoVariant={partner.logoVariant}
                    size={600}
                    uuid={partner.partner}
                  />
                ))}
              </div>
            </div>
          </Container>
        )}
      </div>
    </Layout>
  );
}
