import { VideoWrapper } from "./video-wrapper";

type Props = {
  src: string;
  autoPlay?: boolean;
  poster?: string | undefined;
};

export function NativeVideo({ autoPlay = false, ...props }: Props) {
  return (
    <VideoWrapper>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption -- TODO */}
      <video
        autoPlay={autoPlay}
        className="w-full object-cover"
        controls={true}
        poster={props.poster}
        preload="metadata"
      >
        <source src={props.src} type="video/mp4" />
      </video>
    </VideoWrapper>
  );
}
