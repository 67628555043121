import type { SbBlockComponentProps } from "../types";
import { MediaQuery } from "../components/media-query";
import { parseSbNumberFieldValue } from "../utils/misc";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  heightBase?: string;
  heightTablet?: string;
  heightDesktop?: string;
}>;

type HeightBreakpoint = "base" | "tablet" | "desktop";

type HeightConfig = {
  breakpoint: HeightBreakpoint;
  height: number | undefined;
};

type ValidHeightConfig = {
  breakpoint: HeightBreakpoint;
  height: number;
};

const DEFAULT_HEIGHT = 100;

export function SpacerBlock({ blok }: Props) {
  /**
   * These should be defined in ascending order of breakpoint because the getHeightForBreakpoint function
   * will return the height for the last breakpoint it can find.
   */
  const heights: HeightConfig[] = [
    { breakpoint: "base", height: parseSbNumberFieldValue(blok.heightBase) ?? DEFAULT_HEIGHT },
    { breakpoint: "tablet", height: parseSbNumberFieldValue(blok.heightTablet) },
    { breakpoint: "desktop", height: parseSbNumberFieldValue(blok.heightDesktop) },
  ];

  const validHeights = heights.filter(hasValidHeight);

  return (
    <BlockWrapper blok={blok} className="w-full">
      {/* If there are no responsive heights, it would be wasteful to use three different MediaQuery components. */}
      {validHeights.length === 1 && (
        <Spacer height={getHeightForBreakpoint(validHeights, "base")} />
      )}

      {validHeights.length > 1 && (
        <>
          <MediaQuery to="md">
            <Spacer height={getHeightForBreakpoint(validHeights, "base")} />
          </MediaQuery>
          <MediaQuery from="md" to="xl">
            <Spacer height={getHeightForBreakpoint(validHeights, "tablet")} />
          </MediaQuery>
          <MediaQuery from="xl">
            <Spacer height={getHeightForBreakpoint(validHeights, "desktop")} />
          </MediaQuery>
        </>
      )}
    </BlockWrapper>
  );
}

function Spacer({ height }: { height: number }) {
  return <div style={{ height: `${height}px` }} />;
}

/**
 * Returns the height for the given breakpoint.
 * If there is no height for the given breakpoint, it will return the height for the last breakpoint it can find.
 */
function getHeightForBreakpoint(heightConfigs: ValidHeightConfig[], breakpoint: HeightBreakpoint) {
  if (heightConfigs.length === 0) {
    return DEFAULT_HEIGHT;
  }

  const config = heightConfigs.find((heightConfig) => heightConfig.breakpoint === breakpoint);

  if (!config) {
    return heightConfigs[heightConfigs.length - 1].height;
  }

  return config.height;
}

function hasValidHeight(height: HeightConfig): height is ValidHeightConfig {
  return typeof height.height === "number";
}
