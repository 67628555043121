import type { ISbStoriesParams, ISbStoryParams } from "@storyblok/react";
import { isPubliclyInaccessibleSbFullSlug, SB_ROOT_SLUGS } from "@pts/storyblok";
import { getStoryblokApi } from "../lib/storyblok";
import type {
  ConfigBlockContent,
  GetStoriesResponse,
  GetStoryResponse,
  SbStoryLink,
  SbStoryProps,
} from "../types";
import { isPreviewMode } from "../utils/preview-mode";
import type { MarketPageBlockContent } from "../blocks/pages/market-page-block";
import type { PartnerBlockContent } from "../blocks/pages/partner-page-block";

const getCommonStoryParams = () => {
  const params: Pick<ISbStoriesParams, "version" | "cv"> & { resolve_links: "url" } = {
    version: "published",
    resolve_links: "url",
  };

  if (isPreviewMode()) {
    params.version = "draft";
    params.cv = Date.now();
  }

  return params;
};

export const getStories = async <TContent>(
  params: ISbStoriesParams,
): Promise<GetStoriesResponse<TContent>> => {
  const storyblokApi = getStoryblokApi();

  const sbParams: ISbStoriesParams = {
    ...getCommonStoryParams(),
    ...params,
  };

  return storyblokApi.get(`cdn/stories`, sbParams);
};

/**
 * Only returns stories that should be visible to the public,
 * i.e. no config content or temporarily hidden content etc.
 */
export async function getAllPublicStories(): Promise<SbStoryProps[]> {
  const storyblokApi = getStoryblokApi();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO
  const stories: SbStoryProps[][] = await storyblokApi.getAll("cdn/stories", {
    version: "published",
  });

  return stories.flat().filter((story) => !isPubliclyInaccessibleSbFullSlug(story.full_slug));
}

export const getStoryBySlugOrId = async <TContent>(
  slugOrId: string | number, // id is the numeric "id" field, as opposed to the "uuid" string field
  params?: Omit<ISbStoryParams, "find_by">,
): Promise<GetStoryResponse<TContent>> => {
  const storyblokApi = getStoryblokApi();

  const sbParams: ISbStoryParams = {
    ...getCommonStoryParams(),
    ...(params ?? {}),
  };

  return storyblokApi.get(`cdn/stories/${slugOrId}`, sbParams);
};

export const getStoryByUuid = async <TContent>(
  uuid: string,
  params?: Omit<ISbStoryParams, "find_by">,
): Promise<GetStoryResponse<TContent>> => {
  const storyblokApi = getStoryblokApi();

  const sbParams: ISbStoryParams = {
    ...getCommonStoryParams(),
    ...(params ?? {}),
    find_by: "uuid",
  };

  return storyblokApi.get(`cdn/stories/${uuid}`, sbParams);
};

/**
 * Retrieves stories based on an array of UUIDs,
 * then sorts them in the same order that the UUID array has
 * (Storyblok API ignores the order).
 */
export const getStoriesByUuids = async <TContent>(uuids: string[]) => {
  const sortByInputIdOrder = (stories: SbStoryProps<TContent>[], inputIds: string[]) => {
    const result: SbStoryProps<TContent>[] = [];

    inputIds.forEach((id) => {
      const story = stories.find((s) => s.uuid === id);
      if (!story) {
        return;
      }
      result.push(story);
    });

    return result;
  };

  const data = await getStories<TContent>({ by_uuids: uuids.join(",") });

  const result: GetStoriesResponse<TContent> = {
    ...data,
    data: {
      ...data.data,
      stories: sortByInputIdOrder(data.data.stories, uuids),
    },
  };

  return result.data;
};

export const getOtherStoriesInCurrentFolder = async <TContent>(params: {
  storyFullSlug: string;
  storyId: number;
  contentType: string;
}) => {
  const parentFolderSlug = params.storyFullSlug.split("/").filter(Boolean).slice(0, -1).join("/");

  const data = await getStories<TContent>({
    content_type: params.contentType,
    excluding_ids: String(params.storyId),
    starts_with: `${parentFolderSlug}/`,
  });

  return data.data;
};

export const getAllLinks = async (): Promise<{
  data: {
    links: Record<string, SbStoryLink>;
  };
}> => {
  const storyblokApi = getStoryblokApi();

  return storyblokApi.get("cdn/links/", { version: "published" });
};

export const getConfig = async () => {
  return getStoryBySlugOrId<ConfigBlockContent>(`${SB_ROOT_SLUGS.GLOBAL}/config`);
};

export const getMarkets = async () => {
  const data = await getStories<MarketPageBlockContent>({
    content_type: "marketPage",
    sort_by: "position:asc",
  });

  return data.data.stories;
};

export const getPartners = async () => {
  const data = await getStories<PartnerBlockContent>({
    content_type: "partner",
    sort_by: "position:asc",
  });

  return data.data.stories;
};
