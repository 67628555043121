import { createContext, useContext } from "react";
import type { UseEmblaCarouselType } from "embla-carousel-react";

type CarouselContextState = {
  emblaMainRef: UseEmblaCarouselType[0];
  emblaMainApi: UseEmblaCarouselType[1];
  selectedIndex: number;
  slidesInView: number[];
};

export const CarouselContext = createContext<CarouselContextState | null>(null);

export const useCarouselContext = () => {
  const ctx = useContext(CarouselContext);
  if (!ctx) {
    throw new Error("Missing CarouselContext.");
  }

  return ctx;
};
