import { create } from "zustand";
import { useRouter } from "next/router";
import { useEffect } from "react";

type RouterHistoryStoreState = {
  currentPath: string | null;
  previousPath: string | null;
};

export const useRouterHistoryStore = create<RouterHistoryStoreState>(() => ({
  currentPath: null,
  previousPath: null,
}));

export function RouterHistoryStoreSynchronizer() {
  const { asPath } = useRouter();
  const { previousPath, currentPath } = useRouterHistoryStore();

  useEffect(() => {
    if (currentPath === asPath) {
      return;
    }

    useRouterHistoryStore.setState((state) => ({
      ...state,
      previousPath: currentPath,
      currentPath: asPath,
    }));
  }, [asPath, currentPath, previousPath]);

  return null;
}
