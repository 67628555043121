import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { SbStoryProps } from "../types";

type SbStoryContextState = Omit<SbStoryProps, "content">;

const SbStoryContext = createContext<SbStoryContextState | null>(null);

/**
 * Exposes current story / top-level block metadata (slug, id, etc.).
 */
export function SbStoryContextProvider(props: { children: ReactNode; story: SbStoryProps }) {
  const { content: _content, ...storyMeta } = props.story;

  return <SbStoryContext.Provider value={storyMeta}>{props.children}</SbStoryContext.Provider>;
}

export const useCurrentStory = () => {
  const ctx = useContext(SbStoryContext);

  if (!ctx) {
    throw new Error("Missing SbStoryContextProvider");
  }

  return ctx;
};
