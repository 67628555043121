import { SbWebhookPayload } from "./types";
import { SB_ROOT_SLUGS } from "./constants";

export function isPubliclyInaccessibleSbFullSlug(fullSlug: string) {
  // We show the events list page as an exception.
  if (fullSlug === SB_ROOT_SLUGS.EVENTS || fullSlug === `${SB_ROOT_SLUGS.EVENTS}/`) {
    return false;
  }

  return [SB_ROOT_SLUGS.GLOBAL, SB_ROOT_SLUGS.MARKETS, SB_ROOT_SLUGS.EVENTS].some(
    (slug) => fullSlug === slug || fullSlug.startsWith(`${slug}/`),
  );
}

export function isSbStoryEventWebhookPayload(payload: unknown): payload is SbWebhookPayload {
  return (
    !!payload &&
    typeof payload === "object" &&
    "action" in payload &&
    typeof payload.action === "string" &&
    "story_id" in payload &&
    typeof payload.story_id === "number"
  );
}
