import Link from "next/link";
import { FiChevronRight } from "react-icons/fi";
import type { EnhancedNavItemType } from "../use-enhanced-nav-items";
import { cn, getSbLinkUrl } from "../../../utils/misc";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../accordion";

type Props = {
  navItem: EnhancedNavItemType;
  setActiveSubmenuId: (id: string) => void;
};

const baseTriggerClasses = "md:hover:text-foreground-t1.5 p-5 text-18 font-medium";

export function MobileNavItem({ navItem, setActiveSubmenuId }: Props) {
  return (
    <li className="border-t border-light-2 first:border-t-0">
      {navItem.component === "navigationLink" && (
        <Link className={cn(baseTriggerClasses, "block")} href={getSbLinkUrl(navItem.link) ?? ""}>
          {navItem.title}
        </Link>
      )}
      {navItem.component === "navigationMenu" && (
        <button
          className={cn(baseTriggerClasses, "flex w-full items-center justify-between gap-4")}
          onClick={() => {
            setActiveSubmenuId(navItem._uid);
          }}
          type="button"
        >
          <span>{navItem.title}</span>
          <FiChevronRight className="text-24" />
        </button>
      )}
      {navItem.component === "navigationNestedMenu" && (
        <Accordion collapsible={true} type="single">
          <AccordionItem
            className="border-b-0 transition-all data-[state=open]:bg-light-1"
            value="1"
          >
            <AccordionTrigger
              className={cn(baseTriggerClasses, "transition-none hover:no-underline")}
              iconClassName="h-6 w-6"
            >
              {navItem.title}
            </AccordionTrigger>
            <AccordionContent>
              <div className="border-t border-dashed border-light-3 py-2.5">
                {(navItem.sections ?? []).map((section) => (
                  <button
                    className="group flex w-full items-center justify-between gap-4 px-5 py-2.5 text-left"
                    key={section._uid}
                    onClick={() => {
                      setActiveSubmenuId(section._uid);
                    }}
                    type="button"
                  >
                    <div>
                      <div className="font-medium md:group-hover:text-foreground-t1.5">
                        {section.title}
                      </div>
                      <div className="text-15 text-light-8 md:group-hover:opacity-75">
                        {section.subtitle}
                      </div>
                    </div>
                    <FiChevronRight className="shrink-0 text-24" />
                  </button>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </li>
  );
}
