import { storyblokEditable } from "@storyblok/react";
import type { SbBlockComponentProps, SbBlockSet } from "../../types";
import { Container } from "../../components/container";
import { BlockSet } from "../block-set";
import { Layout } from "../../components/layout";

type Props = SbBlockComponentProps<{
  body?: SbBlockSet;
}>;

export default function LandingPageBlock({ blok }: Props) {
  return (
    <Layout hideBreadcrumbs={true}>
      <div {...storyblokEditable(blok)} className="mb-12 mt-6 lg:mb-16">
        <Container>
          <BlockSet blocks={blok.body} />
        </Container>
      </div>
    </Layout>
  );
}
