import type { ReactNode } from "react";
import type { SbBlockComponentProps, SbBlockContent } from "../types";
import { cn } from "../utils/misc";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type TableBlockContent = {
  table?: {
    thead?: SbBlockContent<{ value: string }>[];
    tbody?: SbBlockContent<{
      body: SbBlockContent<{ value: string }>[];
    }>[];
  };
  title?: string;
  layout?: "vertical" | "horizontal";
};

type Props = SbBlockComponentProps<TableBlockContent>;

const tableCellClasses =
  "table-cell border-b border-light-3 border-r last:border-r-0 text-14 lg:text-15 p-2 lg:p-4 text-left whitespace-pre-wrap";

const tableHeadClasses = (blok: TableBlockContent) =>
  cn(tableCellClasses, "font-semibold", !blok.title && "bg-light-1");

function TableWrapper(props: { children: ReactNode }) {
  return (
    <div className="overflow-x-auto">
      <table className="table w-full table-auto border-collapse border border-light-3">
        {props.children}
      </table>
    </div>
  );
}

function TableCaption(props: { caption: string }) {
  return (
    <caption className="border border-b-0 border-light-3 bg-light-1 p-4 text-left text-18 font-semibold lg:text-24">
      {props.caption}
    </caption>
  );
}

function HorizontalTable(props: TableBlockContent) {
  const headRows = props.table?.thead ?? [];
  const bodyRows = props.table?.tbody ?? [];
  const numRows = Math.max(bodyRows.length, headRows.length);
  const rowIndices = Array(numRows)
    .fill(0)
    .map((x, i) => i);

  return (
    <TableWrapper>
      {!!props.title && <TableCaption caption={props.title} />}

      <tbody className="table-row-group">
        {rowIndices.map((i) => (
          <tr className="table-row" key={i}>
            <th className={tableHeadClasses(props)}>{headRows[i]?.value ?? ""}</th>
            {bodyRows.map((x) => (
              <td className={tableCellClasses} key={x._uid}>
                {x.body[i]?.value ?? ""}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableWrapper>
  );
}

function VerticalTable(props: TableBlockContent) {
  return (
    <TableWrapper>
      {!!props.title && <TableCaption caption={props.title} />}
      {!!props.table?.thead && (
        <thead className="table-header-group">
          <tr className="table-row">
            {props.table.thead.map((cell) => (
              <th className={tableHeadClasses(props)} key={cell._uid}>
                {cell.value}
              </th>
            ))}
          </tr>
        </thead>
      )}
      {!!props.table?.tbody && (
        <tbody className="table-row-group">
          {props.table.tbody.map((row) => (
            <tr className="table-row" key={row._uid}>
              {row.body.map((cell) => (
                <td className={tableCellClasses} key={cell._uid}>
                  {cell.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      )}
    </TableWrapper>
  );
}

export function TableBlock({ blok }: Props) {
  return (
    <BlockWrapper blok={blok}>
      {blok.layout === "horizontal" ? <HorizontalTable {...blok} /> : <VerticalTable {...blok} />}
    </BlockWrapper>
  );
}
