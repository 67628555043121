import type { ReactNode } from "react";
import { cn } from "../../utils/misc";

export function TextInputAdornment(props: { children: ReactNode; onClick?: () => void }) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions -- TODO
    <div
      className={cn(
        "absolute bottom-px right-px top-px flex w-10 cursor-pointer items-center justify-center rounded-br-md rounded-tr-md border-l border-foreground-t4 transition-all",
        "hover:bg-light-1",
        "peer-focus:bg-light-t2 peer-focus:hover:bg-light-1",
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
