import { useQuery } from "@tanstack/react-query";
import { storyblokEditable } from "@storyblok/react";
import { Container } from "../../components/container";
import { QUERY_KEY, SYSTEM_LABELS } from "../../config/constants";
import { useCurrentStory } from "../../context/sb-story-context";
import type { SbBlockComponentProps, SbAsset, System } from "../../types";
import { Layout } from "../../components/layout";
import { OverviewCardList } from "../../components/overview-card-list";
import { SystemSolutionParentPageHeader } from "../../components/system-solution-parent-page-header";
import { getSystemColorScheme } from "../../config/solution-sector-color-scheme";
import { getOtherStoriesInCurrentFolder, getStoriesByUuids } from "../../api/storyblok";
import { cn } from "../../utils/misc";
import { RelatedStoriesGrid } from "../../components/related-stories-grid";
import type { SolutionPageBlockContent } from "./solution-page-block";

export type SolutionFamilyPageBlockContent = {
  image?: SbAsset;
  video?: SbAsset;
  videoPoster?: SbAsset;
  title?: string;
  description?: string;
  pages?: string[];
  system?: System;
};

type Props = SbBlockComponentProps<SolutionFamilyPageBlockContent>;

export function SolutionFamilyPageBlock({ blok }: Props) {
  const { id, full_slug: storyFullSlug } = useCurrentStory();
  const pageIds = blok.pages ?? [];

  const alternateLinksQuery = useQuery({
    queryKey: [QUERY_KEY.SYSTEM_SOLUTION_SUBCATEGORY_ALTERNATE_LINKS, id],
    queryFn: () =>
      getOtherStoriesInCurrentFolder<SolutionFamilyPageBlockContent>({
        storyFullSlug,
        storyId: id,
        contentType: "systemSolutionSubcategory",
      }),
  });
  const pageListQuery = useQuery({
    queryKey: [QUERY_KEY.STORIES_BY_UUIDS, pageIds],
    queryFn: () => getStoriesByUuids<SolutionPageBlockContent>(pageIds),
  });

  const colorScheme = getSystemColorScheme(blok.system);
  const systemLabel = blok.system ? SYSTEM_LABELS[blok.system] : null;

  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-12 lg:mb-16">
        <Container>
          <SystemSolutionParentPageHeader
            aboveTitle={
              systemLabel ? (
                <div className="mb-4 flex items-center gap-1.5">
                  <div className={cn("h-2.5 w-2.5 rounded-3px", colorScheme.bgClass)} />
                  <h3 className={cn("text-10 font-medium sm:text-14", colorScheme.textClass)}>
                    {systemLabel}
                  </h3>
                </div>
              ) : null
            }
            description={blok.description}
            image={blok.image}
            title={blok.title}
            video={blok.video}
            videoPoster={blok.videoPoster}
          />

          <OverviewCardList
            imageVariant="cover"
            isError={pageListQuery.isError}
            isPending={pageListQuery.isPending}
            items={
              pageListQuery.isSuccess
                ? pageListQuery.data.stories.map((story) => ({
                    id: story.uuid,
                    href: story.full_slug,
                    image: story.content.image,
                    title: story.content.title,
                    colorClass: colorScheme.bgClass,
                    description: story.content.description,
                  }))
                : undefined
            }
          />

          <div className="mt-8 border-t border-light-3 pt-8">
            <RelatedStoriesGrid
              isError={alternateLinksQuery.isError}
              isPending={alternateLinksQuery.isPending}
              stories={alternateLinksQuery.data?.stories}
            />
          </div>
        </Container>
      </div>
    </Layout>
  );
}
