import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { RichTextContentProps } from "../components/rich-text-content";

type SbBlockSetContextState = {
  richTextScale: RichTextContentProps["scale"];
};

const SbBlockSetContext = createContext<SbBlockSetContextState | null>(null);

/**
 * Exposes the Storyblok "config" story content (navigation items etc.)
 */
export function SbBlockSetContextProvider(props: {
  value?: Partial<SbBlockSetContextState>;
  children: ReactNode;
}) {
  return (
    <SbBlockSetContext.Provider
      value={{
        richTextScale: undefined,
        ...(props.value ?? {}),
      }}
    >
      {props.children}
    </SbBlockSetContext.Provider>
  );
}

export const useSbBlockSetContext = () => {
  const ctx = useContext(SbBlockSetContext);

  if (!ctx) {
    throw new Error("Missing SbBlockSetContextProvider");
  }

  return ctx;
};
