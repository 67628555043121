import "dayjs/locale/de";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";

/**
 * Instead of setting the dayjs locale globally, we are calling locale(LOCALE_GERMAN)
 * in every dayjs instance which outputs a formatted string. Makes sense in case more
 * locales are added to the site later on.
 */

type DateParam = string | number | Dayjs | Date;

const LOCALE_GERMAN = "de";

export const humanFriendlyDateFormat = (date: DateParam) => {
  return dayjs(date).locale(LOCALE_GERMAN).format("D. MMMM YYYY");
};
