import * as RadixSwitch from "@radix-ui/react-switch";

type Props = {
  isChecked: boolean;
  onCheckedChange: (isChecked: boolean) => void;
};

export function Switch({ isChecked, onCheckedChange }: Props) {
  return (
    <RadixSwitch.Root
      checked={isChecked}
      className="relative h-[24px] w-[44px] cursor-pointer rounded-full bg-light-3 outline-none data-[state=checked]:bg-brand-c"
      onCheckedChange={onCheckedChange}
      style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
    >
      <RadixSwitch.Thumb className="block h-[20px] w-[20px] translate-x-0.5 rounded-full bg-white transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[22px]" />
    </RadixSwitch.Root>
  );
}
