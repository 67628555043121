import { HiOutlineMenu } from "react-icons/hi";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { IconButton } from "../../button";
import { useConfig } from "../../../hooks/use-config";
import { SkeletonLoader } from "../../skeleton-loader";
import type { ConfigBlockContent } from "../../../types";
import type { EnhancedNavItemType } from "../use-enhanced-nav-items";
import { useEnhancedNavItems } from "../use-enhanced-nav-items";
import { cn } from "../../../utils/misc";
import { MobileNavItem } from "./_mobile-nav-item";
import type { MobileSubmenuProps } from "./_mobile-nav-submenu";
import { MobileNavSubmenu } from "./_mobile-nav-submenu";
import { MobileNavHeader } from "./_mobile-nav-header";

type Props = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export function MobileNav({ isOpen, onOpenChange }: Props) {
  const { data, isPending, isSuccess } = useConfig();
  const navRef = useRef<HTMLDivElement | null>(null);

  const close = useCallback(() => {
    onOpenChange(false);
  }, [onOpenChange]);

  useOnClickOutside(navRef, close);

  return (
    <>
      <IconButton
        ariaLabel="Menu"
        className="p-1.5 md:hidden"
        icon={<HiOutlineMenu className="text-28" />}
        onClick={() => {
          onOpenChange(true);
        }}
        size="small"
        variant="subtle"
      />
      <div
        className={cn(
          "pointer-events-none absolute left-0 top-0 z-30 flex w-full justify-end overflow-hidden md:left-auto md:right-0 md:max-w-lg",
          isOpen ? "translate-y-0" : "-translate-y-full",
        )}
        ref={navRef}
        style={{
          transition: "ease-out 250ms",
        }}
      >
        <div
          className={cn(
            "z-[1] w-full origin-[50%_0] p-4 pb-12",
            isOpen
              ? "pointer-events-auto visible translate-y-0 opacity-100"
              : "pointer-events-none invisible translate-y-full opacity-0",
          )}
          style={{
            transition: isOpen
              ? "visibility step-start 250ms, transform ease-out 250ms, opacity ease-out 250ms"
              : "visibility step-end 250ms, transform ease-out 250ms, opacity ease-out 250ms",
          }}
        >
          <div className="overflow-hidden rounded-md border border-light-2 bg-white shadow-floating">
            {!!isPending && (
              <div className="flex flex-col gap-4 p-5">
                {[1, 2, 3, 4, 5].map((i) => (
                  <div className="h-12" key={i}>
                    <SkeletonLoader className="h-full" />
                  </div>
                ))}
              </div>
            )}

            {!!isSuccess && <MobileNavContent config={data} isOpen={isOpen} onClose={close} />}
          </div>
        </div>
      </div>
    </>
  );
}

function MobileNavContent({
  config,
  onClose,
  isOpen,
}: {
  config: ConfigBlockContent;
  onClose: () => void;
  isOpen: boolean;
}) {
  const { primaryItems, secondaryItems, allItems } = useEnhancedNavItems({ config });
  const [activeSubmenuId, setActiveSubmenuId] = useState<string | null>(null);
  const submenus = useMemo(() => extractSubmenus(allItems), [allItems]);
  const submenuHeights = useRef<number[]>([]);
  const [tallestSubmenuHeight, setTallestSubmenuHeight] = useState<number | null>(null);

  useEffect(() => {
    if (isOpen) {
      return;
    }

    const resetTimeout = setTimeout(() => {
      setActiveSubmenuId(null);
    }, 600);

    return () => {
      clearTimeout(resetTimeout);
    };
  }, [isOpen]);

  const makeItem = useCallback(
    (navItem: EnhancedNavItemType) => (
      <MobileNavItem key={navItem._uid} navItem={navItem} setActiveSubmenuId={setActiveSubmenuId} />
    ),
    [],
  );

  return (
    <>
      <MobileNavHeader
        activeSubmenuId={activeSubmenuId}
        onClose={onClose}
        setActiveSubmenuId={setActiveSubmenuId}
      />
      <div
        className={cn(
          "relative flex w-[200%] transition-transform duration-300 ease-out",
          activeSubmenuId ? "-translate-x-1/4" : "translate-x-0",
        )}
        style={{
          /**
           * Since submenus are rendered using position: absolute, we need to set a min-height here to prevent overflow.
           */
          minHeight: `${tallestSubmenuHeight ?? 480}px`,
        }}
      >
        <nav className="flex w-full flex-col justify-between gap-16 bg-white pb-1 pt-2.5">
          <ul>{primaryItems.map((navItem) => makeItem(navItem))}</ul>
          <ul className="mx-1 rounded-md bg-light-t1">
            {secondaryItems.map((navItem) => makeItem(navItem))}
          </ul>
        </nav>
        <div
          className={cn(
            "relative w-full border-t border-dashed border-light-3 bg-white shadow-elevated transition-transform duration-500 ease-out",
            activeSubmenuId ? "-translate-x-1/2" : "invisible translate-x-0",
          )}
        >
          {submenus.map((submenu, index, arr) => (
            <MobileNavSubmenu
              activeSubmenuId={activeSubmenuId}
              key={submenu._uid}
              refCallback={(element) => {
                if (!element) {
                  return;
                }

                submenuHeights.current[index] = element.offsetHeight;

                // Only set this once all submenus have been measured.
                if (index === arr.length - 1) {
                  const tallestHeight = Math.max(...submenuHeights.current);
                  if (tallestHeight !== tallestSubmenuHeight) {
                    setTallestSubmenuHeight(tallestHeight);
                  }
                }
              }}
              submenu={submenu}
            />
          ))}
        </div>
      </div>
    </>
  );
}

function extractSubmenus(navItems: EnhancedNavItemType[]) {
  const result: MobileSubmenuProps[] = [];

  navItems.forEach((item) => {
    if (item.component === "navigationNestedMenu" && item.sections) {
      item.sections.forEach((section) => {
        result.push({
          title: section.title,
          links: section.content?.[0].links ?? [],
          _uid: section._uid,
        });
      });
    }

    if (item.component === "navigationMenu" && item.content) {
      result.push({
        title: item.title,
        links: item.content[0].links ?? [],
        _uid: item._uid,
      });
    }
  });

  return result;
}
