import { storyblokEditable } from "@storyblok/react";
import { Layout } from "../../components/layout";
import { Container } from "../../components/container";
import { OverviewCardList } from "../../components/overview-card-list";
import { getSystemColorScheme } from "../../config/solution-sector-color-scheme";
import type { SbBlockComponentProps, SbStoryProps } from "../../types";
import type { SolutionSectorPageBlockContent } from "./solution-sector-page-block";

type Props = SbBlockComponentProps<{
  title?: string;
  systems?: SbStoryProps<SolutionSectorPageBlockContent>[];
}>;

export function SolutionSectorsListPageBlock({ blok }: Props) {
  const systems = blok.systems ?? [];

  const listItems = systems.map((system) => {
    const colorScheme = getSystemColorScheme(system.content.system);

    return {
      id: system.uuid,
      href: system.full_slug,
      title: system.name,
      colorClass: colorScheme.bgClass,
      image: system.content.image,
    };
  });

  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-16">
        <Container>
          <div className="border-t border-light-3 py-6 lg:mb-6 lg:border-b">
            <h1 className="text-18 font-semibold lg:text-32">{blok.title}</h1>
          </div>

          <OverviewCardList imageVariant="cover" items={listItems} />
        </Container>
      </div>
    </Layout>
  );
}
