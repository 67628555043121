import type { ReactNode } from "react";
import { useCallback, useRef, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useElementResizeObserver } from "../hooks/use-element-resize-observer";
import { DEBOUNCE_DURATION } from "../config/constants";
import { cn } from "../utils/misc";

type Props = {
  className?: string;
  children: ReactNode;
};

export function ExpandableText({ className, children }: Props) {
  const contentRef = useRef(null);
  const [readMoreButtonStatus, setReadMoreButtonStatus] = useState<
    "hidden" | "read-more" | "read-less"
  >("hidden");
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const onResize = useCallback(
    (entry: ResizeObserverEntry) => {
      const hasOverflow = entry.target.clientHeight < entry.target.scrollHeight;

      // If we're not showing the read more button yet and the content overflows, show the button.
      if (hasOverflow && readMoreButtonStatus === "hidden") {
        setReadMoreButtonStatus("read-more");
      }

      // If we're showing the button but the content is no longer overflowing (e.g. on window resize), hide the button.
      if (!hasOverflow && readMoreButtonStatus === "read-more") {
        setReadMoreButtonStatus("hidden");
      }
    },
    [readMoreButtonStatus],
  );

  useElementResizeObserver(contentRef, onResize, DEBOUNCE_DURATION);

  return (
    <div className={cn(className)}>
      <div
        className={cn(!isDescriptionExpanded && "line-clamp-6 md:line-clamp-4")}
        ref={contentRef}
      >
        {children}
      </div>
      {readMoreButtonStatus !== "hidden" && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events -- TODO
        <div
          className="mt-1 inline-flex items-center text-brand-d"
          onClick={() => {
            setReadMoreButtonStatus(
              readMoreButtonStatus === "read-more" ? "read-less" : "read-more",
            );
            setIsDescriptionExpanded(!isDescriptionExpanded);
          }}
          role="button"
          tabIndex={0}
        >
          <span>{isDescriptionExpanded ? "Weniger" : "Mehr"} anzeigen</span>
          <FiChevronDown
            className={cn("ml-0.5 mt-0.5 transition-all", isDescriptionExpanded && "rotate-180")}
            size={21}
          />
        </div>
      )}
    </div>
  );
}
