import Image from "next/image";
import Link from "next/link";
import type { ISbRichtext } from "@storyblok/react";
import { render } from "storyblok-rich-text-react-renderer";
import logo from "../../assets/pts-logo-light.png";
import bgImage from "../../assets/pts-footer-bg.webp";
import { Container } from "../container";
import type { FooterMenuGroupBlockContent, SbBlockComponentProps, SbLink } from "../../types";
import { MediaQuery } from "../media-query";
import { getSbLinkUrl, getSocialLinks } from "../../utils/misc";
import { useConfig } from "../../hooks/use-config";
import { SkeletonLoader } from "../skeleton-loader";

export function Footer() {
  const { data, isPending, isSuccess } = useConfig();

  return (
    <footer className="relative bg-foreground pb-10 pt-8 lg:bg-opacity-70 lg:pb-14 lg:pt-12">
      <MediaQuery from="lg">
        <Image alt="" className="-z-10 object-cover" fill={true} priority={true} src={bgImage} />
      </MediaQuery>

      <Container>
        <div className="max-w-[68px] lg:max-w-[113px]">
          <Image alt="PTS-Group" height={40} src={logo} width={113} />
        </div>
        <div className="mt-12 grid grid-cols-1 items-start gap-y-8 lg:mt-20 lg:grid-cols-3 lg:gap-y-12 xl:grid-cols-6">
          {!!isPending &&
            [1, 2, 3, 4, 5, 6].map((groupIndex) => (
              <div className="flex flex-col gap-3 lg:pr-8" key={groupIndex}>
                {[1, 2, 3, 4, 5].map((childIndex) => (
                  <SkeletonLoader className="h-6" key={childIndex} />
                ))}
              </div>
            ))}

          {!!isSuccess &&
            !!data.footerMenu &&
            data.footerMenu.map((group) => <FooterMenuGroup blok={group} key={group._uid} />)}
        </div>

        <div className="mt-8 lg:mt-20">
          <div className="text-14 font-semibold text-white lg:text-16">
            PTS in den sozialen Medien
          </div>
          <div className="mt-4 flex items-center gap-x-8 lg:gap-x-10">
            {!!isPending && (
              <div className="h-8 w-24">
                <SkeletonLoader className="h-full" />
              </div>
            )}

            {!!isSuccess &&
              getSocialLinks(data).map((link) => (
                <Link aria-label={link.label} href={link.url} key={link._uid} target="_blank">
                  <link.icon className="text-white" size={26} />
                </Link>
              ))}
          </div>
        </div>
      </Container>
    </footer>
  );
}

function FooterMenuGroup({ blok }: SbBlockComponentProps<FooterMenuGroupBlockContent>) {
  return (
    <div className="lg:border-l lg:border-white-t2 lg:pl-5 lg:pr-8 xl:first:border-l-0 xl:first:px-0">
      {!!blok.name && (
        <div className="text-14 font-semibold text-white lg:text-16">{blok.name}</div>
      )}
      {!!blok.items && (
        <ul className="mt-3 flex flex-col gap-2.5">
          {blok.items.map((item) => (
            <li className="text-14 text-white opacity-50 lg:text-16" key={item._uid}>
              {item.component === "menuLink" && <MenuLink link={item.link} name={item.name} />}
              {item.component === "menuText" && <MenuText content={item.content} />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

function MenuLink({ name, link, onClick }: { name?: string; link?: SbLink; onClick?: () => void }) {
  if (!link) {
    return null;
  }

  return (
    <Link
      className="block transition-all hover:underline hover:opacity-100"
      href={getSbLinkUrl(link)}
      onClick={(event) => {
        onClick?.();

        if (!(event.target instanceof HTMLElement)) {
          return;
        }

        /**
         * Ensures the element is no longer focused after clicked,
         * otherwise it could persist certain styling relating to focus state.
         */
        event.target.blur();
      }}
    >
      {name}
    </Link>
  );
}

function MenuText({ content }: { content: ISbRichtext | undefined }) {
  if (!content) {
    return null;
  }

  return (
    <div>
      <div className="flex flex-col gap-1">{render(content)}</div>
    </div>
  );
}
