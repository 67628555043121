import { storyblokEditable } from "@storyblok/react";
import { useQuery } from "@tanstack/react-query";
import type { SbAsset, SbBlockComponentProps } from "../types";
import { RelatedStoriesGrid } from "../components/related-stories-grid";
import { QUERY_KEY } from "../config/constants";
import { getStoriesByUuids } from "../api/storyblok";

type Props = SbBlockComponentProps<{
  title?: string;
  /**
   * List of UUIDs.
   */
  stories?: string[];
}>;

export function RelatedStoriesBlock({ blok }: Props) {
  const { data, isError, isPending } = useQuery({
    queryKey: [QUERY_KEY.STORIES_BY_UUIDS, blok.stories],
    queryFn: () => getStoriesByUuids<{ image?: SbAsset }>(blok.stories ?? []),
    enabled: blok.stories && blok.stories.length > 0,
  });

  return (
    <div {...storyblokEditable(blok)}>
      <RelatedStoriesGrid
        isError={isError}
        isPending={isPending}
        stories={data?.stories}
        title={blok.title}
      />
    </div>
  );
}
