import type { ImageProps } from "next/image";
import NextImage from "next/image";
import { generateStoryblokImageSrc } from "../../utils/sb-image";
import { cn } from "../../utils/misc";
import type { ImageSize, ResponsiveImageSizeConfig, StoryblokImageProps } from "./types";
import { generateImageSizes } from "./utils";

type BaseProps = {
  width?: ImageProps["width"];
  height?: ImageProps["height"];
  className?: string;
  priority?: ImageProps["priority"];
  onLoad?: ImageProps["onLoad"];
};

type PropsWithoutFill = BaseProps &
  StoryblokImageProps & {
    fill?: undefined;
    size?: undefined;
  };

/**
 * Using fill with Next.js Image should be used together with a definition of sizes.
 */
type PropsWithFill = BaseProps &
  StoryblokImageProps & {
    fill: ImageProps["fill"];
    size: ResponsiveImageSizeConfig | ImageSize;
  };

type Props = PropsWithFill | PropsWithoutFill;

export function SbImage(props: Props) {
  if (!props.image?.filename) {
    return null;
  }

  const sharedProps: ImageProps = {
    src: generateStoryblokImageSrc({
      filename: props.image.filename,
      width: props.cropWidth,
      cropStrategy: props.cropStrategy,
    }),
    alt: props.image.alt,
    className: cn("rounded-inherit", props.className),
    width: props.width,
    height: props.height,
    priority: props.priority,
    onLoad: props.onLoad,
  };

  if (props.fill) {
    return <NextImage {...sharedProps} fill={props.fill} sizes={generateImageSizes(props.size)} />;
  }

  return <NextImage {...sharedProps} />;
}
