import { useMediaQuery as useMediaQueryBase } from "react-responsive";
import type { Breakpoint } from "../types";
import { SCREENS } from "../config/theme";

type Params = {
  from?: Breakpoint;
  to?: Breakpoint;
};

export function useMediaQuery({ from, to }: Params) {
  return useMediaQueryBase({
    minWidth: from ? SCREENS[from] : undefined,
    maxWidth: to ? SCREENS[to] - 1 : undefined,
  });
}
