import type { HTMLAttributeAnchorTarget, MouseEventHandler, ReactNode } from "react";
import { forwardRef } from "react";
import Link from "next/link";
import { CgSpinner } from "react-icons/cg";
import { cn } from "../../utils/misc";

type ButtonSize = "default" | "small" | "medium" | "large";

type ButtonVariant = "normal" | "material" | "subtle" | "link" | "mainMenu" | "secondary";

const sharedClasses = ({ justifyContent }: Partial<ButtonBaseProps>) =>
  cn(
    "inline-flex items-center whitespace-nowrap font-medium transition-all",
    "focus:outline focus:outline-2 focus:outline-brand-d",
    justifyContent === "center" && "justify-center",
    justifyContent === "between" && "justify-between",
  );

/**
 * Designs don't seem to be using responsive font sizes for the button components.
 */
const buttonSizeClasses: Record<ButtonSize, (props: Partial<ButtonBaseProps>) => string> = {
  default: ({ paddingStyle }) => {
    return cn(
      "gap-2.5 text-[0.875rem] leading-[1.2rem]",
      "lg:text-[0.9375rem] lg:leading-[1.3125rem]",
      paddingStyle === "circle" ? "p-3.5" : "px-4 py-3",
    );
  },
  small: ({ paddingStyle }) => {
    return cn(
      "gap-1.5 text-[0.8125rem] leading-[0.95rem]",
      "lg:text-[0.875rem] lg:leading-[1.2rem]",
      paddingStyle === "circle" ? "p-2" : "px-3 py-2",
    );
  },
  medium: ({ paddingStyle }) => {
    return cn(
      "gap-2 text-[0.875rem] leading-[1.2rem]",
      "lg:text-[0.9375rem] lg:leading-[1.3125rem]",
      paddingStyle === "circle" ? "p-2.5" : "px-3.5 py-2.5",
    );
  },
  large: ({ paddingStyle }) => {
    return cn(
      "gap-3 text-[0.9375rem] leading-[1.3125rem]",
      "lg:text-[1rem] lg:leading-[1.5rem]",
      paddingStyle === "circle" ? "p-5" : "px-6 py-5",
    );
  },
};

const buttonVariantClasses: Record<ButtonVariant, (props: Partial<ButtonBaseProps>) => string> = {
  normal: ({ isDisabled }) => {
    return cn(
      "border border-black-t5 bg-foreground text-white shadow-button",
      !isDisabled && "hover:bg-brand-d",
    );
  },
  material: ({ isDisabled }) => {
    return cn(
      "border border-light-3 bg-white text-foreground shadow-button",
      !isDisabled && "hover:bg-light-1",
    );
  },
  subtle: ({ isDisabled }) => {
    return cn("bg-light-t2 text-foreground", !isDisabled && "hover:bg-light-t4");
  },
  link: ({ isDisabled }) => {
    return cn("text-foreground", !isDisabled && "hover:bg-light-2");
  },
  mainMenu: ({ isDisabled }) => {
    return cn("text-foreground", !isDisabled && "hover:text-brand-d");
  },
  secondary: ({ isDisabled }) => {
    return cn(
      "border border-foreground text-foreground",
      !isDisabled && "hover:bg-light-2",
      "focus:border-brand-d",
    );
  },
} as const;

export type ButtonBaseProps = {
  size?: ButtonSize;
  variant?: ButtonVariant;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onClick?: MouseEventHandler;
  linkProps?: {
    href: string;
    target?: HTMLAttributeAnchorTarget;
    isFileDownload?: boolean;
  };
  children?: ReactNode;
  className?: string;
  paddingStyle?: "rectangle" | "circle";
  justifyContent?: "center" | "between";
  type?: "submit" | "button";
  isDisabled?: boolean;
  isLoading?: boolean;
  ariaLabel?: string;
};

/**
 * Implemented according to https://xd.adobe.com/view/08149b25-93c8-462b-99b6-a0301cc5ac28-5d47/specs/.
 */
export const ButtonBase = forwardRef<HTMLButtonElement, ButtonBaseProps>(function ButtonBase(
  {
    size = "default",
    variant = "normal",
    paddingStyle = "rectangle",
    type = "button",
    isDisabled = false,
    isLoading = false,
    justifyContent = "center",
    children,
    startIcon,
    endIcon,
    linkProps,
    className,
    onClick,
    ariaLabel,
    ...props
  },
  ref,
) {
  const sharedProps: Partial<ButtonBaseProps> = {
    className: cn(
      sharedClasses({ justifyContent }),
      buttonSizeClasses[size]({ paddingStyle }),
      buttonVariantClasses[variant]({ isDisabled }),
      isDisabled && "cursor-not-allowed opacity-30",
      className,
    ),
    onClick: (event) => {
      if (isLoading || isDisabled) {
        return;
      }
      onClick?.(event);
    },
    ...props,
  };

  const startContent = isLoading ? <CgSpinner className="animate-spin text-20" /> : startIcon;

  const ButtonContent = (
    <>
      {!!startContent && <span>{startContent}</span>}
      {!!children && <span>{children}</span>}
      {!!endIcon && <span>{endIcon}</span>}
    </>
  );

  if (linkProps?.href) {
    return (
      <Link
        {...sharedProps}
        download={linkProps.isFileDownload ? true : undefined}
        href={linkProps.href}
        target={linkProps.isFileDownload ? "_blank" : linkProps.target}
      >
        {ButtonContent}
      </Link>
    );
  }

  return (
    <button
      {...sharedProps}
      aria-label={ariaLabel}
      disabled={isDisabled}
      ref={ref}
      type={type === "submit" ? "submit" : "button"}
    >
      {ButtonContent}
    </button>
  );
});
