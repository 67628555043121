import type { SbBlockComponentProps, SbBlockContent } from "../types";
import type { CardBlockContent } from "./card-block";
import { CardBlock } from "./card-block";
import { BlockTitle } from "./block-title";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  title?: string;
  cards?: SbBlockContent<CardBlockContent>[];
}>;

export function CardGridBlock({ blok }: Props) {
  if (!blok.cards?.length) {
    return null;
  }

  return (
    <BlockWrapper blok={blok}>
      <BlockTitle title={blok.title} />
      <div className="grid grid-cols-[repeat(auto-fit,minmax(212px,1fr))] gap-4 gap-y-6 lg:gap-6 lg:gap-y-8">
        {blok.cards.map((card) => (
          <CardBlock blok={card} key={card._uid} />
        ))}
      </div>
    </BlockWrapper>
  );
}
