import type { SbAsset, SbBlockComponentProps } from "../types";
import { Carousel } from "../components/carousel/carousel";
import { SbAspectRatioImage } from "../components/image";
import { SCREENS } from "../config/theme";
import { CarouselThumbnails } from "../components/carousel/carousel-thumbnails";
import { CarouselSlides } from "../components/carousel/carousel-slides";
import { CarouselThumbnail } from "../components/carousel/carousel-thumbnail";
import { CarouselSlide } from "../components/carousel/carousel-slide";
import { useLightbox } from "../hooks/use-lightbox";
import { cn } from "../utils/misc";
import { BlockTitle } from "./block-title";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  title?: string;
  images?: SbAsset[];
}>;

export function ImageGalleryBlock({ blok }: Props) {
  const { openLightbox, renderLightbox } = useLightbox();

  const images = blok.images ?? [];

  return (
    <BlockWrapper blok={blok}>
      <BlockTitle title={blok.title} />
      <Carousel>
        <CarouselSlides>
          {images.map((image, index) => (
            <CarouselSlide key={image.id}>
              <div>
                <GalleryImage
                  className="cursor-zoom-in transition-all duration-300 hover:scale-105 hover:opacity-90"
                  image={image}
                  onClick={() => {
                    openLightbox({ initialIndex: index });
                  }}
                />
              </div>
            </CarouselSlide>
          ))}
        </CarouselSlides>
        <CarouselThumbnails>
          {images.map((image, index) => (
            <CarouselThumbnail index={index} key={image.id}>
              <GalleryImage image={image} />
            </CarouselThumbnail>
          ))}
        </CarouselThumbnails>
      </Carousel>
      {renderLightbox({
        slides: images.map((image) => ({
          src: image.filename,
          alt: image.alt,
        })),
      })}
    </BlockWrapper>
  );
}

function GalleryImage({
  image,
  className,
  onClick,
}: {
  image: SbAsset;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <SbAspectRatioImage
      aspectRatio="16/9"
      className={cn("rounded-md border border-light-3", className)}
      cropWidth={SCREENS.lg}
      image={image}
      onClick={onClick}
      size={{
        base: "100vw",
        lg: SCREENS.lg,
      }}
    />
  );
}
