import type { SbBlockComponentProps, SbBlockContent } from "../../types";
import { Carousel } from "../../components/carousel/carousel";
import { CarouselArrows } from "../../components/carousel/carousel-arrows";
import { CarouselDots } from "../../components/carousel/carousel-dots";
import { CarouselSlides } from "../../components/carousel/carousel-slides";
import { CarouselSlide } from "../../components/carousel/carousel-slide";
import type { SlideBlockContent } from "./slide";
import { Slide } from "./slide";

type Props = SbBlockComponentProps<{
  slides?: SbBlockContent<SlideBlockContent>[];
}>;

export function SliderBlock({ blok }: Props) {
  const slides = blok.slides ?? [];

  return (
    <Carousel>
      <CarouselSlides>
        {slides.map((slide, index) => (
          <CarouselSlide key={slide._uid}>
            <Slide index={index} prioritizeImageLoad={index === 0} slide={slide} />
          </CarouselSlide>
        ))}
      </CarouselSlides>
      <CarouselArrows />
      <CarouselDots />
    </Carousel>
  );
}
