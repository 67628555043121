import { storyblokEditable } from "@storyblok/react";
import { PostBase } from "../../components/post-base";
import type {
  KeywordBlockContent,
  SbAsset,
  SbBlockComponentProps,
  SbBlockContent,
  SbBlockSet,
} from "../../types";
import { Layout } from "../../components/layout";
import { useCurrentStory } from "../../context/sb-story-context";
import { humanFriendlyDateFormat } from "../../utils/datetime";
import { getNewsPostedDate } from "../../utils/misc";

export type NewsPostPageBlockContent = {
  image?: SbAsset;
  video?: SbAsset;
  videoPoster?: SbAsset;
  keywords?: SbBlockContent<KeywordBlockContent>[];
  title?: string;
  description?: string;
  body?: SbBlockSet;

  menuContent?: string[];
  /**
   * ISO date, e.g. "2013-10-02 00:00".
   *
   * If not set, "first_published_at" date should be used.
   */
  datePosted?: string;
};

type Props = SbBlockComponentProps<NewsPostPageBlockContent>;

export function NewsPostPageBlock({ blok }: Props) {
  const story = useCurrentStory();
  const datePosted = getNewsPostedDate({ ...story, content: blok });

  return (
    <Layout>
      <div {...storyblokEditable(blok)}>
        <PostBase
          body={blok.body}
          date={datePosted ? humanFriendlyDateFormat(datePosted) : undefined}
          image={blok.image}
          keywords={blok.keywords}
          menuContent={blok.menuContent}
          title={blok.title}
          video={blok.video}
          videoPoster={blok.videoPoster}
        />
      </div>
    </Layout>
  );
}
