import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import Link from "next/link";
import { GrReturn } from "react-icons/gr";
import { useCurrentStory } from "../context/sb-story-context";
import { cn } from "../utils/misc";
import { Modal } from "./modal";
import { LinkDropdownMenu } from "./link-dropdown-menu";
import { MediaQuery } from "./media-query";

type Props = {
  links: { href: string; label: string }[];
  title: string;
};

type LinkBarItem = {
  href: string;
  label: string;
  isActive: boolean;
};

/**
 * Used for navigating to other pages (internal / external).
 */
export function LinkBar(props: Props) {
  const { full_slug: fullSlug } = useCurrentStory();

  const links = props.links.map((link) => ({
    href: link.href,
    label: link.label,
    isActive: link.href === fullSlug,
  }));

  return (
    <div>
      <MediaQuery to="md">
        <MobileLinks links={links} title={props.title} />
      </MediaQuery>
      <MediaQuery from="md" to="lg">
        <DesktopLinks links={links} maxVisibleLinks={3} />
      </MediaQuery>
      <MediaQuery from="lg" to="xl">
        <DesktopLinks links={links} maxVisibleLinks={4} />
      </MediaQuery>
      <MediaQuery from="xl" to="2xl">
        <DesktopLinks links={links} maxVisibleLinks={5} />
      </MediaQuery>
      <MediaQuery from="2xl">
        <DesktopLinks links={links} maxVisibleLinks={6} />
      </MediaQuery>
    </div>
  );
}

function MobileLinks(props: { links: LinkBarItem[]; title: string }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const activeLink = props.links.find((link) => link.isActive);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events -- TODO */}
      <div
        className="relative flex items-center overflow-x-hidden rounded-md bg-light-t2 px-3.5 py-2.5"
        onClick={() => {
          setIsModalOpen(true);
        }}
        role="button"
        tabIndex={0}
      >
        <div className="absolute bottom-0 left-0 top-0 w-3px bg-brand-d" />
        <GrReturn className="mr-2.5 -scale-x-100" size={24} />
        <span className="text-15 font-medium">{activeLink?.label}</span>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="border border-light-3 bg-light-1 p-4 text-14 font-medium uppercase">
          {props.title}
        </div>
        <div className="flex pb-3.5">
          <div className="p-4">
            <GrReturn className="mr-2.5 -scale-x-100" size={24} />
          </div>
          <div className="flex-1 pt-1.5">
            <ul>
              {props.links.map((link) => (
                <li key={link.href}>
                  <MobileLink
                    link={link}
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
}

function MobileLink({ link, ...props }: { link: LinkBarItem; onClick: () => void }) {
  return (
    <Link
      className={cn(
        "block select-none text-15 font-medium transition-all hover:bg-light-t1 hover:text-brand-d",
      )}
      href={link.href}
      onClick={props.onClick}
    >
      <span
        className={cn(
          "block px-3 py-2.5",
          link.isActive && "border-l-3 border-l-brand-d bg-light-t1",
        )}
      >
        {link.label}
      </span>
    </Link>
  );
}

const activeLinkClasses = "underline decoration-brand-d underline-offset-4 decoration-2";

function DesktopLinks(props: { links: LinkBarItem[]; maxVisibleLinks: number }) {
  const visibleItems = props.links.slice(0, props.maxVisibleLinks);
  const dropdownItems = props.links.slice(props.maxVisibleLinks);

  return (
    <div className="flex flex-wrap gap-8 border-b border-b-foreground-t4 pb-5">
      {visibleItems.map((link) => (
        <DesktopLink key={link.href} {...link} />
      ))}
      {dropdownItems.length > 0 && <DesktopAdditionalLinksDropdown items={dropdownItems} />}
    </div>
  );
}

function DesktopAdditionalLinksDropdown(props: { items: LinkBarItem[] }) {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const isChildActive = props.items.some((item) => item.isActive);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events -- TODO
    <div
      className={cn(
        "flex items-center pb-1 text-15 font-medium hover:text-brand-d",
        isChildActive && activeLinkClasses,
      )}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      ref={setReferenceElement}
      role="button"
      tabIndex={0}
    >
      <span>Mehr</span>
      <FiChevronDown className="ml-0.5 mt-0.5" size={20} />
      {!!isOpen && (
        <LinkDropdownMenu
          links={props.items.map((item) => ({
            href: item.href,
            label: item.label,
            isActive: item.isActive,
            id: item.href,
          }))}
          onClose={() => {
            setIsOpen(false);
          }}
          popperReferenceElement={referenceElement}
        />
      )}
    </div>
  );
}

export function DesktopLink(props: LinkBarItem) {
  const classes = cn(
    "max-w-[180px] truncate pb-1 text-15 font-medium transition-all",
    props.isActive && activeLinkClasses,
    !props.isActive && "hover:text-brand-d",
  );

  if (props.isActive) {
    return <div className={classes}>{props.label}</div>;
  }

  return (
    <Link className={classes} href={props.href}>
      {props.label}
    </Link>
  );
}
