import { useQuery } from "@tanstack/react-query";
import { Layout } from "../../components/layout";
import { Container } from "../../components/container";
import { BlockSet } from "../block-set";
import { QUERY_KEY } from "../../config/constants";
import { SkeletonLoader } from "../../components/skeleton-loader";
import { LinkBar } from "../../components/link-bar";
import { MediaQuery } from "../../components/media-query";
import { getPartners } from "../../api/storyblok";
import type { SbAsset, SbBlockComponentProps, SbBlockSet } from "../../types";
import { SbAspectRatioImage } from "../../components/image";
import { SCREENS } from "../../config/theme";

export type PartnerBlockContent = {
  title?: string;
  /**
   * Used primarily on content pages.
   */
  logo?: SbAsset;
  /**
   * Used primarily on landing pages, also can be a fallback for "logo".
   */
  thumbnailLogo?: SbAsset;
  coverImageMobile?: SbAsset;
  coverImageDesktop?: SbAsset;
  body?: SbBlockSet;
  footer?: SbBlockSet;
};

type Props = SbBlockComponentProps<PartnerBlockContent>;

export function PartnerPageBlock({ blok }: Props) {
  return (
    <Layout beforeBreadcrumbs={<PartnerLinks />}>
      <div className="mb-12 lg:mb-16">
        <Container>
          <div className="lg:min-h-[48px]">
            <MediaQuery to="lg">
              <SbAspectRatioImage
                aspectRatio="16/10"
                className="rounded-md"
                cropWidth={SCREENS.md}
                image={blok.coverImageMobile}
                priority={true}
                size="100vw"
              />
            </MediaQuery>
            <MediaQuery from="lg">
              <SbAspectRatioImage
                aspectRatio="16/5"
                className="rounded-md"
                cropStrategy="top"
                cropWidth={SCREENS["2xl"]}
                image={blok.coverImageDesktop}
                priority={true}
                size={{
                  base: "100vw",
                  "2xl": SCREENS["2xl"],
                }}
                style={{
                  objectPosition: "center top",
                }}
              />
            </MediaQuery>
          </div>
        </Container>
        <Container maxWidth="4xl">
          <div className="mt-4 sm:mt-6 lg:mt-0 lg:-translate-y-12 lg:rounded-md lg:bg-white lg:px-12 lg:pt-12">
            <h1 className="text-22 font-semibold lg:text-40">{blok.title}</h1>
            <div className="mt-4 lg:mt-6">
              <BlockSet blocks={blok.body} richTextScale={{ lg: "lg" }} />
            </div>
          </div>
        </Container>
        {!!blok.footer && blok.footer.length > 0 && (
          <Container>
            <div className="mt-8 border-t border-light-3 pt-8">
              <BlockSet blocks={blok.footer} richTextScale={{ lg: "lg" }} />
            </div>
          </Container>
        )}
      </div>
    </Layout>
  );
}

function PartnerLinks() {
  const { data, isError, isPending } = useQuery({
    queryKey: [QUERY_KEY.PARTNERS],
    queryFn: getPartners,
  });

  if (isError) {
    return null;
  }

  if (isPending) {
    return <SkeletonLoader className="h-10" />;
  }

  return (
    <LinkBar
      links={data.map((page) => ({
        href: page.full_slug,
        label: page.name,
      }))}
      title="Unsere Partner"
    />
  );
}
