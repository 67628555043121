import { storyblokEditable } from "@storyblok/react";
import { PostBase } from "../../components/post-base";
import { Layout } from "../../components/layout";
import type { SbAsset, SbBlockComponentProps, SbBlockSet } from "../../types";

export type TechKnowledgePostPageBlockContent = {
  title?: string;
  image?: SbAsset;
  video?: SbAsset;
  videoPoster?: SbAsset;
  body?: SbBlockSet;
  menuContent?: string[];
};

type Props = SbBlockComponentProps<TechKnowledgePostPageBlockContent>;

export function TechKnowledgePostPageBlock({ blok }: Props) {
  return (
    <Layout>
      <div {...storyblokEditable(blok)}>
        <PostBase
          body={blok.body}
          image={blok.image}
          menuContent={blok.menuContent}
          title={blok.title}
          video={blok.video}
          videoPoster={blok.videoPoster}
        />
      </div>
    </Layout>
  );
}
