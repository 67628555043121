import { FiDownload } from "react-icons/fi";
import type { SbBlockComponentProps, SbAsset } from "../types";
import { getFileExtensionFromUrl } from "../utils/misc";
import { Button } from "../components/button";
import { BlockTitle } from "./block-title";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  title?: string;
  file_name?: string;
  file?: SbAsset;
}>;

export function FileDownloadBoxBlock({ blok }: Props) {
  if (!blok.file?.filename) {
    return null;
  }

  return (
    <BlockWrapper blok={blok}>
      <BlockTitle title={blok.title} />
      <div className="flex flex-col gap-4 rounded-md border border-light-3 bg-light-1 p-4 md:flex-row md:items-center md:justify-between md:gap-6 md:p-6">
        <div>
          <div className="text-13 lg:text-15">{blok.file_name}</div>
          <div className="mt-1 text-13 uppercase opacity-30 lg:text-14">
            {getFileExtensionFromUrl(blok.file.filename)}
          </div>
        </div>
        <div>
          <Button
            endIcon={<FiDownload size={20} />}
            isFullWidth={true}
            linkProps={{
              href: blok.file.filename,
              isFileDownload: true,
            }}
          >
            Download
          </Button>
        </div>
      </div>
    </BlockWrapper>
  );
}
