import { useFormContext } from "react-hook-form";
import { FiChevronDown } from "react-icons/fi";
import { cn } from "../../utils/misc";
import { RHFFormControl } from "./rhf-form-control";
import { formFieldSharedClasses } from "./shared-classes";

type Props = {
  name: string;
  label: string;
  id: string;
  options: { label: string; value: string }[];
  placeholder?: string;
};

// noinspection JSUnusedGlobalSymbols
export function RHFSelect({ placeholder = "Auswählen", ...props }: Props) {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO
  const value = watch(props.name);
  const hasValueSelected = !!value;
  const isError = !!errors[props.name];

  return (
    <RHFFormControl errors={errors} id={props.id} label={props.label} name={props.name}>
      <div className="relative">
        <select
          className={cn(
            formFieldSharedClasses({ isError }),
            "w-full cursor-pointer rounded-md bg-none pr-10 focus:text-foreground",
            !hasValueSelected && "text-light-t7",
          )}
          defaultValue=""
          {...register(props.name)}
        >
          <option disabled={true} value="">
            {placeholder}
          </option>
          {props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute bottom-0 right-0 top-0 flex w-10 items-center justify-center border-l border-light-t4">
          <FiChevronDown size={30} />
        </div>
      </div>
    </RHFFormControl>
  );
}
