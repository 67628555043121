import type { ImageAspectRatio, StoryblokImageCropStrategy } from "../components/image";
import { imageAspectRatio } from "../components/image/config";

const cropStrategyUrlParams: Record<StoryblokImageCropStrategy, string> = {
  smart: "smart",
  top: "filters:focal(450x0:550x100)",
};

const calcAspectImageDimensions = (aspectRatio: ImageAspectRatio, width: number) => {
  const aspectRatioConfig = imageAspectRatio[aspectRatio];
  const height = Math.round((aspectRatioConfig.heightRatio / aspectRatioConfig.widthRatio) * width);
  return `${width}x${height}`;
};

export const generateStoryblokImageSrc = ({
  filename,
  width,
  aspectRatio,
  cropStrategy = "smart",
}: {
  filename: string;
  width?: number;
  aspectRatio?: ImageAspectRatio;
  cropStrategy?: StoryblokImageCropStrategy;
}) => {
  if (!width || width < 0) {
    return `${filename}/m/`;
  }

  const dimensions = aspectRatio ? calcAspectImageDimensions(aspectRatio, width) : `${width}x0`;
  const crop = cropStrategyUrlParams[cropStrategy];

  return `${filename}/m/${dimensions}/${crop}`;
};
