import { useQuery } from "@tanstack/react-query";
import { type ISbRichtext, storyblokEditable } from "@storyblok/react";
import type { SbBlockComponentProps, SbAsset, System } from "../../types";
import { Container } from "../../components/container";
import { QUERY_KEY } from "../../config/constants";
import { RichTextContent } from "../../components/rich-text-content";
import { useCurrentStory } from "../../context/sb-story-context";
import { Layout } from "../../components/layout";
import { OverviewCardList } from "../../components/overview-card-list";
import { SystemSolutionParentPageHeader } from "../../components/system-solution-parent-page-header";
import { getSystemColorScheme } from "../../config/solution-sector-color-scheme";
import { getStories, getStoriesByUuids } from "../../api/storyblok";
import { cn } from "../../utils/misc";
import { RelatedStoriesGrid } from "../../components/related-stories-grid";
import type { SolutionFamilyPageBlockContent } from "./solution-family-page-block";

export type SolutionSectorPageBlockContent = {
  heading?: string;
  image?: SbAsset;
  video?: SbAsset;
  videoPoster?: SbAsset;
  text?: ISbRichtext;
  subcategories?: string[];
  system?: System;
};

type Props = SbBlockComponentProps<SolutionSectorPageBlockContent>;

export function SolutionSectorPageBlock({ blok }: Props) {
  const { id } = useCurrentStory();

  const subcategoryIds = blok.subcategories ?? [];

  const alternateLinksQuery = useQuery({
    queryKey: [QUERY_KEY.SYSTEM_SOLUTION_ALTERNATE_LINKS, id],
    /**
     * Retrieves all system solution overview stories except the one
     * that is currently being viewed.
     */
    queryFn: async () => {
      const data = await getStories<SolutionSectorPageBlockContent>({
        content_type: "systemSolution",
        excluding_ids: String(id),
      });

      return data.data;
    },
  });
  const subcategoryListQuery = useQuery({
    queryKey: [QUERY_KEY.STORIES_BY_UUIDS, subcategoryIds],
    queryFn: () => getStoriesByUuids<SolutionFamilyPageBlockContent>(subcategoryIds),
  });

  const colorScheme = getSystemColorScheme(blok.system);

  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-12 lg:mb-16">
        <Container>
          <SystemSolutionParentPageHeader
            beforeTitle={
              <div
                className={cn(
                  "mt-1.5 h-4 w-4 shrink-0 rounded-3px md:h-6 md:w-6 md:rounded-md lg:mt-1 xl:mt-1.5",
                  colorScheme.bgClass,
                )}
              />
            }
            description={<RichTextContent content={blok.text} />}
            image={blok.image}
            title={blok.heading}
            video={blok.video}
            videoPoster={blok.videoPoster}
          />

          <OverviewCardList
            imageVariant="cover"
            isError={subcategoryListQuery.isError}
            isPending={subcategoryListQuery.isPending}
            items={
              subcategoryListQuery.isSuccess
                ? subcategoryListQuery.data.stories.map((story) => ({
                    id: story.uuid,
                    href: story.full_slug,
                    image: story.content.image,
                    title: story.content.title,
                    colorClass: colorScheme.bgClass,
                    description: story.content.description,
                  }))
                : undefined
            }
          />

          <div className="mt-8 border-t border-light-3 pt-8">
            <RelatedStoriesGrid
              isError={alternateLinksQuery.isError}
              isPending={alternateLinksQuery.isPending}
              stories={alternateLinksQuery.data?.stories}
            />
          </div>
        </Container>
      </div>
    </Layout>
  );
}
