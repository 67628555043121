import { FiArrowRight } from "react-icons/fi";
import type { BreadcrumbItemProps } from "./_types";
import { BreadcrumbItem } from "./_breadcrumb-item";

export function DesktopBreadcrumbItems(props: { items: BreadcrumbItemProps[]; maxItems: number }) {
  const getFilteredItems = () => {
    const divider = { label: "...", href: "", isPlainText: true, id: "divider" };
    const lastXItems = props.items.slice(props.items.length - props.maxItems);

    return [divider, ...lastXItems];
  };

  /**
   * Based on the max-width of the Container component and the max-width
   * of a breadcrumb item, we set the max number of displayed items.
   *
   * If the original number exceeds this limit, we place a "..." divider between the first
   * item (Start link) and the last X items.
   */
  const filteredItems: (BreadcrumbItemProps & { isPlainText?: boolean })[] =
    props.items.length <= props.maxItems ? props.items : getFilteredItems();

  return (
    <div className="flex items-center gap-x-3">
      {filteredItems.map((item, index) => (
        <div className="group flex items-center gap-x-3" key={item.label}>
          {index > 0 && <FiArrowRight size={20} />}
          <BreadcrumbItem
            href={item.href}
            isPlainText={item.isPlainText || index === filteredItems.length - 1}
            label={item.label}
          />
        </div>
      ))}
    </div>
  );
}
