import Link from "next/link";
import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { PORTAL_ROOT_NODE_ID } from "../config/constants";
import { cn } from "../utils/misc";

type DropdownMenuLink = {
  href: string;
  id: string | undefined;
  isActive: boolean;
  label: string | undefined;
};

type Props = {
  links: DropdownMenuLink[];
  onClose: () => void;
  /**
   * Use a RefCallback as explained here https://popper.js.org/react-popper/v2/#example.
   */
  popperReferenceElement: Element | null;
};

export const LinkDropdownMenu = (props: Props) => {
  const contentRef = useRef(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(props.popperReferenceElement, popperElement, {
    placement: "bottom-start",
  });

  useOnClickOutside(contentRef, props.onClose);

  return createPortal(
    <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <div
        className="z-30 w-max min-w-[256px] max-w-[720px] overflow-hidden rounded-xl bg-white shadow-elevated"
        ref={contentRef}
      >
        <ul>
          {props.links.map((link) => (
            <li key={link.id}>
              <ChildLink link={link} onClick={props.onClose} />
            </li>
          ))}
        </ul>
      </div>
    </div>,
    document.getElementById(PORTAL_ROOT_NODE_ID) as HTMLDivElement,
  );
};

function ChildLink(props: { link: DropdownMenuLink; onClick: () => void }) {
  if (!props.link.href) {
    return null;
  }

  return (
    <Link
      className={cn(
        "block truncate border-l-3 px-3 py-2.5 text-15 font-medium transition-all hover:bg-light-t1",
        !props.link.isActive && "border-transparent",
        props.link.isActive && "border-brand-d bg-light-t1",
      )}
      href={props.link.href}
      onClick={props.onClick}
    >
      {props.link.label}
    </Link>
  );
}
