import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import { FiArrowRight } from "react-icons/fi";
import { useQuery } from "@tanstack/react-query";
import type { SbBlockComponentProps, SbStoryProps } from "../types";
import { Button } from "../components/button";
import { humanFriendlyDateFormat } from "../utils/datetime";
import { QUERY_KEY, ROUTES } from "../config/constants";
import { SkeletonLoader } from "../components/skeleton-loader";
import { MediaQuery } from "../components/media-query";
import { SbAspectRatioImage, SbImage } from "../components/image";
import { SCREENS } from "../config/theme";
import { getStories } from "../api/storyblok";
import { cn, getNewsPostedDate } from "../utils/misc";
import type { NewsPostPageBlockContent } from "./pages/news-post-page-block";

type Props = SbBlockComponentProps<Record<string, unknown>>;

export function LandingPageNewsBlock({ blok }: Props) {
  const {
    data: posts,
    isPending,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: [QUERY_KEY.LANDING_PAGE_POSTS],
    queryFn: async () => {
      const data = await getStories<NewsPostPageBlockContent>({
        content_type: "post",
        per_page: 3,
        sort_by: "content.datePosted:desc",
      });

      return data.data.stories;
    },
  });

  if (isError) {
    return null;
  }

  return (
    <div {...storyblokEditable(blok)}>
      {!!isPending && <SkeletonLoader className="h-80" />}

      {!!isSuccess && (
        <div className="flex flex-col gap-6 lg:flex-row lg:gap-4">
          {!!posts[0] && <PrimaryPost post={posts[0]} />}
          <div>
            <div className="grid grid-cols-2 gap-2 lg:grid-cols-1 lg:gap-4">
              {!!posts[1] && <SecondaryPost post={posts[1]} />}
              {!!posts[2] && <SecondaryPost post={posts[2]} />}
            </div>
            <div className="mt-6 lg:mt-4">
              <Button
                endIcon={<FiArrowRight size={20} />}
                isFullWidth={true}
                justifyContent="between"
                linkProps={{ href: ROUTES.NEWS }}
                size="medium"
                variant="secondary"
              >
                Weitere Beiträge
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function PrimaryPost({ post }: { post: SbStoryProps<NewsPostPageBlockContent> }) {
  const keywords = post.content.keywords?.[0];
  const postedDate = getNewsPostedDate(post);

  return (
    <Link
      className="flex flex-col gap-4 hover:opacity-80 lg:flex-1 lg:flex-row"
      href={post.full_slug}
    >
      <div className="relative w-full rounded-md bg-brand-d lg:order-2 lg:flex lg:w-1/2 lg:shrink-0">
        <MediaQuery to="lg">
          <SbAspectRatioImage
            aspectRatio="3/2"
            className="rounded-md"
            cropWidth={SCREENS.md}
            image={post.content.image}
            size="100vw"
          />
        </MediaQuery>
        <MediaQuery from="lg">
          <SbImage
            className="object-cover"
            cropWidth={SCREENS.md}
            fill={true}
            image={post.content.image}
            size={{
              base: "100vw",
              lg: SCREENS.md,
            }}
          />
        </MediaQuery>
      </div>
      <div className="lg:flex lg:flex-col lg:justify-center lg:pr-2 xl:pr-6">
        <div className="hidden text-14 font-medium uppercase text-foreground-t1 lg:block">
          {!!keywords && keywords.title}
          {!!keywords && !!postedDate && " | "}
          {!!postedDate && humanFriendlyDateFormat(postedDate)}
        </div>
        <h4 className="line-clamp-3 text-22 font-semibold lg:mt-4 lg:text-32 xl:text-40">
          {post.content.title}
        </h4>
        <div className="mt-3 line-clamp-4 text-14 lg:mt-4 lg:line-clamp-3 lg:text-16">
          {post.content.description}
        </div>
      </div>
    </Link>
  );
}

function SecondaryPost({ post }: { post: SbStoryProps<NewsPostPageBlockContent> }) {
  return (
    <Link
      className="relative block overflow-hidden rounded-md transition-all hover:opacity-80 lg:w-[200px] xl:w-[240px] 2xl:w-[275px]"
      href={post.full_slug}
    >
      <SbAspectRatioImage
        aspectRatio="16/9"
        cropWidth={400}
        image={post.content.image}
        size={{ base: "50vw", lg: 300 }}
      />

      <div
        className={cn(
          "mt-2",
          "lg:absolute lg:inset-0 lg:z-10 lg:flex lg:flex-col lg:justify-end lg:p-3",
          "lg:bg-gradient-to-t lg:from-black-t2 lg:to-transparent",
        )}
      >
        <h4
          className={cn(
            "line-clamp-3 hyphens-auto text-14 font-semibold xl:text-16",
            "lg:line-clamp-2 lg:text-white",
          )}
          lang="de"
        >
          {post.name}
        </h4>
      </div>
    </Link>
  );
}
