import { SCREENS } from "../../config/theme";
import type { Breakpoint } from "../../types";
import type { AspectRatioImageProps, ImageSize } from "./types";

const normalizeSizeValue = (value: ImageSize) => {
  return typeof value === "number" ? `${value}px` : value;
};

export const generateImageSizes = (size: AspectRatioImageProps["size"]) => {
  if (!size) {
    return "100vw";
  }

  if (typeof size === "string" || typeof size === "number") {
    return normalizeSizeValue(size);
  }

  const sizeSet = [];

  /**
   * Include responsive sizes first.
   */
  (Object.keys(SCREENS) as Breakpoint[]).forEach((breakpoint) => {
    const value = size[breakpoint];
    if (!value) {
      return;
    }

    const condition = `(min-width: ${SCREENS[breakpoint]}px)`;
    sizeSet.push(`${condition} ${normalizeSizeValue(value)}`);
  });

  /**
   * Include the default size as the last one.
   */
  sizeSet.push(normalizeSizeValue(size.base));

  return sizeSet.join(", ");
};
