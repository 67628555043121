import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import { useCallback, useEffect, useState } from "react";
import { IconButton } from "../button";
import { useCarouselContext } from "./carousel-context";

/**
 * These arrows are currently only used on the landing page, and the classes for button wrappers
 * would probably not be suitable for other use-cases (they have pretty specific positioning).
 * If the arrows are ever added elsewhere, the classes should be adjusted,
 * or a render prop should be added for each of them.
 */
export function CarouselArrows() {
  const { emblaMainApi } = useCarouselContext();
  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(true);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true);

  const maybeDisableButtons = useCallback(() => {
    if (!emblaMainApi) {
      return;
    }
    setIsPrevBtnDisabled(!emblaMainApi.canScrollPrev());
    setIsNextBtnDisabled(!emblaMainApi.canScrollNext());
  }, [emblaMainApi]);

  const scrollPrev = useCallback(() => {
    if (!emblaMainApi) {
      return;
    }
    emblaMainApi.scrollPrev();
  }, [emblaMainApi]);

  const scrollNext = useCallback(() => {
    if (!emblaMainApi) {
      return;
    }
    emblaMainApi.scrollNext();
  }, [emblaMainApi]);

  useEffect(() => {
    if (!emblaMainApi) {
      return;
    }

    maybeDisableButtons();
    emblaMainApi.on("reInit", maybeDisableButtons);
    emblaMainApi.on("select", maybeDisableButtons);
  }, [emblaMainApi, maybeDisableButtons]);

  return (
    <>
      <div className="absolute left-0 top-[247px] flex -translate-y-1/2 sm:top-[302px] md:top-[392px] lg:left-6 lg:top-1/2 lg:h-[80px]">
        <IconButton
          ariaLabel="Vorherige"
          icon={<HiOutlineArrowLeft size={24} />}
          isDisabled={isPrevBtnDisabled}
          onClick={scrollPrev}
          size="small"
          variant="material"
        />
      </div>
      <div className="absolute left-[48px] top-[247px] flex -translate-y-1/2 sm:top-[302px] md:top-[392px] lg:left-auto lg:right-6 lg:top-1/2 lg:h-[80px]">
        <IconButton
          ariaLabel="Nächste"
          icon={<HiOutlineArrowRight size={24} />}
          isDisabled={isNextBtnDisabled}
          onClick={scrollNext}
          size="small"
          variant="material"
        />
      </div>
    </>
  );
}
