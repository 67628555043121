import type { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";
import { cn } from "../../utils/misc";
import { textInputSharedClasses } from "./shared-classes";

type HtmlInputProps = Pick<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "id" | "placeholder" | "onChange" | "onBlur" | "name" | "value" | "onFocus"
>;

type Props = HtmlInputProps & {
  isError?: boolean;
  adornmentRight?: ReactNode;
};

export const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ isError = false, ...props }, ref) => {
    return (
      <div className="relative">
        <input
          className={cn(
            textInputSharedClasses({ isError, hasRightAdornment: !!props.adornmentRight }),
            "peer",
          )}
          id={props.id}
          name={props.name}
          onBlur={props.onBlur}
          onChange={props.onChange}
          onFocus={props.onFocus}
          placeholder={props.placeholder ?? " "}
          ref={ref}
          type="text"
          value={props.value}
        />
        {!!props.adornmentRight && props.adornmentRight}
      </div>
    );
  },
);

TextInput.displayName = "TextInput";
