import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { FiExternalLink } from "react-icons/fi";
import type { GetJobListResponse, HrLabAnnouncement, SbBlockComponentProps } from "../types";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/accordion";
import { QUERY_KEY } from "../config/constants";
import { Button } from "../components/button";
import { SkeletonLoader } from "../components/skeleton-loader";
import { useMediaQuery } from "../hooks/use-media-query";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type Props = SbBlockComponentProps<{
  title?: string;
}>;

export function JobListBlock({ blok }: Props) {
  const {
    data: jobCategories,
    isPending,
    isSuccess,
  } = useQuery({
    queryKey: [QUERY_KEY.JOB_LIST],
    queryFn: getJobList,
    select: makeJobListViewModel,
  });

  const isMobile = useMediaQuery({ to: "sm" });

  return (
    <BlockWrapper blok={blok}>
      {!!blok.title && (
        <h2 className="mb-4 text-28 font-semibold lg:mb-6 lg:text-40">{blok.title}</h2>
      )}

      {!!isPending && (
        <div className="flex flex-col gap-4">
          {[0, 1].map((i) => (
            <div key={i}>
              <SkeletonLoader className="h-20" />
              <div className="flex flex-col gap-3 pt-4 lg:gap-4 lg:pl-8">
                <SkeletonLoader className="h-16" />
                <SkeletonLoader className="h-16" />
                <SkeletonLoader className="h-16" />
              </div>
            </div>
          ))}
        </div>
      )}

      {!!isSuccess && (
        <Accordion
          className="flex flex-col gap-4"
          defaultValue={jobCategories.map((c) => String(c.id))}
          type="multiple"
        >
          {jobCategories.map((category) => (
            <AccordionItem className="border-b-0" key={category.id} value={String(category.id)}>
              <AccordionTrigger
                className="truncate rounded-md bg-light-3 p-4 text-18 font-semibold lg:p-6 lg:text-24"
                iconClassName="h-6 w-6 lg:h-8 lg:w-8"
              >
                {category.name}
              </AccordionTrigger>
              <AccordionContent>
                <div className="flex flex-col gap-3 pt-4 lg:gap-4 lg:pl-8">
                  {category.jobs.map((job) => (
                    <div
                      className="flex flex-col items-start justify-between gap-2 rounded-md bg-light-t1 px-4 py-3 sm:flex-row sm:items-center sm:gap-6 lg:px-6 lg:py-4"
                      key={job.id}
                    >
                      <span className="hyphens-auto text-15 lg:text-18" lang="de">
                        {job.job_title}
                      </span>
                      <Button
                        endIcon={<FiExternalLink className="h-4 w-4 sm:h-5 sm:w-5" />}
                        linkProps={{
                          href: `https://app.hrlab.de/de/jobs/pts_group_gmbh/${job.id}`,
                          target: "_blank",
                        }}
                        size={isMobile ? "small" : "medium"}
                      >
                        Jetzt bewerben
                      </Button>
                    </div>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      )}

      {!!isSuccess && jobCategories.length === 0 && (
        <div className="lg:text-18">Wir haben derzeit keine offenen Stellen.</div>
      )}
    </BlockWrapper>
  );
}

async function getJobList() {
  const { data } = await axios.get<GetJobListResponse>("/api/get-job-list");
  return data.data;
}

function makeJobListViewModel(data: HrLabAnnouncement[]) {
  function getJobsForCategory(id: number) {
    const filteredJobs = data.filter((item) => item.category_id === id);

    filteredJobs.sort((a, b) => a.job_title.localeCompare(b.job_title));

    return filteredJobs;
  }

  const categories = [
    { id: 510, name: "Technischer Bereich", jobs: getJobsForCategory(510) },
    { id: 241, name: "Allgemein", jobs: getJobsForCategory(241) },
  ];

  return categories.filter((item) => item.jobs.length > 0);
}
