import { useRouter } from "next/router";
import { useMemo } from "react";
import type { ConfigBlockContent, NavItemType, NavLinkBlockContent } from "../../types";
import { getSbLinkUrl } from "../../utils/misc";

export type EnhancedNavItemType = NavItemType & { index: number; isActive: boolean };

export function useEnhancedNavItems({ config }: { config: ConfigBlockContent }) {
  const { asPath } = useRouter();
  const normalizedRouterPath = normalizeUrl(asPath);

  const primaryItems = useMemo(
    () =>
      enhanceItems({
        items: config.primaryNavigation ?? [],
        routerPath: normalizedRouterPath,
        startIndex: 0,
      }),
    [config.primaryNavigation, normalizedRouterPath],
  );

  const secondaryItems = useMemo(
    () =>
      enhanceItems({
        items: config.secondaryNavigation ?? [],
        routerPath: normalizedRouterPath,
        startIndex: primaryItems.length,
      }),
    [config.secondaryNavigation, normalizedRouterPath, primaryItems.length],
  );

  const allItems = useMemo(
    () => [...primaryItems, ...secondaryItems],
    [primaryItems, secondaryItems],
  );

  const contentItems = useMemo(
    () =>
      allItems.filter(
        (item): item is Exclude<EnhancedNavItemType, NavLinkBlockContent> =>
          item.component !== "navigationLink",
      ),
    [allItems],
  );

  return {
    primaryItems,
    secondaryItems,
    allItems,
    contentItems,
  };
}

function enhanceItems({
  items,
  routerPath,
  startIndex,
}: {
  items: NavItemType[];
  routerPath: string;
  startIndex: number;
}) {
  const normalizedRouterPath = normalizeUrl(routerPath);

  return items.map((navItem, index) => ({
    ...navItem,
    index: index + startIndex,
    isActive: isNavItemActive({ navItem, normalizedRouterPath }),
  }));
}

function isNavItemActive({
  navItem,
  normalizedRouterPath,
}: {
  navItem: NavItemType;
  normalizedRouterPath: string;
}) {
  if (navItem.component === "navigationNestedMenu") {
    return (
      navItem.sections?.some(
        (section) =>
          section.content?.some(
            (linkList) =>
              linkList.links?.some((link) => isActiveLink({ link, normalizedRouterPath })),
          ),
      ) ?? false
    );
  }

  if (navItem.component === "navigationMenu") {
    return (
      navItem.content?.some(
        (linkList) => linkList.links?.some((link) => isActiveLink({ link, normalizedRouterPath })),
      ) ?? false
    );
  }

  return isActiveLink({ link: navItem, normalizedRouterPath });
}

function isActiveLink({
  link,
  normalizedRouterPath,
}: {
  link: NavLinkBlockContent;
  normalizedRouterPath: string;
}) {
  if (!link.link) {
    return false;
  }

  return (
    normalizeUrl(getSbLinkUrl(link.link)) === normalizedRouterPath ||
    currentPathBeginsWithParentPath(normalizedRouterPath, link)
  );
}

/**
 * Used for comparison with other URLs - removes leading/trailing slashes,
 * query params and hashes.
 */
function normalizeUrl(url: string) {
  return url
    .split(/[?#]/)[0] // remove possible query params and hashes
    .split("/") // extract parts
    .filter(Boolean) // remove empty parts
    .join("/"); // combine into string without leading or trailing slashes
}

function currentPathBeginsWithParentPath(currentPath: string, parent: NavLinkBlockContent) {
  if (!parent.link) {
    return false;
  }

  const parentUrl = getSbLinkUrl(parent.link);
  if (!parentUrl) {
    return false;
  }

  return currentPath.startsWith(normalizeUrl(parentUrl));
}
