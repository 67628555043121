import { useState } from "react";
import { CookieSettingsDialog } from "./cookie-settings-dialog";
import { CookieConsentBanner } from "./cookie-consent-banner";
import { useTrackingPreferences } from "./use-tracking-preferences";
import { useTrackingInit } from "./use-tracking-init";

export function CookieConsentObserver() {
  const { shouldGetConsent, setConsentState, onAcceptAll, onRejectAll } = useTrackingPreferences();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  useTrackingInit({ isEnabled: !shouldGetConsent });

  if (!shouldGetConsent) {
    return null;
  }

  if (isSettingsOpen) {
    return (
      <CookieSettingsDialog
        onAcceptAll={onAcceptAll}
        onClose={() => {
          setIsSettingsOpen(false);
        }}
        onRejectAll={onRejectAll}
        onSave={setConsentState}
      />
    );
  }

  return (
    <CookieConsentBanner
      onAcceptAll={onAcceptAll}
      onRejectAll={onRejectAll}
      onSettingsClick={() => {
        setIsSettingsOpen(true);
      }}
    />
  );
}
