import { cn } from "../../utils/misc";

/**
 * Used with input, textarea, select.
 */
export const formFieldSharedClasses = (props: { isError: boolean; hasRightAdornment?: boolean }) =>
  cn(
    "border pl-3.5 transition-colors",
    !props.hasRightAdornment && "pr-3.5",
    props.hasRightAdornment && "pr-14",
    !props.isError &&
      cn(
        "border-transparent bg-light-t2",
        "hover:border-light-t4",
        "focus:border-brand-d focus:bg-white focus:shadow-border focus:shadow-brand-d focus:ring-brand-d",
      ),
    props.isError &&
      cn(
        "border-error bg-[#EB002617]",
        "focus:shadow-alert focus:ring-alert focus:border-error focus:shadow-border",
      ),
  );

/**
 * Used with fields that contain text the user has entered (input, textarea).
 */
export const textInputSharedClasses = (props: { isError: boolean; hasRightAdornment?: boolean }) =>
  cn(
    formFieldSharedClasses({ isError: props.isError, hasRightAdornment: props.hasRightAdornment }),
    "w-full rounded-md",
    "placeholder:text-light-t7",
  );
