import Link from "next/link";
import { FaPhoneVolume } from "react-icons/fa6";
import { useConfig } from "../../hooks/use-config";
import { SkeletonLoader } from "../skeleton-loader";
import { getSbLinkUrl, getSocialLinks } from "../../utils/misc";
import type { MenuLinkBlockContent } from "../../types";

export function HeaderIconLinks() {
  const { data, isPending, isError, isSuccess } = useConfig();

  if (isError) {
    return null;
  }

  return (
    <div className="flex items-center gap-x-5 md:gap-x-6 lg:gap-x-8">
      {!!isPending && (
        <div className="h-6 w-24 lg:h-10 lg:w-32">
          <SkeletonLoader className="h-full" />
        </div>
      )}

      {!!isSuccess && (
        <>
          {!!data.phoneNumberLink?.[0] && <PhoneNumberLink link={data.phoneNumberLink[0]} />}
          {getSocialLinks(data).map((link) => (
            <Link
              aria-label={link.label}
              className="transition-opacity hover:opacity-70"
              href={link.url}
              key={link._uid}
              target="_blank"
            >
              <link.icon className="text-24 md:text-28" />
            </Link>
          ))}
        </>
      )}
    </div>
  );
}

function PhoneNumberLink({ link }: { link: MenuLinkBlockContent }) {
  const href = getSbLinkUrl(link.link);

  if (!href) {
    return null;
  }

  return (
    <Link
      aria-label="Telefonnummer"
      className="flex items-center gap-3 transition-opacity hover:opacity-70"
      href={href}
      target="_blank"
    >
      <FaPhoneVolume className="text-20 md:text-24" />
      <span className="hidden font-medium xl:block">{link.name}</span>
    </Link>
  );
}
