import { storyblokEditable } from "@storyblok/react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY } from "../../config/constants";
import { useContactPersonQuery } from "../../hooks/use-contact-person-query";
import { BlockSet } from "../block-set";
import { getMarkets } from "../../api/storyblok";
import type { SbAsset, SbBlockComponentProps, SbBlockSet } from "../../types";
import { Layout } from "../../components/layout";
import { Container } from "../../components/container";
import { LinkBar } from "../../components/link-bar";
import { TextLink } from "../../components/text-link";
import { SkeletonLoader } from "../../components/skeleton-loader";
import { SbAspectRatioImage } from "../../components/image";
import { MediaQuery } from "../../components/media-query";
import { SCREENS } from "../../config/theme";
import { cn } from "../../utils/misc";

export type MarketPageBlockContent = {
  title?: string;
  image?: SbAsset;
  body?: SbBlockSet;
  footer?: SbBlockSet;
  contactPerson?: string;
};

type Props = SbBlockComponentProps<MarketPageBlockContent>;

export function MarketPageBlock({ blok }: Props) {
  return (
    <Layout beforeBreadcrumbs={<MarketCategoryLinks />}>
      <div {...storyblokEditable(blok)} className="mb-12 lg:mb-16">
        <Container>
          <MediaQuery to="lg">
            <SbAspectRatioImage
              aspectRatio="16/10"
              className="rounded-md"
              cropWidth={SCREENS.lg}
              image={blok.image}
              priority={true}
              size="100vw"
            />
          </MediaQuery>
          <MediaQuery from="lg">
            <SbAspectRatioImage
              aspectRatio="16/5"
              className="rounded-md"
              cropWidth={SCREENS["2xl"]}
              image={blok.image}
              priority={true}
              size={{ base: "100vw", "2xl": SCREENS["2xl"] }}
            />
          </MediaQuery>
        </Container>
        <Container maxWidth="4xl">
          <div className="mt-4 sm:mt-6 lg:mt-0 lg:-translate-y-12 lg:rounded-md lg:bg-white lg:px-12 lg:pt-12">
            <h1 className="text-22 font-semibold lg:text-40">{blok.title}</h1>
            <div className="mt-4 lg:mt-6">
              <BlockSet blocks={blok.body} richTextScale={{ lg: "lg" }} />
            </div>
          </div>
        </Container>
        {!!blok.footer && blok.footer.length > 0 && (
          <Container>
            <div className="mt-8 border-t border-light-3 pt-8">
              <BlockSet blocks={blok.footer} richTextScale={{ lg: "lg" }} />
            </div>
          </Container>
        )}
        <Container>
          {!!blok.contactPerson && (
            <div className="mt-12 lg:mt-16">
              <MarketContactPerson uuid={blok.contactPerson} />
            </div>
          )}
        </Container>
      </div>
    </Layout>
  );
}

function MarketCategoryLinks() {
  const { data, isError, isPending } = useQuery({
    queryKey: [QUERY_KEY.MARKETS],
    queryFn: getMarkets,
  });

  if (isError) {
    return null;
  }

  if (isPending) {
    return <SkeletonLoader className="h-10" />;
  }

  return (
    <LinkBar
      links={data.map((page) => ({
        href: page.full_slug,
        label: page.name,
      }))}
      title="Märkte & Branchen"
    />
  );
}

function MarketContactPerson(props: { uuid: string }) {
  const { data, isError, isPending } = useContactPersonQuery(props.uuid);

  if (isError) {
    return null;
  }

  if (isPending) {
    return <SkeletonLoader className="h-80" />;
  }

  return (
    <div className="relative">
      <div
        className={cn(
          "absolute inset-0 rounded-md border border-light-3 bg-light-1",
          "lg:top-8",
          "xl:top-16",
        )}
      />
      <div
        className={cn(
          "relative grid grid-cols-1 gap-y-6 px-4 pt-4 sm:px-8 sm:pt-8 lg:px-0 lg:pt-0",
          "lg:grid-cols-[300px_300px_1fr]",
          "xl:grid-cols-[380px_360px_1fr]",
          "2xl:grid-cols-[1fr_400px_1fr]",
        )}
      >
        <div
          className={cn(
            "text-18 font-semibold",
            "lg:flex lg:grow lg:items-center lg:pl-8 lg:pt-8 lg:text-24",
            "xl:pl-16 xl:pr-12 xl:pt-16",
            "2xl:pl-32",
          )}
        >
          Ich beraten Sie gern!
        </div>

        <div
          className={cn(
            "flex min-w-0 flex-col gap-3",
            "lg:order-3 lg:grow lg:justify-center lg:gap-4 lg:pl-12 lg:pr-8 lg:pt-8",
            "xl:pt-16",
          )}
        >
          <div className="text-12 font-medium text-light-7 lg:text-14">{data.title}</div>
          <div className="line-clamp-2 text-18 font-semibold lg:text-24">{data.name}</div>
          <div className="lg:text-20">
            <TextLink href={`tel:${data.phone_number}`} variant="highlighted">
              {data.phone_number}
            </TextLink>
          </div>
          <div className="truncate lg:text-20">
            <TextLink href={`mailto:${data.email}`} variant="highlighted">
              {data.email}
            </TextLink>
          </div>
        </div>

        <div className="md:pr-20 lg:pr-0">
          <div className="ml-auto max-w-[65%] sm:max-w-[360px] lg:ml-0 lg:max-w-full">
            <SbAspectRatioImage aspectRatio="1/1" cropWidth={400} image={data.photo} size={400} />
          </div>
        </div>
      </div>
    </div>
  );
}
