import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import type { ISbRichtext } from "@storyblok/react";
import axios from "axios";
import { useRouter } from "next/router";
import { AiOutlineInfoCircle } from "react-icons/ai";
import type {
  ContactFormReferrerAction,
  ContactFormValues,
  SbLink,
  SubmitContactFormPayload,
} from "../../../types";
import { TextLink } from "../../../components/text-link";
import { Button, IconButton } from "../../../components/button";
import { REFERRER_ACTION_QUERY_PARAM_KEY, ROUTES } from "../../../config/constants";
import { cn, getSbLinkUrl, isEmptyRichText } from "../../../utils/misc";
import { useRouterHistoryStore } from "../../../store/router-history-store";
import { RichTextContent } from "../../../components/rich-text-content";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/popover";
import { RHFTextInput } from "../../../components/form/rhf-text-input";
import { RHFTextarea } from "../../../components/form/rhf-textarea";
import { RHFCheckbox } from "../../../components/form/rhf-checkbox";
import { RHFSelect } from "../../../components/form/rhf-select";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/accordion";
import { RadioInput } from "../../../components/form/radio-input";
import { newCustomerSchema, existingCustomerSchema } from "./schema";
import { messageMaxChars, customers, orderQuestionsOptions } from "./constants";
import type { CustomerTypes } from "./constants";

export function ContactForm({
  privacyLink,
  isAgreedToTermsLabel = "Ich bin mit der Verarbeitung meiner Daten, zum Zwecke der Bearbeitung dieser Nachricht, einverstanden",
  doubleOptInLabelPrimary = "should be used from storyblok source",
  doubleOptInLabelSecondary,
  doubleOptInTooltipContent,
  newCustomerSubjects,
  existingCustomerSubjects,
}: ContactFormProps) {
  const [selected, setSelected] = useState(customers[0]);
  const [subjOptions, setSubjOptions] = useState(newCustomerSubjects);
  const { query } = useRouter();

  const previousPath = useRouterHistoryStore((state) => state.previousPath);
  const methods = useForm<ContactFormValues>({
    resolver: yupResolver(selected === "Neukunde" ? newCustomerSchema : existingCustomerSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      street: "",
      houseNumber: "",
      zipCode: "",
      city: "",
      subject: "",
      subjectType: "",
      additionalSubject: "",
      message: "",
      isAgreedToTerms: false,
      isAgreedToDoubleOptIn: false,
    },
  });

  const { isSubmitting, isSubmitSuccessful, isSubmitted, errors } = methods.formState;

  async function onSubmit(data: ContactFormValues) {
    try {
      const referrerAction = query[REFERRER_ACTION_QUERY_PARAM_KEY]
        ? (JSON.parse(
            query[REFERRER_ACTION_QUERY_PARAM_KEY] as string,
          ) as ContactFormReferrerAction)
        : null;

      data.subject =
        subjOptions?.find((element) => element.value === data.subjectType)?.label || "Sonstiges";

      if (data.additionalSubject) {
        data.subject = `${data.subject}/${data.additionalSubject}`;
        data.subjectType = data.subject;
      }

      const payload: SubmitContactFormPayload = {
        formData: data,
        referrerPath: previousPath,
        referrerAction,
      };

      await axios.post("/api/submit-contact-form", payload);
    } catch (error) {
      methods.setError("root", { message: "Server error." });
    }
  }

  const isSuccessfulSubmission = isSubmitted && isSubmitSuccessful;
  if (isSuccessfulSubmission) {
    return <SuccessfulPostSubmissionContent />;
  }

  const isServerSideError = errors.root?.message !== undefined;
  if (isServerSideError) {
    return <ErrorPostSubmissionContent />;
  }

  function onCustomerTypeChange(value: CustomerTypes) {
    setSelected(value);
    setSubjOptions(value === "Neukunde" ? newCustomerSubjects : existingCustomerSubjects);
  }

  return (
    <FormProvider {...methods}>
      <RadioInput
        items={customers as string[]}
        onSelect={onCustomerTypeChange as (value: string) => void}
        value={selected}
      />

      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises -- TODO */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {selected === "Neukunde" && <PersonalInformation isRequired={false} />}
        <div className="mt-6">
          <RHFTextInput
            id="contact-form-email"
            label="Dienstliche E-Mail Adresse*"
            name="email"
            placeholder="max.mustermann@pts-group.de"
          />
        </div>

        <div className="mt-6">
          <RHFSelect
            id="contact-form-subject"
            label="Betreff*"
            name="subjectType"
            options={subjOptions || []}
            placeholder="Wählen Sie Ihr Anliegen:"
          />
        </div>

        {methods.getValues().subjectType === "Fragen zur Bestellung" && selected !== "Neukunde" && (
          <div className="mt-6">
            <RHFSelect
              id="contact-form-additional-subject"
              label="Kategorie"
              name="additionalSubject"
              options={orderQuestionsOptions}
              placeholder="Wählen Sie Ihr Anliegen:"
            />
          </div>
        )}

        <div className="mt-6">
          <RHFTextarea
            id="contact-form-message"
            label="Ihre Nachricht*"
            name="message"
            placeholder={`Max. ${messageMaxChars} Zeichen`}
            rows={11}
          />
        </div>

        <div className="mt-6">
          <Accordion collapsible={true} type="single">
            <AccordionItem className="border-b-0" value="weitere-details">
              <AccordionTrigger
                className="rounded-md border-l-4 border-l-brand-d bg-light-t2 px-6 py-6 hover:bg-light-t3 hover:no-underline"
                iconClassName="h-7 w-7"
              >
                <div className="text-left">
                  <div className="text-18">Erweitern Sie Ihr Profil (optional)</div>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div className="border-b border-b-light-3 pb-6">
                  {selected !== "Neukunde" && <PersonalInformation isRequired={false} />}
                  <div className="mt-5">
                    <div className="inline border-b border-dashed border-light-6 font-medium">
                      Lieferadresse
                    </div>
                  </div>
                  <div className="mt-5 grid gap-6 lg:grid-cols-2">
                    <RHFTextInput
                      id="contact-form-street"
                      label="Straße"
                      name="street"
                      placeholder="Maritta-Bernhardt-Straße"
                    />
                    <RHFTextInput
                      id="contact-form-houseNumber"
                      label="Hausnummer"
                      name="houseNumber"
                      placeholder="8a"
                    />
                    <RHFTextInput
                      id="contact-form-zipCode"
                      label="PLZ"
                      name="zipCode"
                      placeholder="33480"
                    />
                    <RHFTextInput
                      id="contact-form-city"
                      label="Ort"
                      name="city"
                      placeholder="Garmisch-Partenkirchen"
                    />
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>

        {!!privacyLink && (
          <div className="mt-6">
            <TextLink href={getSbLinkUrl(privacyLink)} target={privacyLink.target}>
              Datenschutz
            </TextLink>
          </div>
        )}

        <div className="mt-6">
          <RHFCheckbox
            id="contact-form-isAgreedToTerms"
            label={`${isAgreedToTermsLabel}*`}
            name="isAgreedToTerms"
          />
        </div>

        <div className="mt-6">
          <RHFCheckbox
            id="contact-form-isAgreedToDoubleOptIn"
            label={
              <>
                <div>{doubleOptInLabelPrimary}</div>
                {!!doubleOptInLabelSecondary && (
                  <div>
                    <span className="align-middle italic leading-none">
                      {doubleOptInLabelSecondary}
                    </span>
                    {!isEmptyRichText(doubleOptInTooltipContent) && (
                      <Popover>
                        <PopoverTrigger asChild={true}>
                          <IconButton
                            ariaLabel="Mehr"
                            className="ml-1 p-0.5 align-middle lg:p-1"
                            icon={<AiOutlineInfoCircle />}
                            variant="link"
                          />
                        </PopoverTrigger>
                        <PopoverContent className="w-80">
                          <RichTextContent content={doubleOptInTooltipContent} scale="sm" />
                        </PopoverContent>
                      </Popover>
                    )}
                  </div>
                )}
              </>
            }
            name="isAgreedToDoubleOptIn"
          />
        </div>

        <div className="mt-6">
          <Button isLoading={isSubmitting} size="large" type="submit">
            Abschicken
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

function PersonalInformation({ isRequired }: { isRequired: boolean }) {
  return (
    <div>
      <div className="mt-6 grid gap-6 lg:grid-cols-2">
        <RHFTextInput
          id="contact-form-firstName"
          label={isRequired ? "Vorname*" : "Vorname"}
          name="firstName"
          placeholder="Max"
        />
        <RHFTextInput
          id="contact-form-lastName"
          label={isRequired ? "Nachname*" : "Nachname"}
          name="lastName"
          placeholder="Mustermann"
        />
      </div>

      <div className="mt-6 grid gap-6 lg:grid-cols-2">
        <RHFTextInput
          id="contact-form-companyName"
          label={isRequired ? "Ihr Unternehmen*" : "Ihr Unternehmen"}
          name="companyName"
          placeholder="Beispielunternehmen"
        />
        <RHFTextInput
          id="contact-form-phoneNumber"
          label={isRequired ? "Telefon Nummer*" : "Telefon Nummer"}
          name="phoneNumber"
          placeholder="+49 69 1234 5678"
        />
      </div>
    </div>
  );
}

function SuccessfulPostSubmissionContent() {
  return (
    <PostSubmissionContent
      headingText="Ihre Nachricht ist bei uns eingegangen."
      nextStepsText={
        <>
          Wir werden Ihr Anliegen schnellstmöglich bearbeiten und uns bei Ihnen per E-Mail melden.
          Prüfen Sie gegebenenfalls ihren Spam Ordner.
        </>
      }
      status="success"
    />
  );
}

function ErrorPostSubmissionContent() {
  return (
    <PostSubmissionContent
      headingText="Entschuldigung! Ein Fehler ist aufgetreten."
      nextStepsText={
        <>
          Versuchen Sie es zu einem späteren Zeitpunkt erneut oder kontaktieren Sie uns unter{" "}
          <TextLink href="mailto:info@pts-group.de">customerservice@pts-group.de</TextLink> gerne
          weiter.
        </>
      }
      status="error"
    />
  );
}

function PostSubmissionContent(props: {
  status: "success" | "error";
  headingText: ReactNode;
  nextStepsText: ReactNode;
}) {
  const nodeRef = useRef<HTMLDivElement | null>(null);

  /**
   * Scrolls to the top of this content on mount, due to layout shift.
   */
  useEffect(() => {
    if (!nodeRef.current) {
      return;
    }

    nodeRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  return (
    <div className="scroll-mt-40 lg:scroll-mt-52" ref={nodeRef}>
      <div
        className={cn(
          "rounded-md",
          props.status === "success" && "bg-confirmation",
          props.status === "error" && "bg-warning",
        )}
      >
        <div className="p-6 text-20 font-semibold text-white lg:px-6 lg:py-8 lg:text-32">
          {props.headingText}
        </div>
      </div>
      <div className="mt-6 text-17 font-semibold lg:text-24">Nächste Schritte</div>
      <div className="mt-4">{props.nextStepsText}</div>
      <div className="mt-6">
        <Button linkProps={{ href: ROUTES.HOME }} size="large">
          Zurück zur Startseite
        </Button>
      </div>
    </div>
  );
}

type ContactFormProps = {
  privacyLink: SbLink | undefined;
  isAgreedToTermsLabel: string | undefined;
  doubleOptInLabelPrimary: string | undefined;
  doubleOptInLabelSecondary: string | undefined;
  doubleOptInTooltipContent: ISbRichtext | undefined;
  newCustomerSubjects: SubjectOptionProps | undefined;
  existingCustomerSubjects: SubjectOptionProps | undefined;
};

type SubjectOptionProps = { label: string; value: string }[];
