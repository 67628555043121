import { System } from "../types";
import { COLORS } from "./theme";

type SolutionSectorColorScheme = {
  /**
   * Background color class.
   */
  bgClass: string;
  /**
   * Text color class.
   */
  textClass: string;
  /**
   * Hex color, e.g. #fff.
   */
  hexColor: string;
};

const SOLUTION_SECTOR_COLOR_SCHEME: Record<System | "fallback", SolutionSectorColorScheme> = {
  fallback: {
    bgClass: "bg-foreground",
    textClass: "text-foreground",
    hexColor: COLORS.foreground.DEFAULT,
  },
  [System.Produktionstechnik]: {
    bgClass: "bg-brand-a",
    textClass: "text-brand-a",
    hexColor: COLORS.brand.a.DEFAULT,
  },
  [System.Industriehydraulik]: {
    bgClass: "bg-brand-b",
    textClass: "text-brand-b",
    hexColor: COLORS.brand.b.DEFAULT,
  },
  [System.Fluidtechnik]: {
    bgClass: "bg-brand-d",
    textClass: "text-brand-d",
    hexColor: COLORS.brand.d.DEFAULT,
  },
  [System.Antriebstechnik]: {
    bgClass: "bg-brand-c",
    textClass: "text-brand-c",
    hexColor: COLORS.brand.c.DEFAULT,
  },
};

export const getSystemColorScheme = (system: System | undefined) => {
  if (!system) {
    return SOLUTION_SECTOR_COLOR_SCHEME.fallback;
  }

  return SOLUTION_SECTOR_COLOR_SCHEME[system];
};
