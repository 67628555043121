import type { ISbRichtext } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react";
import { FiDownload } from "react-icons/fi";
import { useQuery } from "@tanstack/react-query";
import { Container } from "../../components/container";
import { RichTextContent } from "../../components/rich-text-content";
import { cn, getFileExtensionFromUrl, isEmptyRichText } from "../../utils/misc";
import { QUERY_KEY } from "../../config/constants";
import { SkeletonLoader } from "../../components/skeleton-loader";
import { TextLink } from "../../components/text-link";
import { Button } from "../../components/button";
import { useCurrentStory } from "../../context/sb-story-context";
import { BlockSet } from "../block-set";
import { useContactPersonQuery } from "../../hooks/use-contact-person-query";
import { Layout } from "../../components/layout";
import { getSystemColorScheme } from "../../config/solution-sector-color-scheme";
import { getOtherStoriesInCurrentFolder } from "../../api/storyblok";
import type { SbAsset, SbBlockComponentProps, SbBlockSet, System } from "../../types";
import { SbAspectRatioImage } from "../../components/image";
import { SCREENS } from "../../config/theme";
import { NativeVideo } from "../../components/native-video";
import { RelatedStoriesGrid } from "../../components/related-stories-grid";

export type SolutionPageBlockContent = {
  image?: SbAsset;
  video?: SbAsset;
  videoPoster?: SbAsset;
  title?: string;
  description?: string;
  body?: SbBlockSet;
  sidebarTitle?: string;
  sidebarDescription?: ISbRichtext;
  sidebarFile?: SbAsset;
  sidebarFileName?: string;
  contact_person?: string;
  systemSubcategoryPage?: string;
  system?: System;
};

type Props = SbBlockComponentProps<SolutionPageBlockContent>;

export function SolutionPageBlock({ blok }: Props) {
  const { full_slug: storyFullSlug, id } = useCurrentStory();
  const alternateLinksQuery = useQuery({
    queryKey: [QUERY_KEY.SYSTEM_SOLUTION_PAGE_ALTERNATE_LINKS, id],
    queryFn: () =>
      getOtherStoriesInCurrentFolder<SolutionPageBlockContent>({
        storyFullSlug,
        storyId: id,
        contentType: "systemSolutionPage",
      }),
  });

  const colorScheme = getSystemColorScheme(blok.system);

  return (
    <Layout>
      <div {...storyblokEditable(blok)}>
        <Container>
          <div className="mb-12 lg:mb-16">
            <div className="flex flex-col gap-8 xl:flex-row xl:gap-24">
              <div className="min-w-0 max-w-4xl xl:mx-auto xl:flex-grow">
                <h1 className="text-24 font-semibold lg:text-40">{blok.title}</h1>
                <div
                  className={cn(
                    "mt-2 h-1 w-[40px] rounded-md md:h-[6px] md:w-[60px] xl:h-2 xl:w-[80px]",
                    colorScheme.bgClass,
                  )}
                />
                <div className="mt-6">
                  {blok.video?.filename ? (
                    <NativeVideo poster={blok.videoPoster?.filename} src={blok.video.filename} />
                  ) : (
                    <SbAspectRatioImage
                      aspectRatio="16/9"
                      className="rounded-md border border-light-3"
                      cropWidth={SCREENS.lg}
                      image={blok.image}
                      priority={true}
                      size={{ base: "100vw", lg: SCREENS.lg }}
                    />
                  )}
                </div>
                <div className="mx-auto mt-8">
                  <BlockSet blocks={blok.body} richTextScale={{ lg: "lg" }} />
                </div>
              </div>

              <div className="flex flex-col gap-6 xl:flex-shrink-0 xl:flex-grow-0 xl:basis-[350px]">
                {(!!blok.sidebarFile?.filename || !isEmptyRichText(blok.sidebarDescription)) && (
                  <SidebarContent
                    description={blok.sidebarDescription}
                    file={blok.sidebarFile}
                    fileName={blok.sidebarFileName}
                    title={blok.sidebarTitle}
                  />
                )}

                <div className="xl:sticky-full-height">
                  {!!blok.contact_person && (
                    <div className="hidden xl:block">
                      <ContactPersonSideBox uuid={blok.contact_person} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-8 border-t border-light-3 pt-8">
              <RelatedStoriesGrid
                isError={alternateLinksQuery.isError}
                isPending={alternateLinksQuery.isPending}
                stories={alternateLinksQuery.data?.stories}
              />
            </div>
          </div>
        </Container>

        {!!blok.contact_person && <ContactPersonFooter uuid={blok.contact_person} />}
      </div>
    </Layout>
  );
}

function ContactPersonFooter(props: { uuid: string }) {
  const { data, isPending, isError, isSuccess } = useContactPersonQuery(props.uuid);

  if (isError) {
    return null;
  }

  return (
    <div className="bg-light-1 py-10 xl:py-12">
      <Container>
        {!!isPending && <SkeletonLoader className="h-36 max-w-lg" />}
        {!!isSuccess && (
          <div className="flex gap-4 xl:gap-6">
            <div className="w-[100px] xl:w-[150px]">
              <ContactPersonPhoto photo={data.photo} />
            </div>

            <div>
              <div className="text-17 font-semibold lg:text-24">Ich berate Sie gerne!</div>
              <div className="mt-4 lg:text-20 xl:mt-5">
                <div>{data.name}</div>
                <div>{data.title}</div>
                <div className="mt-6 flex flex-col">
                  <ContactPersonPhoneNumber phoneNumber={data.phone_number} />
                  <ContactPersonEmail email={data.email} />
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

function ContactPersonSideBox(props: { uuid: string }) {
  const { data, isPending, isError, isSuccess } = useContactPersonQuery(props.uuid);

  if (isError) {
    return null;
  }

  return (
    <div className="rounded-md border border-light-3 bg-light-1 p-6">
      <h5 className="text-20 font-semibold">Kontakt</h5>
      <div className="mt-4">
        {!!isPending && <SkeletonLoader className="h-24" />}
        {!!isSuccess && (
          <div className="flex gap-3">
            <div className="w-[100px]">
              <ContactPersonPhoto photo={data.photo} />
            </div>

            <div>
              <div className="text-15 font-semibold">{data.name}</div>
              <div className="text-14">{data.title}</div>
              <div className="mt-5 flex flex-col text-14">
                <ContactPersonPhoneNumber phoneNumber={data.phone_number} />
                <ContactPersonEmail email={data.email} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function SidebarContent(props: {
  title: string | undefined;
  description: ISbRichtext | undefined;
  file: SbAsset | undefined;
  fileName: string | undefined;
}) {
  return (
    <div className="flex w-full max-w-4xl flex-col gap-6 rounded-md border border-light-3 bg-light-1 p-4 xl:mx-auto xl:p-6">
      <div>
        {!!props.title && <h4 className="font-semibold lg:text-20">{props.title}</h4>}

        {!!props.description && (
          <div className="mt-4">
            <RichTextContent content={props.description} scale="sm" />
          </div>
        )}
      </div>

      {!!props.file?.filename && (
        <div>
          <div className="text-13 lg:text-15">
            <span className="font-semibold">{props.fileName}</span>
            <span className="mx-2 opacity-30">&#x2022;</span>
            <span className="uppercase opacity-30">
              {getFileExtensionFromUrl(props.file.filename)}
            </span>
          </div>
          <div className="mt-4">
            <Button
              endIcon={<FiDownload size={20} />}
              isFullWidth={true}
              linkProps={{
                href: props.file.filename,
                isFileDownload: true,
              }}
            >
              Download
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

function ContactPersonPhoto(props: { photo: SbAsset | undefined }) {
  return (
    <SbAspectRatioImage
      aspectRatio="1/1"
      className="rounded-md border border-light-3"
      cropWidth={150}
      image={props.photo}
      size={150}
    />
  );
}

function ContactPersonPhoneNumber(props: { phoneNumber: string | undefined }) {
  return (
    <TextLink href={`tel:${props.phoneNumber}`} variant="highlighted">
      {props.phoneNumber}
    </TextLink>
  );
}

function ContactPersonEmail(props: { email: string | undefined }) {
  return (
    <TextLink href={`mailto:${props.email}`} variant="highlighted">
      {props.email}
    </TextLink>
  );
}
