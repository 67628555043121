import { useQuery } from "@tanstack/react-query";
import Link from "next/link";
import type { AspectRatioImageProps, StoryblokImageProps } from "../image";
import { SbAspectRatioImage } from "../image";
import { QUERY_KEY } from "../../config/constants";
import { getStoryByUuid } from "../../api/storyblok";
import type { PartnerBlockContent } from "../../blocks/pages/partner-page-block";
import type { SbStoryProps } from "../../types";
import { PartnerCardSkeleton } from "./partner-card-skeleton";

type BaseProps = {
  logoVariant: "primary" | "thumbnail" | undefined;
  size: AspectRatioImageProps["size"];
  cropWidth: StoryblokImageProps["cropWidth"];
  showLabel?: boolean;
};

export function LazyPartnerCard({
  uuid,
  showLabel,
  logoVariant,
  size,
  cropWidth,
}: BaseProps & { uuid: string | undefined }) {
  const { data, isPending, isError } = useQuery({
    queryKey: [QUERY_KEY.PARTNER, uuid],
    queryFn: () => {
      if (!uuid) {
        throw new Error("Partner uuid missing.");
      }

      return getStoryByUuid<PartnerBlockContent>(uuid);
    },
    enabled: !!uuid,
  });

  if (isError) {
    return null;
  }

  if (isPending) {
    return <PartnerCardSkeleton withLabel={showLabel} />;
  }

  return (
    <PartnerCard
      cropWidth={cropWidth}
      logoVariant={logoVariant}
      size={size}
      story={data.data.story}
    />
  );
}

export function PartnerCard({
  story,
  logoVariant = "primary",
  size,
  cropWidth,
  showLabel,
}: BaseProps & { story: SbStoryProps<PartnerBlockContent> }) {
  return (
    <Link className="group" href={story.full_slug}>
      <div className="rounded-md border border-light-3 p-4 transition-colors group-hover:shadow-elevated">
        <SbAspectRatioImage
          aspectRatio="1/1"
          cropWidth={cropWidth}
          image={getPartnerLogo(story.content, logoVariant)}
          size={size}
        />
      </div>
      {!!showLabel && (
        <div className="mt-2 truncate text-14 font-semibold group-hover:underline lg:text-16">
          {story.content.title}
        </div>
      )}
    </Link>
  );
}

function getPartnerLogo(partner: PartnerBlockContent, variant: "primary" | "thumbnail") {
  if (variant === "thumbnail") {
    return partner.thumbnailLogo;
  }

  return partner.logo?.filename ? partner.logo : partner.thumbnailLogo;
}
