import { useEffect, useMemo, useState } from "react";
import type { SbBlockComponentProps, SbBlockContent, SbBlockSet } from "../types";
import { cn, isInsideStoryblokVisualEditor, parseSbNumberFieldValue } from "../utils/misc";
import { BlockSet } from "./block-set";
import { BlockWrapper } from "./block-wrapper/block-wrapper";

type ColumnBlockContent = {
  body?: SbBlockSet;
  /**
   * Numeric string.
   */
  width?: string;
  widthMeasurementUnit?: "percentage" | "pixels";
  /**
   * Overrides whatever might have been set in the parent columns block configuration.
   */
  verticalAlignment?: "normal" | "top" | "center" | "bottom";
};

type Props = SbBlockComponentProps<{
  columns?: SbBlockContent<ColumnBlockContent>[];
  shouldStackOnMobile?: boolean;
  /**
   * Numeric string representing amount of pixels.
   */
  horizontalSpacing?: string;
  /**
   * Numeric string representing amount of pixels.
   */
  verticalSpacing?: string;
  /**
   * Default: normal.
   */
  verticalAlignment?: "normal" | "top" | "center" | "bottom";
  /**
   * Default: left.
   */
  horizontalAlignment?:
    | "left"
    | "center"
    | "right"
    | "space-between"
    | "space-around"
    | "space-evenly";
}>;

export function ColumnsBlock({ blok }: Props) {
  const isInsideStoryblok = useIsInsideStoryblok();

  if (!blok.columns) {
    return null;
  }

  const horizontalSpacing = parseSbNumberFieldValue(blok.horizontalSpacing);
  const verticalSpacing = parseSbNumberFieldValue(blok.verticalSpacing);

  return (
    <BlockWrapper blok={blok}>
      <div
        className={cn(
          "flex gap-6",
          blok.shouldStackOnMobile && "flex-wrap md:flex-nowrap",
          isInsideStoryblok && blok.columns.length === 0 && "min-h-[3rem]",
          blok.verticalAlignment === "top" && "items-start",
          blok.verticalAlignment === "center" && "items-center",
          blok.verticalAlignment === "bottom" && "items-end",
          blok.horizontalAlignment === "center" && "justify-center",
          blok.horizontalAlignment === "right" && "justify-end",
          blok.horizontalAlignment === "space-between" && "justify-between",
          blok.horizontalAlignment === "space-around" && "justify-around",
          blok.horizontalAlignment === "space-evenly" && "justify-evenly",
        )}
        style={{
          columnGap: horizontalSpacing !== undefined ? `${horizontalSpacing}px` : undefined,
          rowGap: verticalSpacing !== undefined ? `${verticalSpacing}px` : undefined,
        }}
      >
        {blok.columns.map((column) => (
          <ColumnBlock
            blok={column}
            key={column._uid}
            shouldStackOnMobile={blok.shouldStackOnMobile}
          />
        ))}
      </div>
    </BlockWrapper>
  );
}

function ColumnBlock({
  blok,
  shouldStackOnMobile,
}: SbBlockComponentProps<ColumnBlockContent> & { shouldStackOnMobile: boolean | undefined }) {
  const isInsideStoryblok = useIsInsideStoryblok();

  const widthMeasurementUnit = blok.widthMeasurementUnit ?? "percentage";
  const width = useMemo(() => {
    const parsedValue = parseSbNumberFieldValue(blok.width);
    if (!parsedValue) {
      return undefined;
    }

    if (widthMeasurementUnit === "percentage") {
      return `${Math.min(parsedValue, 100)}%`;
    }

    return `${parsedValue}px`;
  }, [blok.width, widthMeasurementUnit]);

  if (!blok.body) {
    return null;
  }

  return (
    <BlockWrapper
      blok={blok}
      className={cn(
        "min-w-0 basis-0 break-words",
        !width && "grow",
        isInsideStoryblok && blok.body.length === 0 && "min-h-[3rem]",
        shouldStackOnMobile && "max-md:!basis-full",
        blok.verticalAlignment === "normal" && "self-stretch",
        blok.verticalAlignment === "top" && "self-start",
        blok.verticalAlignment === "center" && "self-center",
        blok.verticalAlignment === "bottom" && "self-end",
      )}
      style={{ flexBasis: width }}
    >
      <BlockSet blocks={blok.body} />
    </BlockWrapper>
  );
}

function useIsInsideStoryblok() {
  const [isInsideStoryblok, setIsInsideStoryblok] = useState(false);

  /**
   * If the columns block or any of the child blocks do not have any content inserted yet,
   * we want to set some sort of min height or other appearance tweaks to make it more visible to the editor.
   */
  useEffect(() => {
    setIsInsideStoryblok(isInsideStoryblokVisualEditor());
  }, []);

  return isInsideStoryblok;
}
