import { storyblokEditable } from "@storyblok/react";
import type { SbBlockComponentProps, SbStoryProps } from "../types";
import { PartnerCard } from "../components/partner-card/partner-card";
import type { PartnerBlockContent } from "./pages/partner-page-block";

type Props = SbBlockComponentProps<{
  partners?: SbStoryProps<PartnerBlockContent>[];
}>;

export function LandingPagePartnersBlock({ blok }: Props) {
  if (!blok.partners || blok.partners.length === 0) {
    return null;
  }

  return (
    <div
      className="mb-8 grid grid-cols-3 gap-2 sm:gap-4 lg:mb-10 lg:grid-cols-6 xl:gap-6"
      {...storyblokEditable(blok)}
    >
      {blok.partners.map((partner) => (
        <PartnerCard
          cropWidth={500}
          key={partner.id}
          logoVariant="thumbnail"
          size={500}
          story={partner}
        />
      ))}
    </div>
  );
}
