import type { SCREENS } from "../config/theme";
import type { SbStoryProps } from "./storyblok";

/**
 * Next.js story page props.
 */
export type SbPageProps<TContent = Record<string, unknown>> = {
  story: SbStoryProps<TContent> | null;
  resolveRelations: string[];
  preview: boolean;
};

export type Breakpoint = keyof typeof SCREENS;

export enum SocialNetwork {
  LinkedIn = "linkedin",
  YouTube = "youtube",
  Facebook = "facebook",
}

export type CookieConsentState = {
  analyticsConsentGranted: boolean;
};

export type ContactFormValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  subject: string;
  additionalSubject?: string;
  message: string;
  isAgreedToTerms: boolean;
  isAgreedToDoubleOptIn: boolean;
  subjectType: string;
};

export type SubmitContactFormPayload = {
  formData: ContactFormValues;
  referrerPath: string | null;
  referrerAction: ContactFormReferrerAction | null;
};

export type ContactFormReferrerAction = {
  type: "category-inquiry-button-click";
  categoryName: string;
  categoryUrl: string;
  categoryUuid: string;
};

export type HrLabAnnouncement = {
  id: number;
  job_title: string;
  status: "design" | "released" | "intern" | "archived" | "on_hold" | "released_extern";
  category_id: number;
};

export type GetJobListResponse = {
  data: HrLabAnnouncement[];
};
