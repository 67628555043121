import type { MutableRefObject } from "react";
import { useCallback, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { Button } from "../../button";
import { useElementResizeObserver } from "../../../hooks/use-element-resize-observer";

type Props = {
  onShowAllButtonClick: () => void;
  closeMenu: () => void;
  menuRef: MutableRefObject<HTMLDivElement | null>;
};

export function NavOverflowButton({ onShowAllButtonClick, closeMenu, menuRef }: Props) {
  const [shouldRenderShowAllButton, setShouldRenderShowAllButton] = useState(false);

  const onMenuResize = useCallback(
    (entry: ResizeObserverEntry) => {
      const hasOverflow = entry.target.clientHeight < entry.target.scrollHeight;
      setShouldRenderShowAllButton(hasOverflow);

      if (hasOverflow) {
        closeMenu();
      }
    },
    [closeMenu],
  );

  useElementResizeObserver(menuRef, onMenuResize);

  if (!shouldRenderShowAllButton) {
    return null;
  }

  return (
    <div className="absolute bottom-0 right-0 top-0 flex w-60 items-center justify-end px-2 py-2.5">
      <Button
        endIcon={<FiMenu size={21} />}
        onClick={() => {
          onShowAllButtonClick();
          closeMenu();
        }}
        variant="subtle"
      >
        Alles anzeigen
      </Button>
    </div>
  );
}
