import { ErrorMessage } from "@hookform/error-message";
import type { FieldErrors } from "react-hook-form";
import type { ReactNode } from "react";

type Props = {
  name: string;
  id: string;
  label?: string;
  errors: FieldErrors;
  children: ReactNode;
};

export function RHFFormControl(props: Props) {
  return (
    <div>
      {!!props.label && (
        <label className="mb-2 block text-12 lg:text-14" htmlFor={props.id}>
          {props.label}
        </label>
      )}

      {props.children}

      <ErrorMessage
        errors={props.errors}
        name={props.name}
        render={({ message }) => <div className="mt-2 text-14 text-error">{message}</div>}
      />
    </div>
  );
}
