import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { CarouselContext } from "./carousel-context";

type Props = {
  children: ReactNode;
};

export function Carousel({ children }: Props) {
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [slidesInView, setSlidesInView] = useState<number[]>([]);

  const updateSelectedIndex = useCallback(() => {
    if (!emblaMainApi) {
      return;
    }
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, setSelectedIndex]);

  const updateSlidesInView = useCallback(() => {
    if (!emblaMainApi) {
      return;
    }

    setSlidesInView((s) => {
      if (s.length === emblaMainApi.slideNodes().length) {
        emblaMainApi.off("slidesInView", updateSlidesInView);
      }
      const inView = emblaMainApi.slidesInView().filter((index) => !s.includes(index));
      return s.concat(inView);
    });
  }, [emblaMainApi]);

  useEffect(() => {
    if (!emblaMainApi) {
      return;
    }

    updateSelectedIndex();
    updateSlidesInView();
    emblaMainApi.on("slidesInView", updateSlidesInView);
    emblaMainApi.on("reInit", updateSelectedIndex);
    emblaMainApi.on("reInit", updateSlidesInView);
    emblaMainApi.on("select", updateSelectedIndex);
  }, [emblaMainApi, updateSelectedIndex, updateSlidesInView]);

  return (
    <CarouselContext.Provider
      value={{
        emblaMainRef,
        emblaMainApi,
        selectedIndex,
        slidesInView,
      }}
    >
      <div className="relative">{children}</div>
    </CarouselContext.Provider>
  );
}
