import type { ReactNode } from "react";
import { useCarouselContext } from "./carousel-context";

type Props = {
  children: ReactNode;
};

export function CarouselSlides({ children }: Props) {
  const { emblaMainRef } = useCarouselContext();

  return (
    <div className="overflow-hidden" ref={emblaMainRef}>
      <div className="-ml-4 flex touch-pan-y" style={{ backfaceVisibility: "hidden" }}>
        {children}
      </div>
    </div>
  );
}
