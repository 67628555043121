import type { Hit } from "instantsearch.js";
import Link from "next/link";
import { Highlight, Snippet } from "react-instantsearch";
import type { AlgoliaStoryObject } from "@pts/algolia";
import { AspectRatioImage } from "../image";
import { INDEX_STORY_GROUPS } from "../../config/constants";

type Props = {
  hit: Hit<AlgoliaStoryObject>;
  onClick: () => void;
};

export function SearchHit({ hit, onClick }: Props) {
  return (
    <Link
      className="flex gap-4 border-b border-foreground-t5 bg-white p-4 transition-all last:border-b-0 hover:bg-light-t1"
      href={hit.full_slug}
      onAuxClick={onClick}
      onClick={onClick}
    >
      <div>
        <div className="w-[74px]">
          {hit.image ? (
            <AspectRatioImage
              alt={hit.title}
              aspectRatio="16/9"
              className="rounded-md border border-light-3"
              size={75}
              src={hit.image}
            />
          ) : (
            <div className="aspect-h-9 aspect-w-16 rounded-md border border-light-3 bg-light-1">
              <span className="flex h-full items-center justify-center text-12 text-foreground-t2">
                Kein Bild
              </span>
            </div>
          )}
        </div>
      </div>
      <div>
        {!!hit.group && !!INDEX_STORY_GROUPS[hit.group] && (
          <div className="text-14 font-medium uppercase text-foreground-t2">
            {INDEX_STORY_GROUPS[hit.group]}
          </div>
        )}
        <div className="line-clamp-2 hyphens-auto font-semibold" lang="de">
          <Highlight attribute="title" hit={hit} />
        </div>
        <div className="mt-1 line-clamp-3 hyphens-auto text-14" lang="de">
          <Snippet attribute="content" hit={hit} />
        </div>
      </div>
    </Link>
  );
}
