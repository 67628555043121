import { useFormContext } from "react-hook-form";
import { RHFFormControl } from "./rhf-form-control";
import { TextInput } from "./text-input";

type Props = {
  name: string;
  id: string;
  label: string;
  placeholder?: string;
};

export function RHFTextInput(props: Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const isError = !!errors[props.name];

  return (
    <RHFFormControl errors={errors} id={props.id} label={props.label} name={props.name}>
      <TextInput
        id={props.id}
        isError={isError}
        placeholder={props.placeholder}
        {...register(props.name)}
      />
    </RHFFormControl>
  );
}
