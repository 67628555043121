import type { IconType } from "react-icons";
import { FaFacebook, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import type { StoryIndexGroup } from "@pts/algolia";
import { STORY_INDEX_GROUP } from "@pts/algolia";
import { SB_ROOT_SLUGS } from "@pts/storyblok";
import { SocialNetwork, System } from "../types";

export const QUERY_KEY = {
  SYSTEM_SOLUTION_ALTERNATE_LINKS: "system-solution-alternate-links",
  SYSTEM_SOLUTION_SUBCATEGORY_ALTERNATE_LINKS: "system-solution-subcategory-alternate-links",
  SYSTEM_SOLUTION_PAGE_ALTERNATE_LINKS: "system-solution-page-alternate-links",
  CONTACT_PERSON: "contact-person",
  STORIES_BY_UUIDS: "stories",
  MARKETS: "markets",
  PARTNERS: "partners",
  PARTNER: "partner",
  POSTS: "posts",
  TECH_KNOWLEDGE_POSTS: "techKnowledgePosts",
  LANDING_PAGE_POSTS: "landingPagePosts",
  UPCOMING_EVENTS: "upcomingEvents",
  GLOBAL_SEARCH: "globalSearch",
  CONFIG: "config",
  JOB_LIST: "job-list",
} as const;

export const ROUTES = {
  HOME: "/",
  SEARCH: "/s",
  NEWS: `/${SB_ROOT_SLUGS.NEWS}`,
  CONTACT_FORM: "/kontakt",
} as const;

export const REVALIDATE_DURATION_SECONDS = 30;

export const DEBOUNCE_DURATION = 400;

export const PORTAL_ROOT_NODE_ID = "portal-root";

export const SYSTEM_LABELS: Record<System, string> = {
  [System.Antriebstechnik]: "Antriebstechnik",
  [System.Fluidtechnik]: "Fluidtechnik",
  [System.Industriehydraulik]: "Industriehydraulik",
  [System.Produktionstechnik]: "Produktionstechnik",
};

export const SOCIAL_LINKS_META: Record<SocialNetwork, { icon: IconType; label: string }> = {
  [SocialNetwork.LinkedIn]: {
    icon: FaLinkedinIn,
    label: "LinkedIn",
  },
  [SocialNetwork.YouTube]: {
    icon: FaYoutube,
    label: "YouTube",
  },
  [SocialNetwork.Facebook]: {
    icon: FaFacebook,
    label: "Facebook",
  },
};

export const META_TITLE_FALLBACK = "PTS Group | Wir halten die Industrie nachhaltig in Bewegung";
export const META_DESCRIPTION_FALLBACK =
  "Der moderne, kompetente und vertrauenswürdige Partner für alle Ihre industriellen Lösungen in der Industriehydraulik, Produktions-, Antriebs- und Fluidtechnik.";
export const META_IMAGE_FALLBACK =
  "https://a.storyblok.com/f/191708/1600x622/184e129a40/pts_group_logo.png";

export const INDEX_STORY_GROUPS: Record<StoryIndexGroup, string> = {
  [STORY_INDEX_GROUP.SystemSolutions]: "Lösungen",
  [STORY_INDEX_GROUP.News]: "Aktuelles",
  [STORY_INDEX_GROUP.TechnicalKnowledge]: "Technisches Wissen",
  [STORY_INDEX_GROUP.Products]: "Produkte",
  [STORY_INDEX_GROUP.Services]: "Dienstleistungen",
  [STORY_INDEX_GROUP.MarketsAndSectors]: "Märkte & Branchen",
  [STORY_INDEX_GROUP.Partners]: "Partner",
  [STORY_INDEX_GROUP.AboutUs]: "Über Uns",
};

export const MIN_SEARCH_QUERY_LENGTH = 2;

export const SEARCH_QUERY_PARAM_KEY = "q";

export const REFERRER_ACTION_QUERY_PARAM_KEY = "referrer-action";
