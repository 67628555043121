import { useState } from "react";
import Cookies from "js-cookie";
import { isInsideStoryblokVisualEditor } from "../../utils/misc";
import type { CookieConsentState } from "../../types";
import { CONSENT_STATE_COOKIE_KEY } from "./cookie-constants";
import { updateGoogleTagManagerConsent } from "./google-tag-manager-listeners";

const COOKIE_ACTION_KEY = "cookie-action";
const COOKIE_TRUE_VALUE = "true";
const COOKIE_EXPIRATION_DAYS = 365;

export function useTrackingPreferences() {
  const [shouldGetConsent, setShouldGetConsent] = useState(
    isInsideStoryblokVisualEditor() ? false : userHasNotSpecifiedConsentYet(),
  );

  const setConsentState = ({ analyticsConsentGranted }: CookieConsentState) => {
    updateTrackingCookies({ analyticsConsentGranted });
    setShouldGetConsent(false);
  };

  const onAcceptAll = () => {
    setConsentState({ analyticsConsentGranted: true });
  };

  const onRejectAll = () => {
    setConsentState({ analyticsConsentGranted: false });
  };

  return {
    shouldGetConsent,
    setConsentState,
    onAcceptAll,
    onRejectAll,
  };
}

function updateTrackingCookies({ analyticsConsentGranted }: CookieConsentState) {
  Cookies.set(COOKIE_ACTION_KEY, COOKIE_TRUE_VALUE, { expires: COOKIE_EXPIRATION_DAYS });

  const consentState = {
    analyticsConsentGranted,
  };

  Cookies.set(CONSENT_STATE_COOKIE_KEY, JSON.stringify(consentState), {
    expires: COOKIE_EXPIRATION_DAYS,
  });

  updateGoogleTagManagerConsent(consentState);
}

function userHasNotSpecifiedConsentYet() {
  return Cookies.get(COOKIE_ACTION_KEY) !== COOKIE_TRUE_VALUE;
}
