import { storyblokEditable } from "@storyblok/react";
import { Layout } from "../../components/layout";
import { Container } from "../../components/container";
import { OverviewCardList } from "../../components/overview-card-list";
import { getBrandColorScheme } from "../../config/brand-color-scheme";
import type { SbBlockComponentProps, SbStoryProps } from "../../types";
import type { ServicePageBlockContent } from "./service-page-block";

type Props = SbBlockComponentProps<{
  title?: string;
  servicePages?: SbStoryProps<ServicePageBlockContent>[];
}>;

export function ServicesListPageBlock({ blok }: Props) {
  const servicePages = blok.servicePages ?? [];

  const listItems = servicePages.map((page) => {
    const colorScheme = getBrandColorScheme(page.content.colorScheme?.value);

    return {
      id: page.uuid,
      href: page.full_slug,
      title: page.name,
      colorClass: colorScheme.bgClass,
      image: page.content.image,
    };
  });

  return (
    <Layout>
      <div {...storyblokEditable(blok)} className="mb-16">
        <Container>
          <div className="border-t border-light-3 py-6 lg:mb-6 lg:border-b">
            <h1 className="text-18 font-semibold lg:text-32">{blok.title}</h1>
          </div>

          <OverviewCardList imageVariant="cover" items={listItems} />
        </Container>
      </div>
    </Layout>
  );
}
