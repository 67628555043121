import { useFormContext } from "react-hook-form";
import type { ReactNode } from "react";
import { cn } from "../../utils/misc";
import { RHFFormControl } from "./rhf-form-control";
import { formFieldSharedClasses } from "./shared-classes";

type Props = {
  name: string;
  id: string;
  label: ReactNode;
};

export function RHFCheckbox(props: Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const isError = !!errors[props.name];

  return (
    <RHFFormControl errors={errors} id={props.id} name={props.name}>
      <label className="flex items-start text-14" htmlFor={props.id}>
        <input
          className={cn(
            formFieldSharedClasses({ isError }),
            "h-5 w-5 cursor-pointer rounded !p-0 text-brand-d",
            !isError && "bg-light-t3",
          )}
          id={props.id}
          type="checkbox"
          {...register(props.name)}
        />
        <span className="ml-2.5">{props.label}</span>
      </label>
    </RHFFormControl>
  );
}
