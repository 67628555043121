import Link from "next/link";
import { cn } from "../../utils/misc";

export function BreadcrumbItem(props: { href: string; label: string; isPlainText?: boolean }) {
  const classes =
    "truncate text-14 font-medium uppercase lg:tracking-wide max-w-[225px] group-last:max-w-[300px]";

  if (props.isPlainText) {
    return <div className={classes}>{props.label}</div>;
  }

  return (
    <Link
      className={cn(classes, "transition-all hover:text-foreground hover:underline")}
      href={props.href}
    >
      {props.label}
    </Link>
  );
}
