import { isSSR } from "../../utils/misc";
import type { CookieConsentState } from "../../types";

/**
 * Array of callbacks to be executed when consent changes
 */
const consentListeners: ((params: CookieConsentState) => void)[] = [];

if (!isSSR()) {
  /**
   * Called from GTM template to set callback to be executed when user consent is provided.
   *
   * https://developers.google.com/tag-platform/tag-manager/templates/consent-apis#integrate_the_template_with_the_consent_solution
   */
  window.addConsentListener = (callback) => {
    consentListeners.push(callback);
  };
}

/**
 * Called when user grants/denies consent.
 */
export function updateGoogleTagManagerConsent(consent: CookieConsentState) {
  consentListeners.forEach((callback) => {
    callback(consent);
  });
}
