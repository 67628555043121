import { generateStoryblokImageSrc } from "../../utils/sb-image";
import { AspectRatioImage } from "./aspect-ratio-image";
import type { AspectRatioImageProps, StoryblokImageProps } from "./types";

type Props = Omit<AspectRatioImageProps, "src" | "alt"> & StoryblokImageProps;

export function SbAspectRatioImage({
  image,
  aspectRatio,
  cropWidth,
  cropStrategy,
  ...props
}: Props) {
  if (!image?.filename) {
    return null;
  }

  return (
    <AspectRatioImage
      alt={image.alt}
      aspectRatio={aspectRatio}
      src={generateStoryblokImageSrc({
        filename: image.filename,
        width: cropWidth,
        aspectRatio,
        cropStrategy,
      })}
      {...props}
    />
  );
}
