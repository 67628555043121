import { ProductGroup } from "../types";
import { COLORS } from "./theme";

export type ProductGroupColorScheme = {
  /**
   * Background color class.
   */
  bgClass: string;
  /**
   * Text color class.
   */
  textClass: string;
  /**
   * Text color class when combined with `bgClass` as the background (darker backgrounds usually have light text).
   */
  textOnBgClass: string;
  /**
   * Hex color, e.g. #fff.
   */
  hexColor: string;
};

const PRODUCT_GROUP_COLOR_SCHEME: Record<ProductGroup | "fallback", ProductGroupColorScheme> = {
  fallback: {
    bgClass: "bg-foreground",
    textClass: "text-foreground",
    textOnBgClass: "text-white",
    hexColor: COLORS.foreground.DEFAULT,
  },
  [ProductGroup.Montageautomation]: {
    bgClass: "bg-brand-a",
    textClass: "text-brand-a",
    textOnBgClass: "text-white",
    hexColor: COLORS.brand.a.DEFAULT,
  },
  [ProductGroup.Industriehydraulik]: {
    bgClass: "bg-brand-b",
    textClass: "text-brand-b",
    textOnBgClass: "text-white",
    hexColor: COLORS.brand.b.DEFAULT,
  },
  [ProductGroup.Industriepneumatik]: {
    bgClass: "bg-brand-d",
    textClass: "text-brand-d",
    textOnBgClass: "text-foreground",
    hexColor: COLORS.brand.d.DEFAULT,
  },
  [ProductGroup.Antriebstechnik]: {
    bgClass: "bg-brand-c",
    textClass: "text-brand-c",
    textOnBgClass: "text-foreground",
    hexColor: COLORS.brand.c.DEFAULT,
  },
  [ProductGroup.Verbindungstechnik]: {
    bgClass: "bg-brand-h",
    textClass: "text-brand-h",
    textOnBgClass: "text-foreground",
    hexColor: COLORS.brand.h.DEFAULT,
  },
  [ProductGroup.Drucklufttechnik]: {
    bgClass: "bg-brand-e",
    textClass: "text-brand-e",
    textOnBgClass: "text-foreground",
    hexColor: COLORS.brand.e.DEFAULT,
  },
  [ProductGroup.Prozesstechnik]: {
    bgClass: "bg-brand-g",
    textClass: "text-brand-g",
    textOnBgClass: "text-foreground",
    hexColor: COLORS.brand.g.DEFAULT,
  },
  [ProductGroup.Industrieteile]: {
    bgClass: "bg-brand-f",
    textClass: "text-brand-f",
    textOnBgClass: "text-white",
    hexColor: COLORS.brand.f.DEFAULT,
  },
};

export const getProductGroupColorScheme = (productGroup: ProductGroup | undefined) => {
  if (!productGroup) {
    return PRODUCT_GROUP_COLOR_SCHEME.fallback;
  }

  return PRODUCT_GROUP_COLOR_SCHEME[productGroup];
};
